/**
 *
 * TextInput
 *
 */

import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TextInput, InkblotTheme } from 'inkblot-ui';
import { FormattedMessage } from 'react-intl';
import { phoneSize } from '../../global-styles';

const InputWrapper = styled.div`
  display: inline-block;
  width: ${({ width }) => width || '30%'};
  margin-bottom: ${({ marginBottom }) => marginBottom || '40px'};
  @media (max-width: ${phoneSize}) {
    width: ${({ width }) => width || '45%'};
  }
`;

/* eslint-disable react/prefer-stateless-function */
export default function Input(props) {
  return (
    <InputWrapper width={props.width} marginBottom={props.marginBottom}>
      <InkblotTheme>
        <TextInput
          {...props.input}
          inputStyle={{
            width: '100%',
            minWidth: 'unset',
            ...props.inputStyle,
          }}
          type={props.type}
          label={props.label ? <FormattedMessage {...props.label} /> : ''}
          value={props.defaultValue || props.input.value}
          error={(props.meta.touched && props.meta.error) || props.error}
          inlineMessage={
            (props.meta.touched && props.meta.error) || props.inlineMessage
          }
          disabled={props.disabled}
          placeholder={props.placeholder}
          onKeyDown={props.onKeyDown}
        />
      </InkblotTheme>
    </InputWrapper>
  );
}

TextInput.propTypes = {};
