/**
 *
 * TextInput
 *
 */

import React from 'react';
// import PropTypes from 'prop-types';
import { TextInput, InkblotTheme } from 'inkblot-ui';
import { FormattedMessage } from 'react-intl';

/* eslint-disable react/prefer-stateless-function */
export default function Input(props) {
  return (
    <InkblotTheme>
      <div style={{ marginBottom: '40px', width: '220px', ...props.style }}>
        <TextInput
          {...props.input}
          inputStyle={{
            display: 'inline-block',
            width: '220px',
            minWidth: 'unset',
            ...props.inputStyle,
          }}
          type={props.type}
          label={
            props.label ? (
              <span>
                <FormattedMessage {...props.label} />
                {props.required && <span style={{ color: '#AD645C' }}> *</span>}
              </span>
            ) : (
              ''
            )
          }
          value={props.defaultValue || props.input.value}
          error={(props.meta.touched && props.meta.error) || props.error}
          inlineMessage={
            (props.meta.touched && props.meta.error) || props.inlineMessage
          }
          disabled={props.disabled}
          placeholder={props.placeholder}
        />
      </div>
    </InkblotTheme>
  );
}

TextInput.propTypes = {};
