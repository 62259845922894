import styled from 'styled-components';
import { Link } from 'react-router-dom';
import React from 'react';
import Logo from './Logo';
import InkblotLogo from '../../images/Header/navigation-header.png';
import { darkSlateBlue } from '../../global-styles';

const Span = styled.span`
  font-family: 'Barlow';
  font-size: 22px;
  font-weight: bold;
  color: ${darkSlateBlue};
  margin: 0px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
`;

const Wrapper = styled(Link)`
  display: flex;
  text-decoration: none;
  margin-top: -1px;
`;

const NavigationLogo = () => (
  <Wrapper to="/" aria-label="Go to Inkblot Dashboard">
    <Logo src={InkblotLogo} alt="Ink Therapy" />
    <Span>Inkblot</Span>
  </Wrapper>
);

export default NavigationLogo;
