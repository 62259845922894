import React from 'react';
import Lottie from 'react-lottie';

import animationData from './data-loading.json';

function Spinner() {
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div>
      <Lottie
        options={lottieOptions}
        width={32}
        height={32}
        isClickToPauseDisabled
      />
    </div>
  );
}

Spinner.propTypes = {};

export default Spinner;
