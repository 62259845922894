/*
 * AdditionalServices Messages
 *
 * This contains all the text for the AdditionalServices container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.AdditionalServices';

export default defineMessages({
  header: {
    defaultMessage: 'Work, Health and Life Services',
  },
  coachingHeader: {
    defaultMessage: 'Coaching Services',
  },
  ourServices: {
    defaultMessage: 'Coaching & Advisory Services',
  },
  atInkblot: {
    defaultMessage:
      'At Inkblot, we believe in comprehensive mental health supports.',
  },
  pleaseLet: {
    defaultMessage:
      'This includes services that make life less stressful. Our advisory services are comprehensive and best-in-class.',
  },
  financial: {
    defaultMessage: 'Financial',
  },
  financialHotline: {
    defaultMessage: 'Full-service financial hotline',
  },
  financialPlanners: {
    defaultMessage: 'Staffed by professional financial advisors',
  },
  legal: {
    defaultMessage: 'Legal',
  },
  qualifiedAttorneys: {
    defaultMessage: 'Highly qualified attorneys',
  },
  barAssociation: {
    defaultMessage: 'Coverage of most types of legal concerns',
  },
  research: {
    defaultMessage: 'Life Transitions',
  },
  helpFinding: {
    defaultMessage: 'Resources to assist with childcare and eldercare choices',
  },
  saveTime: {
    defaultMessage:
      'Personalized information for adapting to transitions such as adjusting to the workplace, the arrival of a new baby, empty-nesting, etc.',
  },
  health: {
    defaultMessage: 'Health Coaching',
  },
  infoProfessionals: {
    defaultMessage: 'Personalized support from registered professionals',
  },
  quitSmoking: {
    defaultMessage:
      'Quit smoking, nutrition advice, navigate healthcare system, etc.',
  },
  career: {
    defaultMessage: 'Career Coaching',
  },
  careerGoals: {
    defaultMessage: 'Discuss your career goals and path',
  },
  networking: {
    defaultMessage: 'Learn networking and practical skills',
  },
  loan: {
    defaultMessage: 'Employee Emergency Relief Fund',
  },
  emergencyFinancialAssistance: {
    defaultMessage: 'Emergency financial assistance in the form of a loan',
  },
  secureConfidentialAdjudication: {
    defaultMessage: 'Secure/Confidential adjudication process',
  },
  select: {
    defaultMessage: 'Select',
  },
  supportEmail: {
    defaultMessage: 'support@inkblottherapy.com',
    description: 'User support email address',
  },
});
