import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

export const translations = [
  {
    code: 'He/Him',
    label: <FormattedMessage {...messages.ymHeHim} />,
  },
  {
    code: 'She/Her',
    label: <FormattedMessage {...messages.ymSheHer} />,
  },
  {
    code: 'They/Them',
    label: <FormattedMessage {...messages.ymTheyThem} />,
  },
  {
    code: 'Man',
    label: <FormattedMessage {...messages.ymMan} />,
  },
  {
    code: 'Woman',
    label: <FormattedMessage {...messages.ymWoman} />,
  },
  {
    code: 'Gender Non-Binary or Expansive',
    label: <FormattedMessage {...messages.ymGender} />,
  },
  {
    code: 'Transgender',
    label: <FormattedMessage {...messages.ymTrans} />,
  },
  {
    code: 'Asian - South (e.g. Indian, Pakistani, Sri Lankan)',
    label: <FormattedMessage {...messages.ymAsianSouth} />,
  },
  {
    code: 'Asian - East (e.g. Chinese, Japanese, Korean)',
    label: <FormattedMessage {...messages.ymAsianEast} />,
  },
  {
    code: 'Asian - South East (e.g. Malaysian, Filipino, Vietnamese)',
    label: <FormattedMessage {...messages.ymAsianSouthEast} />,
  },
  {
    code: 'Asian - North American (e.g. Canadian, American)',
    label: <FormattedMessage {...messages.ymAsianNorth} />,
  },
  {
    code: 'Asian - Caribbean',
    label: <FormattedMessage {...messages.ymAsianCarib} />,
  },
  {
    code: 'Black - African (e.g. Ghanaian, Kenyan, Somali)',
    label: <FormattedMessage {...messages.ymBlackAfrican} />,
  },
  {
    code: 'Black - Caribbean (e.g. Barbadian, Jamaican)',
    label: <FormattedMessage {...messages.ymBlackCarbi} />,
  },
  {
    code: 'Black - European (e.g. English, Dutch, Portuguese)',
    label: <FormattedMessage {...messages.ymBlackEuro} />,
  },
  {
    code: 'Black - Latin American',
    label: <FormattedMessage {...messages.ymBlackLatin} />,
  },
  {
    code: 'Black - North American (e.g. Canadian, American)',
    label: <FormattedMessage {...messages.ymBlackNorth} />,
  },
  {
    code: 'First Nations',
    label: <FormattedMessage {...messages.ymFirstNation} />,
  },
  {
    code: 'Indian - Caribbean (e.g. Guyanese with origins in India)',
    label: <FormattedMessage {...messages.ymIndianCarib} />,
  },
  {
    code: 'Indigenous',
    label: <FormattedMessage {...messages.ymIndige} />,
  },
  {
    code: 'Inuit',
    label: <FormattedMessage {...messages.ymInuit} />,
  },
  {
    code: 'Latin American (e.g. Argentinean, Chilean, Salvadoran)',
    label: <FormattedMessage {...messages.ymLatinAmeri} />,
  },
  {
    code: 'Métis',
    label: <FormattedMessage {...messages.ymMetis} />,
  },
  {
    code: 'Middle Eastern/North African (e.g. Egyptian, Iranian, Lebanese)',
    label: <FormattedMessage {...messages.ymMiddleEast} />,
  },
  {
    code: 'Pacific Islander (e.g. Hawaii, Guam, Samoa)',
    label: <FormattedMessage {...messages.ymPacific} />,
  },
  {
    code: 'White - European (e.g. English, Italian, Portuguese, Russian)',
    label: <FormattedMessage {...messages.ymWhiteEuro} />,
  },
  {
    code: 'White - North American (e.g. Canadian, American)',
    label: <FormattedMessage {...messages.ymWhiteNorth} />,
  },
  {
    code: 'White - Caribbean',
    label: <FormattedMessage {...messages.ymWhiteCarib} />,
  },
  {
    code: 'Add "Other" - type in',
    label: <FormattedMessage {...messages.ymOther} />,
  },
];
