import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form/immutable';

import H2 from 'components/H2';
import Dialog from 'components/Dialog';
import { InkblotTheme, Button } from 'inkblot-ui';
import Body from 'components/Body';
import { SqTextInputWithLabel } from 'components/SqTextInput/TextInput';

const Center = styled.div`
  text-align: center;
  margin: 0 auto;
  width: 90%;

  h2 {
    margin-bottom: 20px;
  }
`;

const Input = styled.div`
  width: 90%;
  margin: 0 auto;
  margin-bottom: 20px;
`;
const TextInputField = ({
  input,
  label,
  type,
  meta: { touched, error },
  serverError,
}) => (
  <div>
    <label>{label}</label>
    <SqTextInputWithLabel {...input} type={type} />
    {touched && (error || serverError) && (
      <span style={{ color: 'red' }}>
        {error || (
          <FormattedMessage defaultMessage="Incorrect Invitation Code" />
        )}
      </span>
    )}
  </div>
);

function InvitationCodeDialog({
  handleSubmit,
  onSubmit,
  open,
  onClose,
  serverError,
}) {
  const submitForm = (values) => {
    onSubmit(values.get('match_code'));
  };

  return (
    <Dialog maxWidth="600px" onRequestClose={onClose} open={open}>
      <Center>
        <H2>
          <FormattedMessage defaultMessage="Enter Invitation Code" />
        </H2>
        <Body style={{ marginBottom: 40 }}>
          <FormattedMessage defaultMessage="This code will help us to match you with your partner’s couples counsellor." />
        </Body>
      </Center>
      <form onSubmit={handleSubmit(submitForm)}>
        <Input>
          <Field
            name="match_code"
            label={<FormattedMessage defaultMessage="Invitation Code" />}
            type="text"
            serverError={serverError}
            component={TextInputField}
          />
        </Input>
        <Center>
          <InkblotTheme>
            <Button
              label={<FormattedMessage defaultMessage="Continue" />}
              secondary
              type="submit"
            />
          </InkblotTheme>
        </Center>
      </form>
    </Dialog>
  );
}

function validate(values) {
  const errors = {};
  const match_code = values.get('match_code');
  if (!match_code) {
    errors.match_code = (
      <FormattedMessage defaultMessage="This is a required field" />
    );
  }
  return errors;
}

InvitationCodeDialog.propTypes = {
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default reduxForm({ form: 'invitationForm', validate })(
  InvitationCodeDialog,
);
