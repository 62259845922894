import ChevronDown from './chevron-down.svg';
import ChevronUp from './chevron-up.svg';
import CheckCircle from './check-circle.svg';
import ExclamationTriangle from './exclamation-triangle.svg';
import InformationCircle from './information-circle.svg';
import LightBulb from './light-bulb.svg';
import XCircle from './x-circle.svg';
import Eye from './eye.svg';
import EyeOff from './eye-off.svg';

const SolidIcons = {
  CheckCircle,
  ExclamationTriangle,
  InformationCircle,
  LightBulb,
  XCircle,
  Eye,
  EyeOff,
  ChevronDown,
  ChevronUp,
};

export default SolidIcons;
// const names = Arr.map(e => e.split('-').map(str => str.slice(0,1).toUpperCase() + str.slice(1)).join())
// console.log(names);
