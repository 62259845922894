/**
 *
 * DataLoadingSpinner
 *
 */

import React from 'react';
import styled from 'styled-components';
import Body from 'components/Body';
import Lottie from 'react-lottie';

import animationData from './data-loading.json';

const StyledContainer = styled.div`
  margin: 1em 0;
  display: flex;
  max-width: 140px;
  align-items: center;
  border-radius: 6px;
  padding: 10px;
  background-color: #ecf9f9;

  .dls-spinner {
    width: 24px;
  }

  .dls-message {
    margin-left: 10px;
  }
`;

function DataLoadingSpinner() {
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <StyledContainer>
      <div className="dls-spinner">
        <Lottie
          options={lottieOptions}
          width={32}
          height={32}
          isClickToPauseDisabled
        />
      </div>
      <div className="dls-message">
        <Body>Loading...</Body>
      </div>
    </StyledContainer>
  );
}

DataLoadingSpinner.propTypes = {};

export default DataLoadingSpinner;
