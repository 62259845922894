import { defineMessages } from 'react-intl';

export default defineMessages({
  required: {
    defaultMessage: 'This is a required field',
  },
  exists: {
    defaultMessage: 'Email already exists',
  },
  passwordLengthRequirement: {
    defaultMessage: 'Password must be at least 8 characters long',
  },
  passwordConfirmationMustMatch: {
    defaultMessage: 'Passwords must match',
  },
  enterAnEmailWithExample: {
    defaultMessage: 'Please enter an email address - Example, user@example.com',
  },
});
