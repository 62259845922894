/*
 *
 * UserInformation reducer
 *
 */

import { fromJS } from 'immutable';
import {
  FETCH_CONTACT_DETAILS,
  FETCH_CONTACT_DETAILS_ERROR,
  FETCH_CONTACT_DETAILS_SUCCESS,
  UPDATE_CONTACT_DETAILS,
  UPDATE_CONTACT_DETAILS_ERROR,
  UPDATE_CONTACT_DETAILS_SUCCESS,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_ERROR,
  UPDATE_PASSWORD_SUCCESS,
  CREATE_DEPENDANT,
  CREATE_DEPENDANT_ERROR,
  CREATE_DEPENDANT_SUCCESS,
  FETCH_DEFAULT_CARD,
  FETCH_DEFAULT_CARD_ERROR,
  FETCH_DEFAULT_CARD_SUCCESS,
  UPDATE_DEFAULT_CARD,
  UPDATE_DEFAULT_CARD_ERROR,
  UPDATE_DEFAULT_CARD_SUCCESS,
  FETCH_BENEFITS_COVERAGE,
  FETCH_BENEFITS_COVERAGE_SUCCESS,
  FETCH_BENEFITS_COVERAGE_ERROR,
  UPDATE_BENEFITS_COVERAGE,
  UPDATE_BENEFITS_COVERAGE_SUCCESS,
  UPDATE_BENEFITS_COVERAGE_ERROR,
  FETCH_COMPANY,
  FETCH_COMPANY_ERROR,
  FETCH_COMPANY_SUCCESS,
  FETCH_COUPLES_INFO,
  FETCH_COUPLES_INFO_SUCCESS,
  FETCH_COUPLES_INFO_ERROR,
  CREATE_GUARDIANS_SUCCESS,
  CREATE_GUARDIANS_ERROR,
  FETCH_GUARDIANS_SUCCESS,
  FETCH_GUARDIANS_ERROR,
  FETCH_GUARDIANS,
} from './constants';

export const initialState = fromJS({
  loading: 'false',
  contactDetails: {
    name: '',
    relationship: '',
    phone: '',
  },
  passwordError: null,
  dependant: {
    message: '',
    child: {
      first_name: '',
    },
  },
  defaultCard: {},
  benefitsCoverage: {},
  company: {},
  couplesInfo: {},
  guardians: [],
});

function preferenceReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CONTACT_DETAILS:
      return state.set('loading', true);
    case FETCH_CONTACT_DETAILS_ERROR:
      return state.set('loading', false).set('error', action.error);
    case FETCH_CONTACT_DETAILS_SUCCESS:
      return state
        .set('loading', false)
        .mergeDeep({ contactDetails: action.contactDetails });
    case UPDATE_CONTACT_DETAILS:
      return state.set('loading', true);
    case UPDATE_CONTACT_DETAILS_ERROR:
      return state.set('loading', false).set('error', action.error);
    case UPDATE_CONTACT_DETAILS_SUCCESS:
      return state
        .set('loading', false)
        .mergeDeep({ contactDetails: action.contactDetails });
    case UPDATE_PASSWORD:
      return state.set('loading', true);
    case UPDATE_PASSWORD_ERROR:
      return state
        .set('loading', false)
        .mergeDeep({ error: action.error.data });
    case UPDATE_PASSWORD_SUCCESS:
      return state.set('loading', false).set('error', null);
    case CREATE_DEPENDANT:
      return state.set('loading', true);
    case CREATE_DEPENDANT_ERROR:
      return state
        .set('loading', false)
        .setIn(['dependant', 'message'], action.error.data.message);
    case CREATE_DEPENDANT_SUCCESS:
      return state
        .set('loading', false)
        .setIn(['dependant', 'message'], '')
        .setIn(['dependant', 'child'], action.dependant.child);
    case FETCH_DEFAULT_CARD:
      return state.set('loading', false);
    case FETCH_DEFAULT_CARD_ERROR:
      return state.set('loading', false).set('error', action.error);
    case FETCH_DEFAULT_CARD_SUCCESS:
      return state
        .set('loading', false)
        .mergeDeep({ defaultCard: action.card });
    case UPDATE_DEFAULT_CARD:
      return state.set('loading', false);
    case UPDATE_DEFAULT_CARD_ERROR:
      return state.set('loading', false).set('error', action.error);
    case UPDATE_DEFAULT_CARD_SUCCESS:
      return state
        .set('loading', false)
        .mergeDeep({ defaultCard: action.card });
    case FETCH_BENEFITS_COVERAGE:
      return state.set('loading', false);
    case FETCH_BENEFITS_COVERAGE_SUCCESS:
      return state
        .set('loading', false)
        .set('benefitsCoverage', fromJS(action.benefitsCoverage));
    case UPDATE_BENEFITS_COVERAGE:
      return state.set('loading', false);
    case UPDATE_BENEFITS_COVERAGE_SUCCESS:
      return state
        .set('loading', false)
        .mergeDeep({ benefitsCoverage: action.benefitsCoverage });
    case UPDATE_BENEFITS_COVERAGE_ERROR:
      return state.set('loading', false).mergeDeep({ error: action.error });

    case FETCH_BENEFITS_COVERAGE_ERROR:
      return state.set('loading', false).mergeDeep({ error: action.error });

    case FETCH_COMPANY:
      return state.set('loading', true);
    case FETCH_COMPANY_ERROR:
      return state.set('loading', false).set('error', action.error);
    case FETCH_COMPANY_SUCCESS:
      return state.set('loading', false).mergeDeep({ company: action.company });
    case FETCH_COUPLES_INFO:
      return state.set('loading', true).set('error', false);
    case FETCH_COUPLES_INFO_ERROR:
      return state.set('loading', false).set('error', action.error);
    case FETCH_COUPLES_INFO_SUCCESS:
      return state
        .set('loading', false)
        .set('couplesInfo', fromJS(action.couplesInfo));
    case CREATE_GUARDIANS_SUCCESS:
      return state
        .set('loading', false)
        .mergeDeep({ guardians: action.guardians });
    case CREATE_GUARDIANS_ERROR:
      return state.set('loading', false).set('error', action.error);
    case FETCH_GUARDIANS:
      return state.set('loading', true);
    case FETCH_GUARDIANS_SUCCESS:
      return state
        .set('loading', false)
        .mergeDeep({ guardians: action.guardians });
    case FETCH_GUARDIANS_ERROR:
      return state.set('loading', false).set('error', action.error);
    default:
      return state;
  }
}

export default preferenceReducer;
