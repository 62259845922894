import { CommonLabelStyles } from 'components/CommonStyles';
import React, { useState } from 'react';
import styled from 'styled-components';
import PropType from 'prop-types';
import ChevronDown from '../../images/Heroicons/solid/chevron-down.svg';

const Col = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1000;
`;
const StyledLabel = styled.span`
  font-family: 'TT Commons Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  letter-spacing: 0.02em;
  color: #696e82;
  margin-bottom: 8px;
  &.required::after {
    content: '*';
    font-weight: bold;
    color: #8a4f49;
    padding-left: 5px;
  }
`;
const StyledHelpText = styled.span`
  ${CommonLabelStyles} color: #696e82;
  margin-top: 8px;
`;
const Icon = styled.img`
  width: 16px;
  height: 16px;
  position: absolute;
  top: 41px;
  right: 18px;
  z-index: 0;
`;
const StyledSelect = styled.select`
  padding: 12px;
  padding-right: 46px;
  background: none;
  position: static;
  cursor: pointer;
  z-index: 1;
  max-width: 374px;
  width: 100%;
  height: 40px;

  outline: 1px solid #dcdee8;
  border-radius: 10px;
  &:focus-visible,
  &:focus {
    outline: 2px solid #343a54 !important;
  }
  &:hover {
    outline: 1px solid #c5c8d4;
  }

  font-family: 'TT commons pro';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  letter-spacing: 0.02em;
  color: #2f3345;
  appearance: none;
`;

const SqDropdownButton = (props) => {
  const { label, helpText, style, onChange, initValue, selections, required } =
    props;
  // eslint-disable-next-line
  const [selected, setSelection] = useState(initValue);
  return (
    <Col className="select_box" style={style}>
      {label && (
        <StyledLabel className={required ? 'required' : ''}>
          {label}
        </StyledLabel>
      )}
      <StyledSelect selected="" value={selected} onChange={(e) => onChange(e)}>
        {selections &&
          selections.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
      </StyledSelect>
      <Icon src={ChevronDown} />
      {helpText && <StyledHelpText>{helpText}</StyledHelpText>}
    </Col>
  );
};
SqDropdownButton.propTypes = {
  selections: PropType.array.isRequired,
  onChange: PropType.func,
  label: PropType.object,
  helpText: PropType.object,
  style: PropType.object,
  initValue: PropType.object,
};
export default SqDropdownButton;
