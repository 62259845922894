import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import Button from 'components/Button';

import messages from '../messages';

const StyledParagraphs = styled.div`
  font-size: 16px;
  color: #0f2045;
  margin-top: 60px;
  max-width: 620px;
`;

class Introduction extends PureComponent {
  handleNext = () => {
    this.props.changeStep(1);
  };

  render() {
    return (
      <form onSubmit={this.handleNext}>
        <StyledParagraphs>
          <p>
            <FormattedMessage {...messages.theEmployeeReliefFundServices} />
          </p>
          <br />
          <p>
            <FormattedMessage {...messages.someExamples0fSituationsWhere} />
          </p>
        </StyledParagraphs>
        <Button
          secondary
          label={messages.next}
          type="submit"
          style={{ margin: '60px 0 40px 0' }}
        />
      </form>
    );
  }
}

Introduction.propTypes = {
  changeStep: PropTypes.func.isRequired,
};

export default Introduction;
