import React from 'react';
import styled from 'styled-components';
import { compose } from 'redux';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import H1 from 'components/H1';
import H2 from 'components/H2';
import H3 from 'components/H3';
import Breadcrumbs from 'components/Breadcrumbs';
import H4 from 'components/H4';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form/immutable';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import SelectInput from 'components/SelectInput';
import { fetchCompany } from 'containers/Dashboard/actions';
import P from 'components/P';
import { createDependant } from '../actions';
import reducer from '../reducer';
import saga from '../saga';
import messages from '../messages';
import TextInput from './TextInput';
import { validateRequired, validateEmail } from './validate';
import { errorRed, green } from '../../../global-styles';
// import PropTypes from 'prop-types';

const Panel = styled.div`
  min-height: 500px;
  border-radius: 20px;
  box-shadow: 0 0 50px 0 rgba(40, 71, 140, 0.2);
  background-color: #ffffff;
  padding: 60px;
`;

const Divider = styled.div`
  width: 200px;
  height: 1px;
  border: solid 1px #dfe7f7;
  margin-top: 60px;
  margin-bottom: 60px;
`;

const DividerMedium = styled.div`
  width: 40px;
  height: 1px;
  border: solid 1px #dfe7f7;
  margin-left: 20px;
  margin-right: 20px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 60px;
`;

const RowWrapper = styled.div`
  display: flex;
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

const Error = styled(P)`
  color: ${errorRed};
`;
const Success = styled(P)`
  color: ${green};
`;

class InviteDependants extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.fetchCompany();
  }

  onSubmit = (values) => {
    this.props.createDependant(values.toJS());
  };

  render() {
    const { handleSubmit, dependantsRemaining, child } = this.props;
    const childName = child && child.first_name + child && child.last_name;
    return (
      <div>
        <Breadcrumbs
          names={[
            <FormattedMessage key={'breadcrumb1'} {...messages.preferences} />,
            <FormattedMessage
              key={'breadcrumb2'}
              {...messages.inviteDependant}
            />,
          ]}
        />
        <Panel>
          <H1>
            <FormattedMessage {...messages.inviteDependant} />
          </H1>
          <Divider />
          <Wrapper>
            <H4 bold>
              <FormattedMessage {...messages.maxAmountOfDependants} />
            </H4>
            <DividerMedium />
            <H2 color="#2e5fca">{dependantsRemaining}</H2>
          </Wrapper>
          <H3 style={{ marginBottom: '40px' }}>
            <FormattedMessage {...messages.yourDependantsInfo} />
          </H3>
          <form onSubmit={handleSubmit(this.onSubmit)}>
            <RowWrapper>
              <div style={{ marginRight: '40px' }}>
                <Field
                  component={TextInput}
                  label={messages.firstName}
                  name="first_name"
                  validate={[validateRequired]}
                  placeholder="Steve"
                />
              </div>
              <Field
                component={TextInput}
                label={messages.lastName}
                validate={[validateRequired]}
                name="last_name"
                placeholder="Rogers"
              />
            </RowWrapper>
            <RowWrapper>
              <div style={{ marginRight: '40px' }}>
                <Field
                  component={TextInput}
                  label={messages.email}
                  validate={[validateRequired, validateEmail]}
                  name="email"
                  placeholder=""
                />
              </div>
              <div style={{ width: '220px' }}>
                <Field
                  component={SelectInput}
                  label={messages.relationshipToYou}
                  validate={[validateRequired]}
                  name="relationship"
                  options={[
                    { label: 'Spouse', value: 'spouse' },
                    { label: 'Child', value: 'child' },
                  ]}
                />
              </div>
            </RowWrapper>
            <Error>{this.props.errorMessage}</Error>
            {childName && <Success>{childName} sucessfully added!</Success>}
            <Button label={messages.sendInvite} primary type="submit" />
          </form>
        </Panel>
      </div>
    );
  }
}

InviteDependants.propTypes = {};

function mapStateToProps(state) {
  const error = state.getIn(['preferences', 'dependant', 'message']);
  const child = state.getIn(['preferences', 'dependant', 'child']);
  return {
    dependantsRemaining: state.getIn([
      'preferences',
      'company',
      'dependants_remaining',
    ]),
    errorMessage: error,
    child,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCompany: () => dispatch(fetchCompany()),
    createDependant: (values) => dispatch(createDependant(values)),
  };
}

const withReducer = injectReducer({ key: 'preferences', reducer });
const withSaga = injectSaga({ key: 'preferences', saga });

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(
  reduxForm({
    form: 'inviteDependants',
    enableReinitialize: true,
  })(InviteDependants),
);
