/*
 *
 * MyCareProvider reducer
 *
 */

import { fromJS } from 'immutable';
import {
  DESTROY_MATCH,
  DESTROY_MATCH_SUCCESS,
  DESTROY_MATCH_ERROR,
  FETCH_PRACTITIONER,
  FETCH_PRACTITIONER_ERROR,
  FETCH_PRACTITIONER_SUCCESS,
  UPLOAD_FILE,
  UPLOAD_FILE_ERROR,
  UPLOAD_FILE_SUCCESS,
  FETCH_COUPLES_INFO,
  FETCH_COUPLES_INFO_SUCCESS,
  FETCH_COUPLES_INFO_ERROR,
  FETCH_CARE_TEAM,
  FETCH_CARE_TEAM_SUCCESS,
  FETCH_CARE_TEAM_ERROR,
  FETCH_COMPANY,
  FETCH_COMPANY_SUCCESS,
  FETCH_COMPANY_ERROR,
  ENTER_PARTNER_CODE,
  ENTER_PARTNER_CODE_SUCCESS,
  ENTER_PARTNER_CODE_ERROR,
} from './constants';

export const initialState = fromJS({
  couplesInfo: {
    error: false,
    loading: false,
    data: {},
    partnerToken: null,
  },
  careTeam: {
    error: false,
    loading: false,
    data: {},
  },
  company: {},
});

function myCareProviderReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PRACTITIONER:
      return state.set('loading', true);
    case FETCH_PRACTITIONER_ERROR:
      return state.set('loading', false).set('error', action.error);
    case FETCH_PRACTITIONER_SUCCESS:
      return state.set('loading', false).mergeDeep(action.practitioner);
    case UPLOAD_FILE:
      return state.set('loading', true);
    case UPLOAD_FILE_SUCCESS:
      return state.set('loading', false).set('file', action.file);
    case UPLOAD_FILE_ERROR:
      return state.set('loading', false).set('error', action.error);
    case DESTROY_MATCH:
      return state.set('loading', true);
    case DESTROY_MATCH_SUCCESS:
      return state.set('loading', false).set('matched', false);
    case DESTROY_MATCH_ERROR:
      return state.set('loading', false).set('error', action.error);
    case FETCH_COUPLES_INFO:
      return state
        .setIn(['couplesInfo', 'loading'], true)
        .setIn(['couplesInfo', 'error'], false);
    case FETCH_COUPLES_INFO_ERROR:
      return state
        .setIn(['couplesInfo', 'loading'], false)
        .setIn(['couplesInfo', 'error'], action.error);
    case FETCH_COUPLES_INFO_SUCCESS:
      return state
        .setIn(['couplesInfo', 'loading'], false)
        .setIn(['couplesInfo', 'data'], fromJS(action.couplesInfo));
    case FETCH_CARE_TEAM:
      return state
        .setIn(['careTeam', 'loading'], true)
        .setIn(['careTeam', 'error'], false);
    case FETCH_CARE_TEAM_ERROR:
      return state
        .setIn(['careTeam', 'loading'], false)
        .setIn(['careTeam', 'error'], action.error);
    case FETCH_CARE_TEAM_SUCCESS:
      return state
        .setIn(['careTeam', 'loading'], false)
        .setIn(['careTeam', 'data'], fromJS(action.careTeam));
    case FETCH_COMPANY:
      return state.set('loading', true);
    case FETCH_COMPANY_ERROR:
      return state.set('loading', false).set('error', action.error);
    case FETCH_COMPANY_SUCCESS:
      return state.set('loading', false).mergeDeep({ company: action.company });
    case ENTER_PARTNER_CODE:
      return state.set('loading', true);
    case ENTER_PARTNER_CODE_SUCCESS:
      return state
        .set('loading', false)
        .mergeDeep({ partnerToken: action.code });
    case ENTER_PARTNER_CODE_ERROR:
      return state.set('loading', false).set('error', action.error);
    default:
      return state;
  }
}

export default myCareProviderReducer;
