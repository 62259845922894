import styled from 'styled-components';
import { phoneSize } from '../../global-styles';

export default styled.div`
  height: 70px;
  background-color: #ffffff;
  box-sizing: border-box;
  &.navigation-top {
    padding: 20px 40px;
    border-bottom: 2px solid #dfe7f7;
    display: flex;
    justify-content: space-between;
    @media (max-width: ${phoneSize}) {
      border-bottom: unset;
      padding: 20px;
    }
  }
  &.navigation-bottom {
    display: flex;
    justify-content: center;
    padding: 24px 0px;
    white-space: nowrap;
    @media (max-width: ${phoneSize}) {
      display: none;
    }
  }
`;
