import { phoneSizeEnd } from 'global-styles';
import styled from 'styled-components';

const StyledHorizontalLine = styled.div`
  height: 0;
  width: 100%;
  border-bottom: solid 1px rgba(46, 95, 202, 0.1);
  margin-top: 60px;
  margin-bottom: 60px;
`;

export const StyledVerticalLine = styled.div`
  align-self: stretch;
  margin: 0 20px;
  border-right: solid 1px rgba(46, 95, 202, 0.1);
  @media (max-width: ${phoneSizeEnd}) {
    display: none;
  }
`;

export default StyledHorizontalLine;
