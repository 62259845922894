/*
 *
 * TopNavigationBar actions
 *
 */

import {
  FETCH_USER_SETTINGS,
  FETCH_USER_SETTINGS_SUCCESS,
  FETCH_USER_SETTINGS_ERROR,
  UPDATE_USER_SETTINGS,
  UPDATE_USER_SETTINGS_SUCCESS,
  UPDATE_USER_SETTINGS_ERROR,
} from './constants';

export function updateUserSettings(data, callback) {
  return {
    type: UPDATE_USER_SETTINGS,
    data,
    callback,
  };
}
export function updateUserSettingsSuccess(settings) {
  return {
    type: UPDATE_USER_SETTINGS_SUCCESS,
    settings,
  };
}

export function updateUserSettingsError(error) {
  return {
    type: UPDATE_USER_SETTINGS_ERROR,
    error,
  };
}

export function fetchUserSettings(callback) {
  return {
    type: FETCH_USER_SETTINGS,
    callback,
  };
}
export function fetchUserSettingsSuccess(settings) {
  return {
    type: FETCH_USER_SETTINGS_SUCCESS,
    settings,
  };
}

export function fetchUserSettingsError(error) {
  return {
    type: FETCH_USER_SETTINGS_ERROR,
    error,
  };
}
