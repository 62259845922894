import { CommonLabelStyles } from 'components/CommonStyles';
import Icon from 'components/SqIcon/Icon';
import React from 'react';
import styled from 'styled-components';
import { phoneSize, eAZoom400 } from '../../global-styles';
const StyledTextInput = styled.input`
  padding: 12px;
  height: 40px;

  background: #ffffff;
  outline: ${({ error }) =>
    error ? '1px solid #8a4f49' : '1px solid #dcdee8'};
  border-radius: 10px;
  &:focus-visible,
  &:focus {
    outline: 2px solid #343a54 !important;
  }
  &:hover {
    outline: 1px solid #c5c8d4;
  }
  @media (max-width: ${phoneSize}) {
    max-width: 374px;
    width: 100%;
  }

  font-family: 'TT commons pro';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  letter-spacing: 0.02em;
  color: #2f3345;
  &.rightPadding {
    padding-right: 46px;
  }
  &.leftPadding {
    padding-left: 46px;
  }
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  height: 40px;
  width: 374px;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
const StyledLabel = styled.label`
  font-family: 'TT Commons Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  letter-spacing: 0.02em;
  color: #696e82;
  margin-bottom: 8px;
`;
const StyledHelpText = styled.span`
  ${CommonLabelStyles} color: #696e82;
  margin-top: 8px;
`;
const StyledButton = styled.button`
  left: 13px;
  top: 12px;
  position: absolute;
  &.Right {
    left: auto;
    right: 13px;
  }

  @media screen and (max-width: ${eAZoom400}) and (min-device-width: ${phoneSize}) {
    &.Right {
      left: auto;
      right: 0px;
    }
  }
`;
const StyledIcon = styled(Icon)`
  left: 13px;
  top: 12px;
  position: absolute;
`;
const Asterisk = styled.span`
  color: #8a4f49;
`;

export const SqTextInputWithLabel = (props) => {
  const {
    label,
    onChange,
    leftIconName,
    rightIconName,
    forwardedRef,
    style,
    input,
    meta,
    rightIconOnclick,
    rightIconAriaLabel,
    required = true,
  } = props;
  return (
    <Col style={style}>
      {label && (
        <StyledLabel>
          {label}
          {required && <Asterisk> *</Asterisk>}
        </StyledLabel>
      )}
      <SqTextInput
        ref={forwardedRef}
        aria-label={
          label && typeof label === 'string' ? label : input && input.name
        }
        aria-haspopup={false}
        {...{ onChange, leftIconName, rightIconName }}
        {...props}
        rightIconOnclick={rightIconOnclick}
        rightIconAriaLabel={rightIconAriaLabel}
      />
      {meta && meta.error && (meta.submitFailed || meta.touched) && (
        <StyledHelpText tabIndex="0" style={{ color: '#8A4F49' }}>
          {meta.error}
        </StyledHelpText>
      )}
    </Col>
  );
};

const SqTextInput = (props) => {
  const {
    style,
    leftIconName,
    rightIconName,
    type,
    forwardedRef,
    onChange,
    input,
    rightIconOnclick,
    rightIconAriaLabel,
    ...rest
  } = props;
  if (leftIconName || rightIconName) {
    return (
      <Row style={style}>
        {leftIconName && <StyledIcon isSolid name={leftIconName} />}
        <StyledTextInput
          ref={forwardedRef}
          type={type || 'text'}
          className={`${rightIconName ? 'rightPadding' : ''} ${
            leftIconName ? 'leftPadding' : ''
          }`}
          {...{ onChange, leftIconName, rightIconName, ...rest }}
          {...props}
          {...input}
        />
        {rightIconName && (
          <StyledButton
            className="Right"
            onClick={rightIconOnclick ? () => rightIconOnclick() : () => {}}
            type="button"
            aria-label={rightIconAriaLabel}
          >
            <Icon isSolid name={rightIconName} color={props.color} />
          </StyledButton>
        )}
      </Row>
    );
  }
  return <StyledTextInput ref={forwardedRef} {...props} {...(input || {})} />;
};

export default SqTextInput;
