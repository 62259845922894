/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux-immutable';

import { reducer as formReducer } from 'redux-form/immutable';

import languageProviderReducer from 'containers/LanguageProvider/reducer';
import userInformationReducer from 'containers/UserInformation/reducer';
import companyReducer from 'containers/Company/reducer';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  return combineReducers({
    company: companyReducer,
    user: userInformationReducer,
    language: languageProviderReducer,
    form: formReducer,
    ...injectedReducers,
  });
}
