/*
 *
 * Messaging reducer
 *
 */

import { fromJS } from 'immutable';
import _ from 'lodash';
import {
  DEFAULT_ACTION,
  FETCH_CHATS,
  FETCH_CHATS_SUCCESS,
  FETCH_CHATS_ERROR,
  FETCH_TWILIO_TOKEN,
  FETCH_TWILIO_TOKEN_SUCCESS,
  FETCH_TWILIO_TOKEN_ERROR,
  FETCH_CHAT,
  FETCH_CHAT_SUCCESS,
  FETCH_CHAT_ERROR,
  FETCH_MESSAGES,
  FETCH_MESSAGES_SUCCESS,
  FETCH_MESSAGES_ERROR,
} from './constants';

export const initialState = fromJS({
  chats: {},
  token: {},
  chat: {},
  messages: {},
});

function messagingReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TWILIO_TOKEN:
      return state.set('loading', true);
    case FETCH_TWILIO_TOKEN_ERROR:
      return state.set('loading', false).set('error', action.error);
    case FETCH_TWILIO_TOKEN_SUCCESS:
      return state.set('loading', false).mergeDeep({ token: action.token });
    case FETCH_CHATS:
      return state.set('loading', true);
    case FETCH_CHATS_ERROR:
      return state.set('loading', false).set('error', action.error);
    case FETCH_CHATS_SUCCESS:
      return state
        .set('loading', false)
        .merge({ chats: _.mapKeys(action.chats, 'id') });
    case FETCH_CHAT:
      return state.set('loading', true);
    case FETCH_CHAT_ERROR:
      return state.set('loading', false).set('error', action.error);
    case FETCH_CHAT_SUCCESS:
      return state.set('loading', false).mergeDeep({ chat: action.chats });
    case FETCH_MESSAGES:
      return state.set('loading', true);
    case FETCH_MESSAGES_ERROR:
      return state.set('loading', false).set('error', action.error);
    case FETCH_MESSAGES_SUCCESS:
      return state.set('loading', false).merge({ messages: action.messages });
    case DEFAULT_ACTION:
      return state;
    default:
      return state;
  }
}

export default messagingReducer;
