/*
 *
 * Booking reducer
 *
 */

import { fromJS } from 'immutable';
import {
  FETCH_COUPLES_INFO,
  FETCH_COUPLES_INFO_SUCCESS,
  FETCH_COUPLES_INFO_ERROR,
  CONFIRM_CONSENT_INFO,
  CONFIRM_CONSENT_INFO_ERROR,
  CONFIRM_CONSENT_INFO_SUCCESS,
} from './constants';

export const initialState = fromJS({
  coupleInfo: {
    data: null,
    error: false,
    loading: false,
  },
  partnerConsent: {
    data: null,
    error: false,
    loading: false,
  },
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_COUPLES_INFO:
      return state
        .setIn(['coupleInfo', 'loading'], true)
        .setIn(
          ['coupleInfo', 'data'],
          initialState.getIn(['coupleInfo', 'data']),
        );
    case FETCH_COUPLES_INFO_ERROR:
      return state
        .setIn(['coupleInfo', 'loading'], false)
        .setIn(['coupleInfo', 'error'], action.error);
    case FETCH_COUPLES_INFO_SUCCESS:
      return state
        .setIn(['coupleInfo', 'loading'], false)
        .setIn(['coupleInfo', 'data'], action.coupleInfo);

    case CONFIRM_CONSENT_INFO:
      return state
        .setIn(['partnerConsent', 'loading'], true)
        .setIn(
          ['partnerConsent', 'data'],
          initialState.getIn(['partnerConsent', 'data']),
        );
    case CONFIRM_CONSENT_INFO_ERROR:
      return state
        .setIn(['partnerConsent', 'loading'], false)
        .setIn(['partnerConsent', 'error'], action.error);
    case CONFIRM_CONSENT_INFO_SUCCESS:
      return state
        .setIn(['partnerConsent', 'loading'], false)
        .setIn(['partnerConsent', 'data'], action.partnerConsent);
    default:
      return state;
  }
}

export default reducer;
