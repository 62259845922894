import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the myCareProvider state domain
 */

const selectMyCareProviderDomain = (state) =>
  state.get('myCareProvider', initialState);

const makeSelectCouplesInfo = () =>
  createSelector(selectMyCareProviderDomain, (substate) =>
    substate.get('couplesInfo'),
  );

const makeSelectCareTeam = () =>
  createSelector(selectMyCareProviderDomain, (substate) =>
    substate.get('careTeam'),
  );

const makeSelectCompany = () =>
  createSelector(selectMyCareProviderDomain, (substate) =>
    substate.get('company'),
  );

/**
 * Default selector used by MyCareProvider
 */

const makeSelectMyCareProvider = () =>
  createSelector(selectMyCareProviderDomain, (substate) => substate);

export default makeSelectMyCareProvider;
export {
  selectMyCareProviderDomain,
  makeSelectCouplesInfo,
  makeSelectCareTeam,
  makeSelectCompany,
};
