import { takeLatest, put, call } from 'redux-saga/effects';

import { API_URL } from '../../utils/environment';
import { get } from '../../utils/request';
import {
  fetchCurrentTermsAndConditionsSuccess,
  fetchCurrentTermsAndConditionsError,
} from './actions';
import { FETCH_CURRENT_TERMS_AND_CONDITIONS } from './constants';

function* fetchCurrentTermsAndConditions({ callback }) {
  const requestURL = `${API_URL}/terms_and_conditions`;

  try {
    const termsAndConditions = yield call(get, requestURL);
    yield put(fetchCurrentTermsAndConditionsSuccess(termsAndConditions));
    if (callback) callback(termsAndConditions);
  } catch (error) {
    yield put(fetchCurrentTermsAndConditionsError(error));
    if (callback) callback(null, error);
  }
}

// Individual exports for testing
export default function* termsAndConditionsSaga() {
  yield takeLatest(
    FETCH_CURRENT_TERMS_AND_CONDITIONS,
    fetchCurrentTermsAndConditions,
  );
}
