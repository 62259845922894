export const SQTOKEN = {
  'core-color-neutral-white': '#FFFFFF',
  'core-color-neutral-black': '#000000',
  'core-color-slate-0': '#F7F8FC',
  'core-color-slate-5': '#EBECF2',
  'core-color-slate-10': '#DCDEE8',
  'core-color-slate-20': '#C5C8D4',
  'core-color-slate-30': '#ACB0BF',
  'core-color-slate-40': '#979BAB',
  'core-color-slate-50': '#808597',
  'core-color-slate-60': '#696E82',
  'core-color-slate-70': '#555A6E',
  'core-color-slate-80': '#41465A',
  'core-color-slate-90': '#2F3345',
  'core-color-slate-100': '#1F2331',
  'core-color-blue-0': '#F0F2FA',
  'core-color-blue-5': '#E3E7F9',
  'core-color-blue-10': '#CBD4F7',
  'core-color-blue-20': '#AEBCF2',
  'core-color-blue-30': '#9BAAE8',
  'core-color-blue-40': '#8899D9',
  'core-color-blue-50': '#5D70BA',
  'core-color-blue-60': '#52619E',
  'core-color-blue-70': '#454E73',
  'core-color-blue-80': '#343A54',
  'core-color-blue-90': '#282D40',
  'core-color-blue-100': '#1F2331',
  'core-color-green-0': '#F0FAF7',
  'core-color-green-5': '#DBEDE7',
  'core-color-green-10': '#C8E0D8',
  'core-color-green-20': '#AECCC2',
  'core-color-green-30': '#93C2B2',
  'core-color-green-40': '#74B29E',
  'core-color-green-50': '#529C83',
  'core-color-green-60': '#3E7864',
  'core-color-green-70': '#39594F',
  'core-color-green-80': '#334A42',
  'core-color-green-90': '#2A3733',
  'core-color-green-100': '#1F2624',
  'core-color-yellow-0': '#FAFAF0',
  'core-color-yellow-5': '#F5F5DB',
  'core-color-yellow-10': '#F0F0C9',
  'core-color-yellow-20': '#E8E8B1',
  'core-color-yellow-30': '#DEDE96',
  'core-color-yellow-40': '#C7C775',
  'core-color-yellow-50': '#B0B058',
  'core-color-yellow-60': '#72723F',
  'core-color-yellow-70': '#545437',
  'core-color-yellow-80': '#3D3D2B',
  'core-color-yellow-90': '#2E2E22',
  'core-color-yellow-100': '#21211A',
  'core-color-orange-0': '#FAF6F0',
  'core-color-orange-5': '#F8EFDF',
  'core-color-orange-10': '#F2E4CA',
  'core-color-orange-20': '#EDD9B4',
  'core-color-orange-30': '#DFC698',
  'core-color-orange-40': '#C2A673',
  'core-color-orange-50': '#AD8F57',
  'core-color-orange-60': '#856F46',
  'core-color-orange-70': '#574C39',
  'core-color-orange-80': '#3D362A',
  'core-color-orange-90': '#2E2A22',
  'core-color-orange-100': '#211E19',
  'core-color-red-0': '#FAF1F0',
  'core-color-red-5': '#F5E1DF',
  'core-color-red-10': '#EDD2CF',
  'core-color-red-20': '#E5C1BD',
  'core-color-red-30': '#DBABA6',
  'core-color-red-40': '#C7867F',
  'core-color-red-50': '#AD645C',
  'core-color-red-60': '#8A4F49',
  'core-color-red-70': '#61403C',
  'core-color-red-80': '#402B29',
  'core-color-red-90': '#2E211F',
  'core-color-red-100': '#1F1918',
  'core-space-none': '0px',
  'core-space-xxxs': '0.125rem',
  'core-space-xxs': '0.25rem',
  'core-space-xs': '0.5rem',
  'core-space-sm': '0.75rem',
  'core-space-base': '1rem',
  'core-space-lg': '1.25rem',
  'core-space-xl': '1.5rem',
  'core-space-2xl': '2rem',
  'core-space-3xl': '2.5rem',
  'core-space-4xl': '3rem',
  'core-space-5xl': '3.5rem',
  'core-space-6xl': '4rem',
  'core-space-7xl': '4.5rem',
  'core-space-8xl': '6rem',
  'core-space-9xl': '8rem',
  'core-font-size-xxs': '0.625rem',
  'core-font-size-xs': '0.75rem',
  'core-font-size-sm': '0.875rem',
  'core-font-size-base': '1rem',
  'core-font-size-lg': '1.125rem',
  'core-font-size-xl': '1.25rem',
  'core-font-size-2xl': '1.5rem',
  'core-font-size-3xl': '1.875rem',
  'core-font-size-4xl': '2.25rem',
  'core-font-size-5xl': '3rem',
  'core-font-size-6xl': '3.75rem',
  'core-font-size-7xl': '4.5rem',
  'core-font-size-8xl': '6rem',
  'core-font-size-9xl': '8rem',
  'core-font-family-sans': 'TT Commons Pro',
  'core-font-family-serif': 'P22 Mackinac Pro',
  'core-font-weight-1-base': 'Medium',
  'core-font-weight-1-italic': 'Medium Italic',
  'core-font-weight-2-base': 'DemiBold',
  'core-font-weight-2-italic': 'DemiBold Italic',
  'core-font-weight-3-base': 'Bold',
  'core-font-weight-3-italic': 'Bold Italic',
  'core-line-height-none': '100%',
  'core-line-height-tight': '130%',
  'core-line-height-heading': '140%',
  'core-line-height-body-sm': '20px',
  'core-line-height-body-lg': '24px',
  'core-line-height-label': '20px',
  'core-line-height-caption': '16px',
  'core-border-radius-xxs': '4px',
  'core-border-radius-xs': '8px',
  'core-border-radius-sm': '10px',
  'core-border-radius-md': '12px',
  'core-border-radius-lg': '16px',
  'core-border-radius-xl': '20px',
  'core-border-radius-2xl': '32px',
  'core-border-radius-max': '999px',
  'core-size-xs': '0.5rem',
  'core-size-sm': '0.75rem',
  'core-size-base': '1rem',
  'core-size-lg': '1.25rem',
  'core-size-xl': '1.5rem',
  'core-size-2xl': '2rem',
  'core-size-3xl': '2.5rem',
  'core-size-4xl': '3rem',
  'core-size-5xl': '3.5rem',
  'core-size-6xl': '4rem',
  'core-size-7xl': '4.5rem',
  'core-size-8xl': '5rem',
  'core-letter-spacing-tighter': '-5%',
  'core-letter-spacing-tight': '-2%',
  'core-letter-spacing-base': '0%',
  'core-letter-spacing-wide': '2%',
  'core-letter-spacing-wider': '5%',
  'core-letter-spacing-widest': '10%',
  'core-border-width-0': '0px',
  'core-border-width-1': '1px',
  'core-border-width-2': '1.5px',
  'core-border-width-3': '2px',
  'core-shadow-border-hover-x': '0',
  'core-shadow-border-hover-y': '0',
  'core-shadow-border-hover-blur': '0',
  'core-shadow-border-hover-spread': '4',
  'core-shadow-border-hover-color': '#1f23310d',
  'core-shadow-border-hover-type': 'dropShadow',
  'core-shadow-focused-default-0-x': '0',
  'core-shadow-focused-default-0-y': '0',
  'core-shadow-focused-default-0-blur': '0',
  'core-shadow-focused-default-0-spread': '4',
  'core-shadow-focused-default-0-color': '#343A54',
  'core-shadow-focused-default-0-type': 'dropShadow',
  'core-shadow-focused-default-1-x': '0',
  'core-shadow-focused-default-1-y': '0',
  'core-shadow-focused-default-1-blur': '0',
  'core-shadow-focused-default-1-spread': '2',
  'core-shadow-focused-default-1-color': '#FFFFFF',
  'core-shadow-focused-default-1-type': 'dropShadow',
  'core-shadow-elevation-xs-0-x': '0',
  'core-shadow-elevation-xs-0-y': '8',
  'core-shadow-elevation-xs-0-blur': '5',
  'core-shadow-elevation-xs-0-spread': '-2',
  'core-shadow-elevation-xs-0-color': '#80859705',
  'core-shadow-elevation-xs-0-type': 'dropShadow',
  'core-shadow-elevation-xs-1-x': '0',
  'core-shadow-elevation-xs-1-y': '4',
  'core-shadow-elevation-xs-1-blur': '8',
  'core-shadow-elevation-xs-1-spread': '0',
  'core-shadow-elevation-xs-1-color': '#80859708',
  'core-shadow-elevation-xs-1-type': 'dropShadow',
  'core-shadow-elevation-sm-0-x': '0',
  'core-shadow-elevation-sm-0-y': '20',
  'core-shadow-elevation-sm-0-blur': '8',
  'core-shadow-elevation-sm-0-spread': '0',
  'core-shadow-elevation-sm-0-color': '#80859705',
  'core-shadow-elevation-sm-0-type': 'dropShadow',
  'core-shadow-elevation-sm-1-x': '0',
  'core-shadow-elevation-sm-1-y': '6',
  'core-shadow-elevation-sm-1-blur': '8',
  'core-shadow-elevation-sm-1-spread': '0',
  'core-shadow-elevation-sm-1-color': '#8085970e',
  'core-shadow-elevation-sm-1-type': 'dropShadow',
  'core-letter-case-uppercase': 'uppercase',
  'core-divider-right-x': '1',
  'core-divider-right-y': '0',
  'core-divider-right-blur': '0',
  'core-divider-right-spread': '0',
  'core-divider-right-color': '#DCDEE8',
  'core-divider-right-type': 'dropShadow',
  'core-divider-left-x': '-1',
  'core-divider-left-y': '0',
  'core-divider-left-blur': '0',
  'core-divider-left-spread': '0',
  'core-divider-left-color': '#DCDEE8',
  'core-divider-left-type': 'dropShadow',
  'core-divider-top-x': '0',
  'core-divider-top-y': '-1',
  'core-divider-top-blur': '0',
  'core-divider-top-spread': '0',
  'core-divider-top-color': '#DCDEE8',
  'core-divider-top-type': 'dropShadow',
  'core-divider-bottom-x': '0',
  'core-divider-bottom-y': '1',
  'core-divider-bottom-blur': '0',
  'core-divider-bottom-spread': '0',
  'core-divider-bottom-color': '#DCDEE8',
  'core-divider-bottom-type': 'dropShadow',
  'core-text-decoration-underline': 'underline',
  'core-filter-backdrop-brightness-darken-1': '0.97',
  'core-filter-backdrop-brightness-darken-2': '0.94',
  'alias-border-radius-pill': '999px',
  'alias-border-radius-circle': '999px',
  'alias-color-bg-base': '#FFFFFF',
  'alias-color-bg-alt': '#F7F8FC',
  'alias-color-bg-neutral': '#EBECF2',
  'alias-color-bg-accent': '#8899d91f',
  'alias-color-bg-info': '#52619E',
  'alias-color-bg-info-weak': '#E3E7F9',
  'alias-color-bg-success': '#3E7864',
  'alias-color-bg-success-weak': '#DBEDE7',
  'alias-color-bg-attention': '#72723F',
  'alias-color-bg-attention-weak': '#F5F5DB',
  'alias-color-bg-critical': '#8A4F49',
  'alias-color-bg-critical-weak': '#F5E1DF',
  'alias-color-bg-primary': '#343A54',
  'alias-color-bg-secondary': '#3E7864',
  'alias-color-bg-tertiary': '#E3E7F9',
  'alias-color-bg-disabled': '#EBECF2',
  'alias-color-bg-base-inverse': '#41465A',
  'alias-color-text-base': '#2F3345',
  'alias-color-text-weak': '#41465A',
  'alias-color-text-weaker': '#555A6E',
  'alias-color-text-weakest': '#696E82',
  'alias-color-text-disabled': '#ACB0BF',
  'alias-color-text-link-default': '#5D70BA',
  'alias-color-text-link-hover': '#52619E',
  'alias-color-text-link-pressed': '#454E73',
  'alias-color-text-link-visited': '#696E82',
  'alias-color-text-info': '#52619E',
  'alias-color-text-success': '#3E7864',
  'alias-color-text-attention': '#72723F',
  'alias-color-text-critical': '#8A4F49',
  'alias-color-border-base': '#DCDEE8',
  'alias-color-border-weak': '#EBECF2',
  'alias-color-border-hover': '#C5C8D4',
  'alias-color-border-pressed': '#ACB0BF',
  'alias-color-border-active': '#343A54',
  'alias-color-border-accent': '#343A54',
  'alias-color-border-critical': '#AD645C',
  'alias-color-border-disabled': '#EBECF2',
  'alias-color-icon-base': '#808597',
  'alias-color-icon-weak': '#979BAB',
  'alias-color-icon-disabled': '#C5C8D4',
  'alias-color-icon-accent': '#343A54',
  'alias-color-icon-info': '#5D70BA',
  'alias-color-icon-success': '#529C83',
  'alias-color-icon-attention': '#B0B058',
  'alias-color-icon-critical': '#AD645C',
  'alias-color-text-on-primary': '#FFFFFF',
  'alias-color-text-on-secondary': '#FFFFFF',
  'alias-color-text-on-tertiary': '#454E73',
  'alias-color-text-on-destructive': '#8A4F49',
  'alias-color-text-on-info': '#FFFFFF',
  'alias-color-text-on-info-weak': '#454E73',
  'alias-color-text-on-success': '#FFFFFF',
  'alias-color-text-on-success-weak': '#39594F',
  'alias-color-text-on-attention': '#FFFFFF',
  'alias-color-text-on-attention-weak': '#545437',
  'alias-color-text-on-critical': '#FFFFFF',
  'alias-color-text-on-critical-weak': '#61403C',
  'alias-color-text-on-neutral': '#555A6E',
  'alias-color-text-on-outline': '#555A6E',
  'alias-color-text-on-accent': '#343A54',
  'alias-color-text-on-base-inverse': '#FFFFFF',
  'alias-font-display-2xl-font-family': 'P22 Mackinac Pro',
  'alias-font-display-2xl-font-weight': 'Medium',
  'alias-font-display-2xl-line-height': '140%',
  'alias-font-display-2xl-font-size': '3rem',
  'alias-font-display-2xl-letter-spacing': '0%',
  'alias-font-display-2xl-paragraph-spacing': '0',
  'alias-font-display-2xl-text-decoration': 'none',
  'alias-font-display-2xl-text-case': 'none',
  'alias-font-display-xl-font-family': 'P22 Mackinac Pro',
  'alias-font-display-xl-font-weight': 'Medium',
  'alias-font-display-xl-line-height': '140%',
  'alias-font-display-xl-font-size': '2.25rem',
  'alias-font-display-xl-letter-spacing': '0%',
  'alias-font-display-xl-paragraph-spacing': '0',
  'alias-font-display-xl-text-decoration': 'none',
  'alias-font-display-xl-text-case': 'none',
  'alias-font-display-lg-font-family': 'P22 Mackinac Pro',
  'alias-font-display-lg-font-weight': 'Medium',
  'alias-font-display-lg-line-height': '140%',
  'alias-font-display-lg-font-size': '1.875rem',
  'alias-font-display-lg-letter-spacing': '0%',
  'alias-font-display-lg-paragraph-spacing': '0',
  'alias-font-display-lg-text-decoration': 'none',
  'alias-font-display-lg-text-case': 'none',
  'alias-font-display-md-font-family': 'TT Commons Pro',
  'alias-font-display-md-font-weight': 'DemiBold',
  'alias-font-display-md-line-height': '140%',
  'alias-font-display-md-font-size': '1.25rem',
  'alias-font-display-md-letter-spacing': '0%',
  'alias-font-display-md-paragraph-spacing': '0',
  'alias-font-display-md-text-decoration': 'none',
  'alias-font-display-md-text-case': 'none',
  'alias-font-display-sm-font-family': 'TT Commons Pro',
  'alias-font-display-sm-font-weight': 'DemiBold',
  'alias-font-display-sm-line-height': '140%',
  'alias-font-display-sm-font-size': '1rem',
  'alias-font-display-sm-letter-spacing': '0%',
  'alias-font-display-sm-paragraph-spacing': '0',
  'alias-font-display-sm-text-decoration': 'none',
  'alias-font-display-sm-text-case': 'none',
  'alias-font-subtitle-lg-font-family': 'TT Commons Pro',
  'alias-font-subtitle-lg-font-weight': 'DemiBold',
  'alias-font-subtitle-lg-line-height': '20px',
  'alias-font-subtitle-lg-font-size': '1rem',
  'alias-font-subtitle-lg-letter-spacing': '2%',
  'alias-font-subtitle-lg-paragraph-spacing': '0',
  'alias-font-subtitle-lg-text-decoration': 'none',
  'alias-font-subtitle-lg-text-case': 'none',
  'alias-font-subtitle-sm-font-family': 'TT Commons Pro',
  'alias-font-subtitle-sm-font-weight': 'DemiBold',
  'alias-font-subtitle-sm-line-height': '20px',
  'alias-font-subtitle-sm-font-size': '0.875rem',
  'alias-font-subtitle-sm-letter-spacing': '2%',
  'alias-font-subtitle-sm-paragraph-spacing': '0',
  'alias-font-subtitle-sm-text-decoration': 'none',
  'alias-font-subtitle-sm-text-case': 'none',
  'alias-font-body-lg-base-font-family': 'TT Commons Pro',
  'alias-font-body-lg-base-font-weight': 'Medium',
  'alias-font-body-lg-base-line-height': '24px',
  'alias-font-body-lg-base-font-size': '1rem',
  'alias-font-body-lg-base-letter-spacing': '2%',
  'alias-font-body-lg-base-paragraph-spacing': '0',
  'alias-font-body-lg-base-text-decoration': 'none',
  'alias-font-body-lg-base-text-case': 'none',
  'alias-font-body-lg-bold-font-family': 'TT Commons Pro',
  'alias-font-body-lg-bold-font-weight': 'DemiBold',
  'alias-font-body-lg-bold-line-height': '24px',
  'alias-font-body-lg-bold-font-size': '1rem',
  'alias-font-body-lg-bold-letter-spacing': '2%',
  'alias-font-body-lg-bold-paragraph-spacing': '0',
  'alias-font-body-lg-bold-text-decoration': 'none',
  'alias-font-body-lg-bold-text-case': 'none',
  'alias-font-body-lg-italic-font-family': 'TT Commons Pro',
  'alias-font-body-lg-italic-font-weight': 'Medium Italic',
  'alias-font-body-lg-italic-line-height': '24px',
  'alias-font-body-lg-italic-font-size': '1rem',
  'alias-font-body-lg-italic-letter-spacing': '2%',
  'alias-font-body-lg-italic-paragraph-spacing': '0',
  'alias-font-body-lg-italic-text-decoration': 'none',
  'alias-font-body-lg-italic-text-case': 'none',
  'alias-font-body-sm-base-font-family': 'TT Commons Pro',
  'alias-font-body-sm-base-font-weight': 'Medium',
  'alias-font-body-sm-base-line-height': '20px',
  'alias-font-body-sm-base-font-size': '0.875rem',
  'alias-font-body-sm-base-letter-spacing': '2%',
  'alias-font-body-sm-base-paragraph-spacing': '0',
  'alias-font-body-sm-base-text-decoration': 'none',
  'alias-font-body-sm-base-text-case': 'none',
  'alias-font-body-sm-bold-font-family': 'TT Commons Pro',
  'alias-font-body-sm-bold-font-weight': 'DemiBold',
  'alias-font-body-sm-bold-line-height': '20px',
  'alias-font-body-sm-bold-font-size': '0.875rem',
  'alias-font-body-sm-bold-letter-spacing': '2%',
  'alias-font-body-sm-bold-paragraph-spacing': '0',
  'alias-font-body-sm-bold-text-decoration': 'none',
  'alias-font-body-sm-bold-text-case': 'none',
  'alias-font-body-sm-italic-font-family': 'TT Commons Pro',
  'alias-font-body-sm-italic-font-weight': 'Medium Italic',
  'alias-font-body-sm-italic-line-height': '20px',
  'alias-font-body-sm-italic-font-size': '0.875rem',
  'alias-font-body-sm-italic-letter-spacing': '2%',
  'alias-font-body-sm-italic-paragraph-spacing': '0',
  'alias-font-body-sm-italic-text-decoration': 'none',
  'alias-font-body-sm-italic-text-case': 'none',
  'alias-font-label-strong-font-family': 'TT Commons Pro',
  'alias-font-label-strong-font-weight': 'DemiBold',
  'alias-font-label-strong-line-height': '20px',
  'alias-font-label-strong-font-size': '0.875rem',
  'alias-font-label-strong-letter-spacing': '2%',
  'alias-font-label-strong-paragraph-spacing': '0',
  'alias-font-label-strong-text-decoration': 'none',
  'alias-font-label-strong-text-case': 'none',
  'alias-font-label-weak-font-family': 'TT Commons Pro',
  'alias-font-label-weak-font-weight': 'Medium',
  'alias-font-label-weak-line-height': '20px',
  'alias-font-label-weak-font-size': '0.875rem',
  'alias-font-label-weak-letter-spacing': '2%',
  'alias-font-label-weak-paragraph-spacing': '0',
  'alias-font-label-weak-text-decoration': 'none',
  'alias-font-label-weak-text-case': 'none',
  'alias-font-label-weak-underline-font-family': 'TT Commons Pro',
  'alias-font-label-weak-underline-font-weight': 'Medium',
  'alias-font-label-weak-underline-line-height': '20px',
  'alias-font-label-weak-underline-font-size': '0.875rem',
  'alias-font-label-weak-underline-letter-spacing': '2%',
  'alias-font-label-weak-underline-paragraph-spacing': '0',
  'alias-font-label-weak-underline-text-decoration': 'underline',
  'alias-font-label-weak-underline-text-case': 'none',
  'alias-font-label-strong-underline-font-family': 'TT Commons Pro',
  'alias-font-label-strong-underline-font-weight': 'DemiBold',
  'alias-font-label-strong-underline-line-height': '20px',
  'alias-font-label-strong-underline-font-size': '0.875rem',
  'alias-font-label-strong-underline-letter-spacing': '2%',
  'alias-font-label-strong-underline-paragraph-spacing': '0',
  'alias-font-label-strong-underline-text-decoration': 'underline',
  'alias-font-label-strong-underline-text-case': 'none',
  'alias-font-caption-font-family': 'TT Commons Pro',
  'alias-font-caption-font-weight': 'Medium',
  'alias-font-caption-line-height': '16px',
  'alias-font-caption-font-size': '0.75rem',
  'alias-font-caption-letter-spacing': '2%',
  'alias-font-caption-paragraph-spacing': '0',
  'alias-font-caption-text-decoration': 'none',
  'alias-font-caption-text-case': 'none',
  'alias-font-overline-font-family': 'TT Commons Pro',
  'alias-font-overline-font-weight': 'DemiBold',
  'alias-font-overline-line-height': '16px',
  'alias-font-overline-font-size': '0.75rem',
  'alias-font-overline-letter-spacing': '10%',
  'alias-font-overline-paragraph-spacing': '0',
  'alias-font-overline-text-decoration': 'none',
  'alias-font-overline-text-case': 'uppercase',
  'alias-state-primary-hover': '#2D3248',
  'alias-state-primary-pressed': '#252A3B',
  'alias-state-secondary-hover': '#3A6E5C',
  'alias-state-secondary-pressed': '#376455',
  'alias-state-tertiary-hover': '#D7DCF2',
  'alias-state-tertiary-pressed': '#CCD2EA',
  'alias-state-base-hover': '#1f233108',
  'alias-state-base-pressed': '#1f23310f',
  'alias-state-destructive-hover': '#8a4f490d',
  'alias-state-destructive-pressed': '#8a4f4914',
  'alias-size-input-sm': '1rem',
  'alias-size-input-md': '2.5rem',
  'alias-size-input-lg': '3rem',
  'alias-size-action-sm': '2rem',
  'alias-size-action-md': '2.5rem',
  'alias-size-action-lg': '3rem',
  'alias-size-action-xl': '3.5rem',
  'alias-size-menu-part-sm': '2rem',
  'alias-size-menu-part-md': '2.5rem',
  'alias-size-menu-part-lg': '3rem',
  'alias-size-menu-part-xl': '3.5rem',
  'alias-size-field-sm': '2.5rem',
  'alias-size-field-md': '3.5rem',
  'alias-size-field-lg': '4.5rem',
  'alias-space-text-with-icon-sm': '0.25rem',
  'alias-space-text-with-icon-md': '0.5rem',
  'alias-space-text-with-icon-lg': '0.75rem',
  'alias-space-button-group-sm': '0.5rem',
  'alias-space-button-group-md': '0.75rem',
  'alias-space-button-group-lg': '1rem',
  'alias-space-form-items-horizontal': '1rem',
  'alias-space-form-items-vertical': '1.25rem',
  'alias-space-title-with-text-sm': '0.125rem',
  'alias-space-title-with-text-md': '0.25rem',
  'alias-space-title-with-text-lg': '0.5rem',
  'alias-space-title-with-text-xl': '1rem',
  'alias-space-label-with-text-base': '0.25rem',
  'alias-space-label-with-text-tight': '0.125rem',
  'alias-padding-base': '1.25rem',
  'alias-padding-sm': '1rem',
  'alias-padding-xs': '0.75rem',
  'alias-padding-xxs': '0.5rem',
  'alias-padding-xxxs': '0.25rem',
  'alias-padding-action-sm-base': '0.5rem',
  'alias-padding-action-sm-tight': '0.25rem',
  'alias-padding-action-md-base': '0.75rem',
  'alias-padding-action-md-tight': '0.5rem',
  'alias-padding-action-lg-base': '1rem',
  'alias-padding-action-lg-tight': '0.75rem',
  'alias-border-width-default': '1px',
  'alias-border-width-pressed': '1.5px',
  'alias-border-width-active': '2px',
  'alias-height-label': '20px',
  'alias-menu-part-padding-base': '0.75rem',
  'alias-menu-part-padding-tight': '0.25rem',
  'alias-menu-part-space': '0.5rem',
  'alias-filter-backdrop-brightness-base-hover': '0.97',
  'alias-filter-backdrop-brightness-base-pressed': '0.94',
  'component-input-group-space-tight': '0.25rem',
  'component-input-group-space-base': '0.5rem',
  'component-input-group-space-wide': '1rem',
  'component-button-sm-padding-base': '1.25rem',
  'component-button-sm-padding-tight': '1rem',
  'component-button-height-sm': '2.5rem',
  'component-button-height-md': '3rem',
  'component-button-height-lg': '3.5rem',
  'component-button-md-padding-base': '1.5rem',
  'component-button-md-padding-tight': '1.25rem',
  'component-button-lg-padding-base': '2rem',
  'component-button-lg-padding-tight': '28px',
  'component-button-border-radius-pill': '999px',
  'component-button-border-radius-round': '10px',
  'component-button-border-width-default': '1px',
  'component-button-border-width-active': '2px',
  'component-button-space': '0.5rem',
  'component-icon-size-xs': '0.5rem',
  'component-icon-size-sm': '0.75rem',
  'component-icon-size-base': '1rem',
  'component-icon-size-lg': '1.25rem',
  'component-icon-size-xl': '1.5rem',
  'component-text-input-padding-base': '0.75rem',
  'component-text-input-padding-tight': '0.25rem',
  'component-text-input-color-text-default': '#41465A',
  'component-text-input-color-text-hover': '#41465A',
  'component-text-input-color-text-active': '#2F3345',
  'component-text-input-color-text-complete': '#2F3345',
  'component-text-input-color-text-disabled': '#ACB0BF',
  'component-text-input-height': '2.5rem',
  'component-text-input-space': '0.5rem',
  'component-text-input-border-width-default': '1px',
  'component-text-input-border-width-pressed': '1.5px',
  'component-text-input-border-width-active': '2px',
  'component-text-input-border-radius': '10px',
  'component-text-input-margin-base': '0.5rem',
  'component-badge-padding-base': '0.75rem',
  'component-badge-padding-tight': '0.5rem',
  'component-badge-height-lg': '1.5rem',
  'component-badge-height-sm': '1.25rem',
  'component-badge-border-radius': '999px',
  'component-badge-space': '0.25rem',
  'component-avatar-size-sm': '1.5rem',
  'component-avatar-size-md': '2rem',
  'component-avatar-size-lg': '2.5rem',
  'component-avatar-size-xl': '3rem',
  'component-avatar-size-2xl': '3.5rem',
  'component-avatar-border-radius': '999px',
  'component-table-cell-size-header': '3rem',
  'component-table-cell-size-single-line': '3.5rem',
  'component-table-cell-size-multi-line': '4.5rem',
  'component-table-cell-padding-tighter': '0.75rem',
  'component-table-cell-padding-tight': '1rem',
  'component-table-cell-padding-base': '1.25rem',
  'component-table-cell-spacing-vertical': '0.125rem',
  'component-table-cell-spacing-horizontal': '0.25rem',
  'component-sidebar-padding': '0.75rem',
  'component-sidebar-space': '1rem',
  'component-dropdown-menu-padding': '0.5rem',
  'component-dropdown-menu-border-radius': '10px',
  'component-simple-button-padding-base': '0.5rem',
  'component-simple-button-padding-tight': '0.25rem',
  'component-simple-button-height': '2rem',
  'component-simple-button-space-base': '0.25rem',
  'component-alert-padding-tight': '0.5rem',
  'component-alert-padding-base': '0.75rem',
  'component-alert-border-radius-sm': '8px',
  'component-alert-border-radius-lg': '12px',
  'component-checkbox-size': '1rem',
  'component-checkbox-border-radius': '4px',
  'component-checkbox-border-width-default': '1.5px',
  'component-checkbox-border-width-active': '0px',
  'component-checkbox-space-base': '0.5rem',
  'component-checkbox-space-wide': '0.75rem',
  'component-checkbox-space-tight': '0.25rem',
  'component-radio-size': '1rem',
  'component-radio-border-radius': '999px',
  'component-radio-space-base': '0.5rem',
  'component-radio-space-wide': '0.75rem',
  'component-radio-space-tight': '0.25rem',
  'component-radio-border-width': '1.5px',
  'component-menu-item-height-sm': '2.5rem',
  'component-menu-item-height-md': '3rem',
  'component-menu-item-height-lg': '3.5rem',
  'component-menu-item-border-radius': '10px',
  'component-extra-height': '2rem',
  'component-extra-padding': '0.75rem',
  'component-extra-border-radius': '8px',
  'component-extra-border-width-weak': '0px',
  'component-extra-border-width-strong': '1px',
  'component-tab-height': '2.5rem',
  'component-tab-padding-base': '0.75rem',
  'component-tab-padding-tight': '0.5rem',
  'component-tab-border-radius': '10px',
  'component-tab-space': '0.5rem',
  'component-tab-border-width-default': '1px',
  'component-tab-border-width-hover': '2px',
  'component-tab-border-width-pressed': '2px',
  'component-tab-border-width-active': '2px',
  'component-step-height-single-line': '2.5rem',
  'component-step-height-multi-line': '3.5rem',
  'component-step-padding': '0.75rem',
  'component-step-space': '0.75rem',
  'component-step-border-radius': '10px',
  'component-tab-group-space-pill': '0.5rem',
  'component-tab-group-space-border': '1.5rem',
  'component-description-list-height': '3.5rem',
  'component-description-list-padding-vertical': '0.5rem',
  'component-description-list-spacing-horizontal': '1rem',
  'component-description-list-spacing-vertical-view': '0.25rem',
  'component-description-list-spacing-vertical-edit': '0.5rem',
  'component-card-padding-base': '1.25rem',
  'component-card-padding-tight': '1rem',
  'component-card-padding-none': '0px',
  'component-card-border-width': '1px',
  'component-card-border-radius-base': '16px',
  'component-step-indicator-border-width': '1.5px',
  'component-step-indicator-border-radius': '999px',
  'component-step-indicator-size': '1rem',
  'component-step-group-space': '0.5rem',
  'component-breadcrumbs-space': '0.25rem',
  'component-menu-header-height': '2rem',
  'component-topbar-height': '5rem',
  'component-topbar-padding': '2.5rem',
  'component-topbar-space': '1rem',
  'component-page-margin-base': '2.5rem',
  'component-pagination-bar-padding': '1.25rem',
  'component-pagination-bar-height': '4rem',
  'component-pagination-bar-space': '1rem',
  'component-radio-card-border-width-default': '1px',
  'component-radio-card-border-width-active': '1.5px',
  'component-radio-card-border-radius': '12px',
  'component-radio-card-padding-base': '1rem',
  'component-radio-card-space-base': '0.125rem',
  'component-snackbar-padding-base': '0.75rem',
  'component-snackbar-padding-tight': '0.5rem',
  'component-file-upload-padding': '1.25rem',
  'component-file-upload-space-tight': '0.25rem',
  'component-file-upload-space-base': '0.5rem',
  'component-file-upload-border-radius': '12px',
  'component-file-upload-border-width': '1px',
  'component-file-padding': '0.75rem',
  'component-file-space-base': '0.75rem',
  'component-file-space-tight': '0.25rem',
  'component-file-border-radius': '12px',
  'component-file-border-width': '1px',
  'component-toggle-border-radius': '999px',
  'component-toggle-space-base': '0.5rem',
  'component-toggle-height': '1.25rem',
  'component-toggle-width': '2.25rem',
  'component-toggle-padding': '0.125rem',
  'component-segmented-buttons-border-radius': '10px',
  'component-segmented-buttons-border-width': '1px',
  'component-dropdown-space': '0.5rem',
  'component-radio-card-group-space-base': '0.5rem',
};
