import { connect } from 'react-redux';
import {
  makeSelectUserHasAdvisoryService,
  makeSelectUserHasAdvisoryServices,
} from './selectors';
import { createStructuredSelector } from 'reselect';

export const withHasAdvisoryServices = connect(
  createStructuredSelector({
    hasAdvisoryServices: makeSelectUserHasAdvisoryServices(),
  }),
);

export const withHasAdvisoryService = (key) => (propName) =>
  connect(
    createStructuredSelector({
      [propName]: makeSelectUserHasAdvisoryService(key),
    }),
  );

export const withHasEnhancedCrisisSupoort = (
  propName = 'hasEnhancedCrisisSupport',
) => withHasAdvisoryService('crisisSupport')(propName);

export const withHasFinancialAdvisoryService = (
  propName = 'hasFinancialAdvisoryService',
) => withHasAdvisoryService('financial')(propName);

export const withHasHealthAdvisoryService = (
  propName = 'hasHealthAdvisoryService',
) => withHasAdvisoryService('health')(propName);

export const withHasLegalAdvisoryService = (
  propName = 'hasLegalAdvisoryService',
) => withHasAdvisoryService('legal')(propName);

export const withHasResearchAdvisoryService = (
  propName = 'hasResearchAdvisoryService',
) => withHasAdvisoryService('research')(propName);

export const withHasCareerAdvisoryService = (
  propName = 'hasCareerAdvisoryService',
) => withHasAdvisoryService('career')(propName);

export const withHasLoanAdvisoryService = (
  propName = 'hasLoanAdvisoryService',
) => withHasAdvisoryService('loan')(propName);
