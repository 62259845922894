/**
 * Validation & normalization functions to be used with Redux Form
 * https://redux-form.com/8.3.0/examples/fieldlevelvalidation/
 */

import { defineMessages } from 'react-intl';
import {
  validateCanadianPostalCode,
  validateUSZipCode,
  validateNorthAmericanPhoneNumber,
} from 'utils/validation';

const messages = defineMessages({
  required: {
    defaultMessage: 'Required',
  },
  invalidPostalCode: {
    defaultMessage: 'Invalid postal code',
  },
  invalidZIPCode: {
    defaultMessage: 'Invalid ZIP code',
  },
  invalidPhoneNumberWithExample: {
    defaultMessage: 'Invalid phone number (example: 123-456-7890)',
  },
  mustIncludeNotWhitespace: {
    defaultMessage: 'Include at least one character that is not a space',
  },
});

/**
 * Wrapper around required validation for Redux Form validate prop
 *
 * @param {String} value Postal code candidate
 * @param {any?} allValues Ignored
 * @param {any?} props Props from component. Requires component to have `intl` prop.
 * @returns {String | undefined} Error message if invalid or `undefined` if valid
 */
export function validateRequired(value, allValues, props) {
  if (!value) {
    return props.intl.formatMessage(messages.required);
  }
}

/**
 * Redux Form validation function to be used in `validate` prop of Field component
 * Empty string will be considered valid. Use `validateRequired` if empty string is invalid.
 *
 * @param {String} value Candidate value
 * @param {any?} allValues Ignored
 * @param {any?} props Props from component. Requires component to have `intl` prop.
 * @returns {String | undefined} Error message if invalid or `undefined` if valid
 */
export function validateNotOnlyWhitespace(value, allValues, props) {
  if (value && /^\s+$/.test(value)) {
    return props.intl.formatMessage(messages.mustIncludeNotWhitespace);
  }
}

/**
 * Wrapper around postal code validation for Redux Form validate prop
 *
 * @param {String} value Postal code candidate
 * @param {any?} allValues Ignored
 * @param {any?} props Props from component. Requires component to have `intl` prop.
 * @returns {String | undefined} Error message if invalid or `undefined` if valid
 */
export function validatePostalCode(value, allValues, props) {
  if (value && !validateCanadianPostalCode(value)) {
    return props.intl.formatMessage(messages.invalidPostalCode);
  }
}

/**
 * Wrapper around ZIP code validation for Redux Form validate prop
 *
 * @param {String} value ZIP code candidate
 * @param {any?} allValues Ignored
 * @param {any?} props Props from component. Requires component to have `intl` prop.
 * @returns {String | undefined} Error message if invalid or `undefined` if valid
 */
export function validateZipCode(value, allValues, props) {
  if (value && !validateUSZipCode(value)) {
    return props.intl.formatMessage(messages.invalidZIPCode);
  }
}

/**
 * Wrapper around phone number validation for Redux Form validate prop
 *
 * @param {String} value Value to test
 * @param {any?} allValues Ignored
 * @param {any?} props Props from component. Requires component to have `intl` prop.
 * @returns {String | undefined} Error message if invalid or `undefined` if valid
 */
export function validatePhoneNumber(value, allValues, props) {
  if (value && !validateNorthAmericanPhoneNumber(value)) {
    return props.intl.formatMessage(messages.invalidPhoneNumberWithExample);
  }
}

/**
 * Normalizes phone number for display when using Redux Form
 *
 * @param {String} value
 * @returns {String}
 */
export function normalizePhoneNumber(value) {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(
    6,
    10,
  )}`;
}
