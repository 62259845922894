/*
 *
 * UpdatedTermsConditions constants
 *
 */

export const UPDATE_TERMS_CONDITIONS =
  'app/UpdatedTermsAndConditions/UPDATE_TERMS_CONDITIONS';
export const UPDATE_TERMS_CONDITIONS_SUCCESS =
  'app/UpdatedTermsAndConditions/UPDATE_TERMS_CONDITIONS_SUCCESS';
export const UPDATE_TERMS_CONDITIONS_ERROR =
  'app/UpdatedTermsAndConditions/UPDATE_TERMS_CONDITIONS_ERROR';
