import { defineMessages } from 'react-intl';

export const scope = 'app.components.Header';

export default defineMessages({
  dashboardLink: {
    defaultMessage: 'Dashboard',
  },
  myPractitionerLink: {
    defaultMessage: 'My Care Team',
  },
  appointmentsLink: {
    defaultMessage: 'Appointments',
  },
  preferencesLink: {
    defaultMessage: 'Preferences',
  },
  supportLink: {
    defaultMessage: 'Support',
  },
  advisoryServices: {
    defaultMessage: 'Work, Health and Life Services',
  },
  coachingServices: {
    defaultMessage: 'Coaching Services',
  },
  logOut: {
    defaultMessage: 'Log Out',
  },
  timeNow: {
    defaultMessage: 'Time now',
  },
  modify: {
    defaultMessage: 'Modify',
  },
  selectYourTimezone: {
    defaultMessage: 'Select your timezone',
  },
  selectTimezone: {
    defaultMessage: 'Select timezone',
  },
  updateTimezone: {
    defaultMessage: 'Update timezone',
  },
});
