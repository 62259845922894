import React from 'react';
import styled from 'styled-components';
import Content from 'components/Content';
import H3 from 'components/H3';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import CheckSvg from '../../../images/YourMatches/check.svg';

const StyledBulletItem = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
`;

const StyledBulletItemIcon = styled.div`
  width: 40px;
  height: 40px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledBulletItemText = styled.div`
  margin: 0;
  flex: 1;
`;
const StyledH3 = styled(H3)`
  margin-bottom: 20px;
`;
const BulletItems = ({ data, messageTitle, ...props }) => (
  <>
    {data.length !== 0 && (
      <div {...props}>
        <StyledH3>
          <FormattedMessage {...messages[messageTitle]} />
        </StyledH3>
        <div style={{ margin: '10px 0 0 0' }}>
          {data.map((e, index) => (
            <StyledBulletItem key={`key-${index + 1}`}>
              <StyledBulletItemIcon>
                <img src={CheckSvg} alt="" />
              </StyledBulletItemIcon>
              <StyledBulletItemText>
                <Content bold>{e}</Content>
              </StyledBulletItemText>
            </StyledBulletItem>
          ))}
        </div>
      </div>
    )}
  </>
);

export default BulletItems;
