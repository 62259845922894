/*
 *
 * UserInformation actions
 *
 */

import { setUserId, setUserProperties } from 'utils/amplitude';

import {
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_ERROR,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  UPDATE_EMAIL,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_ERROR,
  CONFIRM_EMAIL_SUCCESS,
  CONFIRM_EMAIL,
  CONFIRM_EMAIL_ERROR,
  TOGGLE_FREE_SESSION,
} from './constants';

export function fetchUser(callback) {
  return {
    type: FETCH_USER,
    callback,
  };
}

export function fetchUserSuccess(user) {
  setUserId(user.id);
  setUserProperties(user.user_properties);

  return {
    type: FETCH_USER_SUCCESS,
    user,
  };
}

export function fetchUserError(error) {
  return {
    type: FETCH_USER_ERROR,
    error,
  };
}

export function updateUser(data, callback) {
  return {
    type: UPDATE_USER,
    data,
    callback,
  };
}

export function updateUserSuccess(user) {
  return {
    type: UPDATE_USER_SUCCESS,
    user,
  };
}

export function updateUserError(error) {
  return {
    type: UPDATE_USER_ERROR,
    error,
  };
}

export function updateEmail(data, callback) {
  return {
    type: UPDATE_EMAIL,
    data,
    callback,
  };
}

export function updateEmailSuccess(email) {
  return {
    type: UPDATE_EMAIL_SUCCESS,
    email,
  };
}

export function updateEmailError(error) {
  return {
    type: UPDATE_EMAIL_ERROR,
    error,
  };
}

export function confirmEmail(data, callback = () => {}) {
  return {
    type: CONFIRM_EMAIL,
    data,
    callback,
  };
}

export function confirmEmailSuccess(tokenInfo) {
  return {
    type: CONFIRM_EMAIL_SUCCESS,
    tokenInfo,
  };
}

export function confirmEmailError(error) {
  return {
    type: CONFIRM_EMAIL_ERROR,
    error,
  };
}

export function toggleFreeSession(bool) {
  return {
    type: TOGGLE_FREE_SESSION,
    bool,
  };
}
