import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Chip from 'components/Chip';
import { mediumBlue, phoneSizeEnd, green } from 'global-styles';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import messages from '../messages';

import LaptopSvg from '../../../images/YourMatches/laptop.svg';
import PhoneSvg from '../../../images/YourMatches/phone.svg';
import SofaSvg from '../../../images/YourMatches/sofa.svg';

const WrapRow = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @media (max-width: ${phoneSizeEnd}) {
    justify-content: flex-start;
  }
`;

const Modality = ({ modality }) => {
  if (modality.toLowerCase() === 'in_person') {
    return (
      <Chip
        key="in-person"
        color={green}
        uppercase
        icon={SofaSvg}
        style={{ marginTop: 30 }}
        disabled
      >
        <FormattedMessage defaultMessage={'In Person'} />
      </Chip>
    );
  }
  if (modality.toLowerCase() === 'voice') {
    return (
      <Chip
        key="voice"
        color="#40dccb"
        uppercase
        icon={PhoneSvg}
        style={{ marginTop: 30 }}
        disabled
      >
        <FormattedMessage {...messages.voice} />
      </Chip>
    );
  }
  if (modality.toLowerCase() === 'video') {
    return (
      <Chip
        key="video"
        color={mediumBlue}
        uppercase
        icon={LaptopSvg}
        style={{ marginTop: 30 }}
        disabled
      >
        <FormattedMessage {...messages.video} />
      </Chip>
    );
  }
  return <></>;
};

const Modalities = ({ data }) => (
  <WrapRow style={{ marginBottom: '20px' }}>
    {data &&
      data.map((modality) => <Modality key={modality} modality={modality} />)}
  </WrapRow>
);
Modalities.propTypes = {
  data: PropTypes.array.isRequired,
};
export default withLDConsumer()(Modalities);
