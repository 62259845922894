/*
 *
 * WMHSignup reducer
 *
 */

import { fromJS } from 'immutable';
import {
  REGISTER_USER,
  REGISTER_USER_ERROR,
  REGISTER_USER_SUCCESS,
} from './constants';

export const initialState = fromJS({
  error: false,
  loading: false,
});

function wmhSignupReducer(state = initialState, action) {
  switch (action.type) {
    case REGISTER_USER:
      return state.set('loading', true);
    case REGISTER_USER_ERROR:
      return state.set('error', action.error).set('loading', false);
    case REGISTER_USER_SUCCESS:
      return state.set('loading', false);
    default:
      return state;
  }
}

export default wmhSignupReducer;
