import amplitude from 'amplitude-js/amplitude';

import { AMPLTIUDE_API_KEY } from './environment';

export const initAmplitude = () =>
  amplitude.getInstance().init(AMPLTIUDE_API_KEY);

export const setUserId = (userId) => amplitude.getInstance().setUserId(userId);

export const setUserProperties = (userProperties) =>
  amplitude.getInstance().setUserProperties(userProperties);

export const logEvent = (eventType, eventProperties) =>
  amplitude.getInstance().logEvent(eventType, eventProperties);

export const setUserProperty = (property, value) => {
  const identify = new amplitude.Identify().set(property, value);
  amplitude.getInstance().identify(identify);
};

export const SENT_PARTNER_INVITATION = 'sent partner invitation email';
export const LANDED_PARTNER_INVITATION = 'landed on partner invitation page';

export const COMPLETED_SIGNIN = 'complete signin';
export const COMPLETED_SIGNUP = 'complete signup';
