import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import AlertIcon from 'images/FailedPayments/alert-circle.png';

const ButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: 20px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 46px;
  padding: 16px;
  text-align: center;

  h2 {
    color: #193269;
    font-family: 'Barlow';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
  }

  p {
    color: #000;
    text-align: center;

    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
  }
`;

const ActionButton = styled.button`
  display: flex;
  height: 48px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  background: ${(props) => (props.primary ? '#2954c3' : '#fff')};
  color: ${(props) => (props.primary ? '#fff' : '#2954c3')};
  padding: 8px 48px;
  white-space: nowrap;
  font-size: 16px;
  font-family: 'Barlow';
  font-weight: 600;
  line-height: 20px;
  box-shadow: ${(props) =>
    props.primary ? '0px 1px 9px 0px #dde5ef' : 'noShadow'};
  width: 274px;
  a {
    color: inherit;
  }
  a:hover {
    text-decoration: none;
  }
`;

const PartialSuccess = ({ closeModal, history }) => {
  const handleNavigate = () => {
    history.push('/');
  };

  return (
    <Wrapper>
      <div>
        <img alt="alert-icon" src={AlertIcon} />
        <h2>
          <FormattedMessage defaultMessage="Partial Payment Processed" />
        </h2>
      </div>
      <p>
        <FormattedMessage
          defaultMessage="The transaction is incomplete due to remaining balance from a previous appointment. For details, please review your email or visit the update payment details page. {br}{br}Please finalize your payment by updating your payment method."
          values={{ br: <br /> }}
        />
      </p>
      <ButtonDiv>
        <ActionButton primary onClick={closeModal}>
          <FormattedMessage defaultMessage="Update Payment" />
        </ActionButton>
        <ActionButton onClick={handleNavigate}>
          <FormattedMessage defaultMessage="Back To Dashboard" />
        </ActionButton>
      </ButtonDiv>
    </Wrapper>
  );
};

export default withRouter(PartialSuccess);
