/*
 *
 * Misc reducer
 *
 */
import _ from 'lodash';
import { fromJS } from 'immutable';
import {
  FETCH_STATES,
  FETCH_STATES_SUCCESS,
  FETCH_STATES_ERROR,
} from 'containers/Misc/constants';

export const initialState = fromJS({});

function miscReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_STATES:
      return state.set('loading', true);
    case FETCH_STATES_SUCCESS:
      return state
        .set('loading', false)
        .set('canada', _.filter(action.states, ['country_id', 1]))
        .set('america', _.filter(action.states, ['country_id', 2]));
    case FETCH_STATES_ERROR:
      return state.set('loading', false).set('error', action.error);
    default:
      return state;
  }
}

export default miscReducer;
