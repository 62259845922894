/*
 * UpdatePopover Messages
 *
 * This contains all the text for the UpdatePopover container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.UpdatePopover';

export default defineMessages({
  refresh: {
    defaultMessage: 'Refresh!',
  },
  updateAvailable: {
    defaultMessage: 'A new update is available!',
  },
});
