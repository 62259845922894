import React from 'react';
import queryString from 'query-string';

import { setToken, setBackTo } from 'utils/auth';

class ExternalTokenRedirect extends React.PureComponent {
  componentDidMount() {
    const token = window.location.pathname.match(/redirect\/(.*)$/)[1];

    if (token) {
      const qs = queryString.parse(window.location.search);

      if (qs.back_to) {
        setBackTo(qs.back_to);
      }

      setToken(token);

      window.location.href = qs.partner_registration
        ? '/partner_registration'
        : '/';
    }
  }

  render() {
    return <div />;
  }
}

export default ExternalTokenRedirect;
