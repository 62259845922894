import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { mediumBlue } from '../../global-styles';

const ChipContainer = styled.button`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.1px;
  color: ${(props) => props.color};
  padding: 5px 10px;
  border-radius: 15px;
  box-shadow: 0 0 10px 0 rgba(99, 140, 177, 0.2);
  background-color: #ffffff;
  margin-right: 25px;
  margin-bottom: 20px;
  text-transform: ${(props) => (props.uppercase ? 'uppercase' : 'none')};
  &:last-child {
    margin-right: 0px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  span {
    margin-right: 10px;
  }
`;

const renderChildren = (icon, children) => {
  if (icon) {
    return (
      <IconContainer>
        <SVG src={icon} alt="icon" />
        <div>{children}</div>
      </IconContainer>
    );
  }
  return <div>{children}</div>;
};

class Chip extends React.PureComponent {
  render() {
    const { color, children, uppercase, icon, style, onClick, disabled } =
      this.props;
    return (
      <ChipContainer
        color={color}
        uppercase={uppercase}
        style={style}
        onClick={onClick}
        disabled={disabled}
      >
        {renderChildren(icon, children)}
      </ChipContainer>
    );
  }
}

Chip.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node.isRequired,
  uppercase: PropTypes.bool,
  icon: PropTypes.node,
  style: PropTypes.object,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

Chip.defaultProps = {
  color: mediumBlue,
  uppercase: false,
  icon: null,
  style: {},
  onClick: () => {},
};

export default Chip;
