import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';

import Content from 'components/Content';
import messages from '../messages';
import { mediumBlue, phoneSize } from '../../../global-styles';
import UpSvg from './up.svg';
import DownSvg from './down.svg';
import Divider from '../Divider';
import Header from './Header';
import NoProvider from './NoProvider';
import Profile from './Profile';
import Unmatch from '../Unmatch';
import Resources from '../Resources';
import SendFiles from '../SendFiles';

const StyledContainer = styled.div`
  position: relative;
  margin: 60px 0;
  border-radius: 20px;
  box-shadow: 0 0 10px 0 rgba(99, 140, 177, 0.1);
  border: solid 1px rgba(46, 95, 202, 0.1);
  background-color: #ffffff;

  .pc-detail-wrapper {
    padding: 0 60px;
    @media (max-width: ${phoneSize}) {
      padding: 0 20px;
    }
  }

  .pc-detail-button-wrapper {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
    .pc-detail-button {
      display: flex;
      margin: 0 20px 0 0;
      .pc-detail-text {
        margin-right: 13px;
      }
    }
  }

  button {
    :focus {
      outline: 5px solid ${mediumBlue};
    }
  }

  @media (max-width: ${phoneSize}) {
    border: solid 1px rgba(46, 95, 202, 0.2);
    box-shadow: none;
  }
`;

function ProviderCard({
  title,
  provider,
  matchUrl,
  bookingUrl,
  isGuest,
  isPrivate,
  locale,
  isCouples,
  openInvitationCodeDialog,
  failedPaymentsProviderTypes,
  setModalOpen,
}) {
  const [openDetails, setOpenDetails] = useState(false);

  function renderDetailButton() {
    return (
      <div className="pc-detail-button-wrapper">
        <button
          className="pc-detail-button"
          onClick={() => setOpenDetails(!openDetails)}
          type="button"
        >
          <div className="pc-detail-text">
            <Content bold color="#2e5fca">
              <FormattedMessage
                {...(openDetails ? messages.hideDetails : messages.viewDetails)}
              />
            </Content>
          </div>
          <div className="pc-detail-arrow">
            <SVG src={openDetails ? UpSvg : DownSvg} />
          </div>
        </button>
      </div>
    );
  }

  function renderDetails() {
    if (isGuest) {
      return (
        <div className="pc-detail-wrapper">
          <Profile provider={provider} />
        </div>
      );
    }

    return (
      <div className="pc-detail-wrapper">
        <Resources provider={provider} />
        <Divider />
        <SendFiles provider={provider} />
        <Divider />
        <Profile provider={provider} />
        {!isPrivate && (
          <>
            <Divider />
            <Unmatch provider={provider} />
          </>
        )}
      </div>
    );
  }

  if (!provider || !provider.id) {
    return (
      <StyledContainer>
        <NoProvider
          title={title}
          matchUrl={matchUrl}
          isCouples={isCouples}
          openInvitationCodeDialog={openInvitationCodeDialog}
        />
      </StyledContainer>
    );
  }

  return (
    <StyledContainer>
      <Header
        title={title}
        provider={provider}
        bookingUrl={bookingUrl}
        isGuest={isGuest}
        locale={locale}
        isCouples={isCouples}
        failedPaymentsProviderTypes={failedPaymentsProviderTypes}
        setModalOpen={setModalOpen}
      />
      {openDetails && renderDetails()}
      {renderDetailButton()}
    </StyledContainer>
  );
}

ProviderCard.propTypes = {
  provider: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  matchUrl: PropTypes.string.isRequired,
  bookingUrl: PropTypes.string.isRequired,
  isGuest: PropTypes.bool,
  isPrivate: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  failedPaymentsProviderTypes: PropTypes.array,
};

ProviderCard.defaultProps = {
  provider: {},
  isGuest: false,
};

export default ProviderCard;
