import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import { Map } from 'immutable';
import { FormattedMessage } from 'react-intl';

import EnglishSvg from './svgs/en.svg';
import FrenchSvg from './svgs/fr.svg';
import LogOutImg from '../../images/Header/log-out.svg';
import { mediumBlue, phoneSize } from '../../global-styles';
import messages from './messages';
import { disableGSFields } from 'utils/gsHelper';

const Wrapper = styled.div`
  display: ${(props) => (props.open ? 'unset' : 'none')};

  position: absolute;
  right: 0px;
  top: 69px;
  width: 190px;
  padding: 0 20px;
  border: solid 1px rgba(46, 95, 202, 0.2);
  background-color: #ffffff;
  z-index: 200;
  @media (max-width: ${phoneSize}) {
    display: none;
  }
`;

const Divider = styled.div`
  height: 1px;
  border-bottom: solid 1px rgba(46, 95, 202, 0.2);
`;

const ItemWrapper = styled.button`
  height: 58px;
  cursor: pointer;

  display: flex;
  align-items: center;

  .tno-icon {
    width: 32px;
    margin-top: -4px;
  }

  .tno-label {
    display: inline-block;

    p {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.2px;
      color: ${mediumBlue};
    }
  }
`;

const languages = {
  en: { flag: EnglishSvg, label: 'English' },
  fr: { flag: FrenchSvg, label: 'Français' },
};

class NavDropdown extends React.PureComponent {
  handleLocaleChange = () => {
    const { localeObject } = this.props;
    const changeLocale = localeObject.get('changeLocale');

    if (localeObject.get('currentLocale') === 'en') {
      changeLocale('fr');
    } else {
      changeLocale('en');
    }
  };

  renderLanguage() {
    const { localeObject } = this.props;
    let code = 'en';

    if (localeObject.get('currentLocale') === 'en') {
      code = 'fr';
    } else {
      code = 'en';
    }

    return (
      <React.Fragment>
        <div className="tno-label">
          <p>{languages[code].label}</p>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { open, profileType } = this.props;

    return (
      <Wrapper open={open}>
        <ItemWrapper
          onClick={this.handleLocaleChange}
          hidden={disableGSFields(profileType)}
        >
          {this.renderLanguage()}
        </ItemWrapper>
        <Divider />
        <ItemWrapper onClick={this.props.handleSignOut}>
          <div className="tno-icon">
            <SVG src={LogOutImg} />
          </div>
          <div className="tno-label">
            <p>
              <FormattedMessage {...messages.logOut} />
            </p>
          </div>
        </ItemWrapper>
      </Wrapper>
    );
  }
}

NavDropdown.propTypes = {
  open: PropTypes.bool.isRequired,
  handleSignOut: PropTypes.func.isRequired,
  localeObject: PropTypes.instanceOf(Map).isRequired,
};

export default NavDropdown;
