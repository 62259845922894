/*
 *
 * UpdatedTermsConditions actions
 *
 */

import {
  UPDATE_TERMS_CONDITIONS,
  UPDATE_TERMS_CONDITIONS_ERROR,
  UPDATE_TERMS_CONDITIONS_SUCCESS,
} from './constants';

export function updateTermsConditions(callback) {
  return {
    type: UPDATE_TERMS_CONDITIONS,
    callback,
  };
}

export function updateTermsConditionsSuccess() {
  return {
    type: UPDATE_TERMS_CONDITIONS_SUCCESS,
  };
}

export function updateTermsConditionsError(error) {
  return {
    type: UPDATE_TERMS_CONDITIONS_ERROR,
    error,
  };
}
