import { put, call, takeLatest } from 'redux-saga/effects';
import { API_URL } from 'utils/environment';
import { post, get } from 'utils/request';
import {
  verifyPhoneNumberSuccess,
  verifyPhoneNumberError,
  createPhoneNumberSuccess,
  createPhoneNumberError,
} from './actions';
import {
  VERIFY_PHONE_NUMBER,
  CREATE_PHONE_NUMBER,
  VERIFY_PHONE_NUMBER_SUCCESS,
  VERIFY_PHONE_NUMBER_ERROR,
  CREATE_PHONE_NUMBER_SUCCESS,
  CREATE_PHONE_NUMBER_ERROR,
} from './constants';

function* createPhoneNumber({ values, callback, errorCallback }) {
  const params = {
    country_code: values.country,
    number: values.number,
    call_to: values.call_to,
    phone_type: 'primary',
  };
  const requestURL = `${API_URL}/phone_numbers`;
  try {
    const phoneNumber = yield call(post, requestURL, params);
    yield put(createPhoneNumberSuccess(phoneNumber));
    if (callback) callback(phoneNumber);
  } catch (error) {
    yield put(createPhoneNumberError(error));
    if (errorCallback) errorCallback(error);
  }
}

function* verifyPhoneNumber(values) {
  const params = {
    code: values.code,
    phone_type: 'primary',
  };
  const requestURL = `${API_URL}/phone_numbers/verify?code=${values.code}&phone_type=primary`;
  try {
    const phoneNumberVerify = yield call(get, requestURL, params);
    yield put(verifyPhoneNumberSuccess(phoneNumberVerify));
    yield call(values.callback);
  } catch (error) {
    yield put(verifyPhoneNumberError(error));
    yield call(values.errorCallback);
  }
}

export default function* phoneVerificationSaga() {
  yield takeLatest(VERIFY_PHONE_NUMBER, verifyPhoneNumber);
  yield takeLatest(VERIFY_PHONE_NUMBER_SUCCESS, verifyPhoneNumberSuccess);
  yield takeLatest(VERIFY_PHONE_NUMBER_ERROR, verifyPhoneNumberError);
  yield takeLatest(CREATE_PHONE_NUMBER, createPhoneNumber);
  yield takeLatest(CREATE_PHONE_NUMBER_SUCCESS, createPhoneNumberSuccess);
  yield takeLatest(CREATE_PHONE_NUMBER_ERROR, createPhoneNumberError);
}
