import { CommonLabelStyles } from 'components/CommonStyles';
import SqTextInput from 'components/SqTextInput/TextInput';
import React, { useState } from 'react';
import styled from 'styled-components';
import PropType from 'prop-types';

const Col = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1000;
`;
const StyledLabel = styled.span`
  font-family: 'TT Commons Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  letter-spacing: 0.02em;
  color: #696e82;
  margin-bottom: 8px;
`;
const StyledHelpText = styled.span`
  ${CommonLabelStyles} color: #696e82;
  margin-top: 8px;
`;
const StyledSelectionsCard = styled.div`
  top: calc(100% + 8px);
  width: ${({ width }) => width};
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  border: 1px solid #dcdee8;
  box-shadow: 0px 6px 8px rgba(128, 133, 151, 0.05),
    0px 20px 8px rgba(128, 133, 151, 0.02);
  border-radius: 10px;
  padding: 8px;
`;
const Option = styled.div`
  width: ${({ width }) => width};
  height: 40px;
  padding: 12px 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover,
  &:focus {
    background: rgba(31, 35, 49, 0.03);
    border-radius: 12px;
    outline: none;
  }
`;
const SqDropdownButton = (props) => {
  const {
    label,
    helpText,
    placeholder,
    style,
    onChange,
    initValue,
    selections,
    btnStyle,
    menuStyle,
    hideIcon,
    ...rest
  } = props;
  const [selected, setSelection] = useState(initValue);
  const [opened, setOpen] = useState(false);
  const [icon, setIcon] = useState('ChevronDown');

  React.useEffect(() => {
    setSelection(initValue);
  }, [initValue]);

  React.useEffect(() => {
    if (opened) {
      setIcon('ChevronUp');
    } else {
      setIcon('ChevronDown');
    }
  }, [opened]);

  return (
    <Col style={style} onClick={() => setOpen((pre) => !pre)}>
      {label && <StyledLabel>{label}</StyledLabel>}
      <SqTextInput
        type="button"
        defaultValue={(selected && selected.label) || placeholder}
        rightIconName={hideIcon ? null : icon}
        style={btnStyle}
        {...rest}
      />
      {selections && opened && (
        <StyledSelectionsCard style={menuStyle}>
          {selections.map((option) => (
            <Option
              tabIndex={0}
              key={option.key}
              onClick={() => {
                setSelection(option);
                onChange(option);
              }}
              onKeyUp={(event) => {
                if (event.key === 'Enter') {
                  setSelection(option);
                  onChange(option);
                  setOpen((pre) => !pre);
                }
              }}
              {...props}
            >
              {option.label}
            </Option>
          ))}
        </StyledSelectionsCard>
      )}
      {helpText && <StyledHelpText>{helpText}</StyledHelpText>}
    </Col>
  );
};
SqDropdownButton.propTypes = {
  selections: PropType.array.isRequired,
  onChange: PropType.func,
  label: PropType.string,
  helpText: PropType.string,
  placeholder: PropType.string,
  style: PropType.object,
  initValue: PropType.object,
  btnStyle: PropType.object,
  menuStyle: PropType.object,
  hideIcon: PropType.bool,
};
export default SqDropdownButton;
