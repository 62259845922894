import { defineMessages } from 'react-intl';

export const scope = 'app.utils';

export default defineMessages({
  ht: {
    defaultMessage: 'Hawaii‑Aleutian Time',
  },
  akt: {
    defaultMessage: 'Alaskan Time',
  },
  pt: {
    defaultMessage: 'Pacific Time (Los Angeles/Vancouver)',
  },
  mt: {
    defaultMessage: 'Mountain Time (Denver/Edmonton)',
  },
  ct: {
    defaultMessage: 'Central Time (Chicago/Winnipeg)',
  },
  et: {
    defaultMessage: 'Eastern Time (New York/Toronto)',
  },
  at: {
    defaultMessage: 'Atlantic Time (Halifax)',
  },
  st: {
    defaultMessage: 'Saskatchewan (Central Standard Time)',
  },
  nt: {
    defaultMessage: 'Newfoundland Time',
  },
  mst: {
    defaultMessage: 'Yukon (Mountain Standard Time)',
  },
  est: {
    defaultMessage: 'Pickle Lake/Atikokan (Eastern Standard Time)',
  },
});
