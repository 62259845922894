const { USER_HELPER_API_URL } = require('./environment');

export const getCreateSessionUrl = () => `${USER_HELPER_API_URL}session`;

export const getSessionDetailUrl = (sessionId) =>
  `${getCreateSessionUrl()}/${sessionId}`;

export const getTerminateSessionUrl = (sessionId) =>
  `${getSessionDetailUrl(sessionId)}/terminate`;

export const getUpdateSessionUrl = (sessionId) =>
  `${getSessionDetailUrl(sessionId)}/activity`;

export const createSession = async (fingerprint, accessToken) => {
  const response = await fetch(getCreateSessionUrl(), {
    method: 'POST',
    headers: {
      authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify({ fingerprint: `${fingerprint}` }),
  });

  if (response.status !== 201) throw new Error('Failed to create session');

  return response.json();
};

export const terminateSession = (sessionId, fingerprint, accessToken) =>
  fetch(getTerminateSessionUrl(sessionId), {
    method: 'POST',
    headers: {
      authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify({ fingerprint: `${fingerprint}` }),
  });

export const checkSession = (sessionId, fingerprint, accessToken) =>
  fetch(getSessionDetailUrl(sessionId), {
    method: 'POST',
    headers: {
      authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify({ fingerprint: `${fingerprint}` }),
  });

export const updateSession = (sessionId, fingerprint, accessToken) =>
  fetch(getUpdateSessionUrl(sessionId), {
    method: 'POST',
    headers: {
      authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify({ fingerprint: `${fingerprint}` }),
  });
