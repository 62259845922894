import styled from 'styled-components';

import NormalImg from 'components/Img';

const Avatar = styled(NormalImg)`
  width: 23px;
  height: 23px;
  margin-right: 10px;
  display: inline-block;
  border-radius: 50%;
  background-color: #ecf2f4;
  object-fit: cover;
`;

export default Avatar;
