import { mediumBlue } from 'global-styles';
import styled from 'styled-components';

const OutlineButton = styled.button`
  background: #ffffff;
  box-shadow: 0px 1px 9px #dde5ef;
  border-radius: 999px;

  padding: 14px 48px;

  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${mediumBlue};
  &:not(:disabled) {
    &:focus {
      color: #343a54;
      border: 2px solid #343a54;
    }
    &:hover {
      color: #555a6e;
    }
  }
  &:disabled {
    background: #ebecf2;
    color: #acb0bf;
  }
`;

const OutlineLinkButton = styled(OutlineButton)`
  margin: 16px 0;
  text-decoration: none !important;
  text-transform: capitalize;
  text-align: center;
`;
OutlineLinkButton.defaultProps = {
  as: 'link',
};

export default OutlineButton;
export { OutlineLinkButton };
