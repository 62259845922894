import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';
import { phoneSize, eAZoom400 } from '../../../global-styles';

import messages from '../messages';
import CompleteSvg from './complete.svg';

const steps = [
  { label: <FormattedMessage {...messages.introduction} /> },
  { label: <FormattedMessage {...messages.additionalInformation} /> },
  { label: <FormattedMessage {...messages.confirmation} /> },
];

const StyledFinancialServicesSlider = styled.div`
  width: 100%;
  max-width: 300px;
  display: flex;
  justify-content: center;
`;

const StyledLabelContainer = styled(StyledFinancialServicesSlider)`
  justify-content: space-between;
`;

const StyledStep = styled.div`
  width: 30px;
  height: 30px;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
`;

const CompletedStep = (props) => (
  <StyledStep {...props}>
    <SVG src={CompleteSvg} />
  </StyledStep>
);

const CurrentStep = (props) => (
  <StyledStep {...props}>
    <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <linearGradient id="gradient">
        <stop offset="0%" stopColor="#fc587f" />
        <stop offset="100%" stopColor="#e938cc" />
      </linearGradient>
      <circle
        cx="15"
        cy="15"
        r="13.5"
        stroke="url(#gradient)"
        strokeWidth="2.5"
        fill="transparent"
      />
    </svg>
  </StyledStep>
);

const NextStep = (props) => (
  <StyledStep {...props}>
    <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="15"
        cy="15"
        r="13.5"
        stroke="#ecf2f4"
        strokeWidth="2.5"
        fill="transparent"
      />
    </svg>
  </StyledStep>
);

const StyledLine = styled.div`
  width: 85px;
  > div {
    height: 50%;
  }
  div:first-child {
    border-bottom: solid 1px #dfe7f7;
  }
`;
const Line = () => (
  <StyledLine>
    <div />
    <div />
  </StyledLine>
);

const StyledLabel = styled.div`
  width: 75px;
  height: 20px;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${(props) => (props.isCurrent ? '#0f2045' : 'rgba(15, 32, 69, 0.75)')};

  @media screen and (max-width: ${eAZoom400}) and (min-device-width:${phoneSize}) {
    font-size: 10px;
`;

const StyledDivider = styled.div`
  margin-top: 60px;
  margin-bottom: 60px;
  width: 200px;
  border-bottom: solid 1px #dfe7f7;
`;

/* eslint-disable react/prefer-stateless-function */
class Stepper extends React.PureComponent {
  renderStepIcon(step) {
    const { currentStep } = this.props;
    let stepIcon = null;

    if (step < currentStep) {
      stepIcon =
        currentStep === 1 ? (
          <CompletedStep clickable onClick={() => this.props.onChange(step)} />
        ) : (
          <CompletedStep />
        );
    } else if (step === currentStep) {
      stepIcon = <CurrentStep />;
    } else {
      stepIcon = <NextStep />;
    }

    return stepIcon;
  }

  render() {
    const { currentStep } = this.props;

    return (
      <React.Fragment>
        <StyledFinancialServicesSlider>
          {steps.map((step, index) => (
            <React.Fragment key={`key-${index + 1}`}>
              {this.renderStepIcon(index)}
              {index !== steps.length - 1 && <Line />}
            </React.Fragment>
          ))}
        </StyledFinancialServicesSlider>
        <StyledLabelContainer>
          {steps.map((step, index) => (
            <StyledLabel
              isCurrent={currentStep === index}
              key={`key-${index + 1}`}
            >
              {step.label}
            </StyledLabel>
          ))}
        </StyledLabelContainer>
        <StyledDivider />
      </React.Fragment>
    );
  }
}

Stepper.propTypes = {
  currentStep: PropTypes.number.isRequired,
  onChange: PropTypes.func,
};

Stepper.defaultProps = {
  initialValue: null,
  onChange: () => {},
};

export default Stepper;
