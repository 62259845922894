/*
 *
 * WMHSignUp constants
 *
 */

export const REGISTER_USER = 'app/WMHSignUp/REGISTER_USER';
export const REGISTER_USER_ERROR = 'app/WMHSignUp/REGISTER_USER_ERROR';
export const REGISTER_USER_SUCCESS = 'app/WMHSignUp/REGISTER_USER_SUCCESS';

export const SUBSCRIBE_EMAIL_MARKETING =
  'app/WMHSignUp/SUBSCRIBE_EMAIL_MARKETING';
export const SUBSCRIBE_EMAIL_MARKETING_ERROR =
  'app/WMHSignUp/SUBSCRIBE_EMAIL_MARKETING_ERROR';
export const SUBSCRIBE_EMAIL_MARKETING_SUCCESS =
  'app/WMHSignUp/SUBSCRIBE_EMAIL_MARKETING_SUCCESS';
