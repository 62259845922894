/*
 *
 * TopNavigationBar reducer
 *
 */

import { fromJS } from 'immutable';
import {
  CHECK_NEW_CHAT_MESSAGE,
  CHECK_NEW_CHAT_MESSAGE_SUCCESS,
  CHECK_NEW_CHAT_MESSAGE_ERROR,
} from './constants';

export const initialState = fromJS({
  newMessageInfo: {
    data: {
      unread_yn: false,
    },
    error: false,
    loading: false,
  },
});

function topNavigationBarReducer(state = initialState, action) {
  switch (action.type) {
    case CHECK_NEW_CHAT_MESSAGE:
      return state
        .setIn(['newMessageInfo', 'loading'], true)
        .setIn(
          ['newMessageInfo', 'data'],
          initialState.getIn(['newMessageInfo', 'data']),
        );
    case CHECK_NEW_CHAT_MESSAGE_ERROR:
      return state
        .setIn(['newMessageInfo', 'loading'], false)
        .setIn(['newMessageInfo', 'error'], fromJS(action.error));
    case CHECK_NEW_CHAT_MESSAGE_SUCCESS:
      return state
        .setIn(['newMessageInfo', 'loading'], false)
        .setIn(['newMessageInfo', 'data'], fromJS(action.newMessageInfo));

    default:
      return state;
  }
}

export default topNavigationBarReducer;
