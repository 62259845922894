import { takeLatest, call, put } from 'redux-saga/effects';
import { fetchStatesSuccess, fetchStatesError } from 'containers/Misc/actions';
import { FETCH_STATES } from 'containers/Misc/constants';
import { API_URL } from '../../utils/environment';
import { get } from '../../utils/request';

function* fetchStates() {
  const requestURL = `${API_URL}/states/`;
  try {
    const states = yield call(get, requestURL);
    yield put(fetchStatesSuccess(states));
  } catch (error) {
    yield put(fetchStatesError(error));
  }
}
// Individual exports for testing
export default function* miscSaga() {
  yield takeLatest(FETCH_STATES, fetchStates);
}
