import styled from 'styled-components';
import PropTypes from 'prop-types';
import React from 'react';
import { phoneSize } from '../../global-styles';

const Wrapper = styled.div`
  display: none;
  @media (max-width: ${phoneSize}) {
    display: ${(props) => (props.open ? 'block' : 'none')};
    opacity: ${(props) => (props.open ? 1 : 0)};
    z-index: 200;
    position: fixed;
    top: 0;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    transition: opacity 0.5s;
  }
`;

const Overlay = (props) => (
  <Wrapper open={props.open} onClick={props.toggleNavSide} />
);

Overlay.propTypes = {
  toggleNavSide: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default Overlay;
