/*
 *
 * Dashboard actions
 *
 */

import {
  FETCH_COMPANY,
  FETCH_COMPANY_SUCCESS,
  FETCH_COMPANY_ERROR,
  FETCH_PRACTITIONER,
  FETCH_PRACTITIONER_SUCCESS,
  FETCH_PRACTITIONER_ERROR,
  FETCH_UPCOMING_SESSIONS,
  FETCH_UPCOMING_SESSIONS_SUCCESS,
  FETCH_UPCOMING_SESSIONS_ERROR,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  FETCH_COUPLES_INFO,
  FETCH_COUPLES_INFO_SUCCESS,
  FETCH_COUPLES_INFO_ERROR,
  FETCH_CARE_TEAM,
  FETCH_CARE_TEAM_SUCCESS,
  FETCH_CARE_TEAM_ERROR,
  FETCH_BENEFITS_COVERAGE,
  FETCH_BENEFITS_COVERAGE_SUCCESS,
  FETCH_BENEFITS_COVERAGE_ERROR,
  ENTER_PARTNER_CODE,
  ENTER_PARTNER_CODE_SUCCESS,
  ENTER_PARTNER_CODE_ERROR,
} from './constants';

export function fetchBenefitsCoverage() {
  return {
    type: FETCH_BENEFITS_COVERAGE,
  };
}

export function fetchBenefitsCoverageSuccess(benefitsCoverage) {
  return {
    type: FETCH_BENEFITS_COVERAGE_SUCCESS,
    benefitsCoverage,
  };
}

export function fetchBenefitsCoverageError(error) {
  return {
    type: FETCH_BENEFITS_COVERAGE_ERROR,
    error,
  };
}

export function fetchCompany() {
  return {
    type: FETCH_COMPANY,
  };
}

export function fetchCompanySuccess(company) {
  return {
    type: FETCH_COMPANY_SUCCESS,
    company,
  };
}

export function fetchCompanyError(error) {
  return {
    type: FETCH_COMPANY_ERROR,
    error,
  };
}

export function fetchPractitioner() {
  return {
    type: FETCH_PRACTITIONER,
  };
}

export function fetchPractitionerSuccess(practitioner) {
  return {
    type: FETCH_PRACTITIONER_SUCCESS,
    practitioner,
  };
}

export function fetchPractitionerError(error) {
  return {
    type: FETCH_PRACTITIONER_ERROR,
    error,
  };
}

export function fetchUpcomingSessions() {
  return {
    type: FETCH_UPCOMING_SESSIONS,
  };
}

export function fetchUpcomingSessionsSuccess(upcomingSessions) {
  return {
    type: FETCH_UPCOMING_SESSIONS_SUCCESS,
    upcomingSessions,
  };
}

export function fetchUpcomingSessionsError(error) {
  return {
    type: FETCH_UPCOMING_SESSIONS_ERROR,
    error,
  };
}

export function changePassword(values, callback) {
  return {
    type: CHANGE_PASSWORD,
    values,
    callback,
  };
}

export function changePasswordSuccess() {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
  };
}

export function changePasswordError(error) {
  return {
    type: CHANGE_PASSWORD_ERROR,
    error,
  };
}

export function fetchCouplesInfo(callback) {
  return {
    type: FETCH_COUPLES_INFO,
    callback,
  };
}

export function fetchCouplesInfoSuccess(couplesInfo) {
  return {
    type: FETCH_COUPLES_INFO_SUCCESS,
    couplesInfo,
  };
}

export function fetchCouplesInfoError(error) {
  return {
    type: FETCH_COUPLES_INFO_ERROR,
    error,
  };
}

export function fetchCareTeam() {
  return {
    type: FETCH_CARE_TEAM,
  };
}

export function fetchCareTeamSuccess(careTeam) {
  return {
    type: FETCH_CARE_TEAM_SUCCESS,
    careTeam,
  };
}

export function fetchCareTeamError(error) {
  return {
    type: FETCH_CARE_TEAM_ERROR,
    error,
  };
}

export function enterPartnerCode(match_code, callback, errCallback) {
  return {
    type: ENTER_PARTNER_CODE,
    match_code,
    callback,
    errCallback,
  };
}

export function enterPartnerCodeSuccess(match_code) {
  return {
    type: ENTER_PARTNER_CODE_SUCCESS,
    match_code,
  };
}

export function enterPartnerCodeError(error) {
  return {
    type: ENTER_PARTNER_CODE_ERROR,
    error,
  };
}
