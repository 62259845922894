import { deletePhoneVerificationReminder } from 'containers/PhoneVerificationDialog';
import queryString from 'query-string';
import { ROOT_URL, SERVER_URL } from './environment';
import { post } from './request';

export function setToken(token) {
  return localStorage.setItem('token', token);
}

export function getToken() {
  return localStorage.getItem('token');
}

export function headers(token = null) {
  return {
    headers: {
      Authorization: token || getToken(),
    },
  };
}

export function isUnauthorized(error) {
  if (error && error.response && error.response.status === 401) {
    return true;
  }

  return false;
}

export const clearAuthTokens = () => {
  // clean local storage
  localStorage.removeItem('token');
  localStorage.removeItem('last_action_at');
  deletePhoneVerificationReminder();
};

export function signOut(redirect = true) {
  clearAuthTokens();

  // redirect to marketing site
  if (redirect) {
    window.location = `${ROOT_URL}/signin`;
  }
}

export function unauthSignOut() {
  const exceptionalRoutes = ['/redirect', '/confirmation', '/two_factor'];
  const currentPath = window.location.pathname;
  const isExceptional = exceptionalRoutes.some((route) =>
    currentPath.includes(route),
  );

  if (!isExceptional) {
    signOut();
  }
}

export function setBackTo(href) {
  return localStorage.setItem('back_to', href);
}

export function getBackTo() {
  return localStorage.getItem('back_to');
}

export const getRedirectToTherapyUrl = (token, additionalParams) => {
  const params = {
    ...additionalParams,
    back_to: window.location.href,
  };

  const url = ROOT_URL;

  return `${url}/redirect/${token}?${queryString.stringify(params)}`;
};

export const redirectToTherapy = (token, additionalParams) => {
  window.location.replace(getRedirectToTherapyUrl(token, additionalParams));
};

export const getLoginUrl = () => `${SERVER_URL}/api/login/azure`;

export const loginWithAzure = async (azureIdToken) => {
  try {
    const response = await post(getLoginUrl(), {
      azure_id_token: azureIdToken,
    });
    setToken(response.access_token);
    window.location.href = ROOT_URL;
    return true;
  } catch {
    return false;
  }
};
