/*
 *
 * TopNavigationBar constants
 *
 */

export const CHECK_NEW_CHAT_MESSAGE =
  'app/TopNavigationBar/CHECK_NEW_CHAT_MESSAGE';
export const CHECK_NEW_CHAT_MESSAGE_SUCCESS =
  'app/TopNavigationBar/CHECK_NEW_CHAT_MESSAGE_SUCCESS';
export const CHECK_NEW_CHAT_MESSAGE_ERROR =
  'app/TopNavigationBar/CHECK_NEW_CHAT_MESSAGE_ERROR';
