/**
 *
 * TopNavigationBar
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Map } from 'immutable';

import { makeSelectUser } from 'containers/UserInformation/selectors';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { tabletSize } from 'global-styles';
import { fetchDefaultCard, updateDefaultCard } from './actions.js';
import { makeSelectDefaultCard } from './selectors';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import saga from './saga';
import reducer from './reducer';
import { Link } from 'react-router-dom';

const BannerWrapper = styled.div`
  width: 100%;
  padding: 24px 7.5%;
  background-color: #1f285b;
  color: white;
  display: flex;
  align-items: center;
  gap: 86px;

  @media (max-width: ${tabletSize}) {
    padding: 24px 40px;
    gap: 14px;
    flex-direction: column;
  }
`;

const Header = styled.h1`
  color: #fff;

  font-family: 'Source Sans Pro';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  margin-bottom: 14px;
`;

const Body = styled.div`
  color: #fff;

  font-family: 'Source Sans Pro';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 100% */
`;

const ActionButton = styled.button`
  display: flex;
  height: 48px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  background: #fff;
  color: #2954c3;
  padding: 8px 48px;
  white-space: nowrap;
  font-size: 16px;
  font-family: 'Barlow';
  font-weight: 600;
  line-height: 20px;
`;

const FailedPaymentsBanner = (props) => {
  useEffect(() => {
    props.fetchDefaultCard();
  });

  const { user, defaultCard } = props;
  const failedPayments = user.toJS().failed_payment_embed_page_types;
  const lastFourDigits = defaultCard && defaultCard.toJS().last_4_digits;

  if (failedPayments?.length > 0) {
    return (
      <BannerWrapper>
        <div>
          <Header>
            <FormattedMessage defaultMessage="Update payment details and pay outstanding balance" />
          </Header>
          <Body>
            <FormattedMessage
              defaultMessage="We were unable to charge your credit card ending in {lastFourDigits} for your latest counselling appointment. You won't be able to book your next appointment until your outstanding balance is paid."
              values={{
                lastFourDigits: lastFourDigits,
              }}
            />
          </Body>
        </div>
        <Link to="/failed_payments">
          <ActionButton>
            <FormattedMessage defaultMessage="Update Payment Information" />
          </ActionButton>
        </Link>
      </BannerWrapper>
    );
  } else {
    return <></>;
  }
};

FailedPaymentsBanner.propTypes = {
  user: PropTypes.instanceOf(Map),
  locale: PropTypes.string,
};

function mapDispatchToProps(dispatch) {
  return {
    fetchDefaultCard: () => dispatch(fetchDefaultCard()),
    updateDefaultCard: (token) => dispatch(updateDefaultCard(token)),
  };
}

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  defaultCard: makeSelectDefaultCard(),
});

const withReducer = injectReducer({ key: 'failedPaymentsBanner', reducer });
const withSaga = injectSaga({ key: 'failedPaymentsBanner', saga });

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(FailedPaymentsBanner);
