import styled from 'styled-components';
import H1 from 'components/H1';
import H3 from 'components/H3';
import Button from 'components/Button';
import {
  darkSlateBlue,
  tabletSize,
  phoneSize,
  mediumBlue,
} from '../../../global-styles';
const Container = styled.div`
  max-width: 540px;
  margin: 0 auto;
  text-align: center;
  margin-top: 40px;
`;

const RowWrapper = styled.div`
  position: relative;
  text-align: left;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${phoneSize}) {
    display: block;
    margin: auto 0;
    text-align: center;
  }
`;

const CustomWrapper = styled.div`
  position: relative;
  text-align: left;
  display: flex;
  justify-content: flex-end;
  margin-top: -40px;
  @media (max-width: ${phoneSize}) {
    display: block;
    margin: auto 0;
    text-align: center;
  }
`;

const StyledH1 = styled(H1)`
  margin: 0 0 16px 0;
  text-align: left;
`;

const StyledH3 = styled(H3)`
  margin: 0 0 40px 0;
  text-align: left;
  color: ${darkSlateBlue};
`;

const StyledButton = styled(Button)`
  margin: 57px 0 0 0 !important;
  width: 140px;
  :focus {
    outline: 5px solid ${mediumBlue};
  }
  @media (max-width: ${tabletSize}) {
    margin-top: 120px !important;
  }
  @media (max-width: ${phoneSize}) {
    margin-top: 60px !important;
  }
`;

const ButtonContainer = styled.div`
  margin-bottom: 5rem;
`;

const FieldContainer = styled.div`
  @media (max-width: ${phoneSize}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
export {
  Container,
  RowWrapper,
  CustomWrapper,
  StyledH1,
  StyledH3,
  StyledButton,
  ButtonContainer,
  FieldContainer,
};
