import styled from 'styled-components';
import { mediumBlue } from '../../../global-styles';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    :focus {
      outline: 5px solid ${mediumBlue};
    }
  }
`;

export default ButtonWrapper;
