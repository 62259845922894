/*
 *
 * TopNavigationBar actions
 *
 */

import {
  CHECK_NEW_CHAT_MESSAGE,
  CHECK_NEW_CHAT_MESSAGE_SUCCESS,
  CHECK_NEW_CHAT_MESSAGE_ERROR,
} from './constants';

export function checkNewChatMessage() {
  return {
    type: CHECK_NEW_CHAT_MESSAGE,
  };
}
export function checkNewChatMessageSuccess(newMessageInfo) {
  return {
    type: CHECK_NEW_CHAT_MESSAGE_SUCCESS,
    newMessageInfo,
  };
}

export function checkNewChatMessageError(error) {
  return {
    type: CHECK_NEW_CHAT_MESSAGE_ERROR,
    error,
  };
}
