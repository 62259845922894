import { takeLatest, put, call } from 'redux-saga/effects';

import { API_URL } from '../../utils/environment';
import { post } from '../../utils/request';
import {
  updateTermsConditionsSuccess,
  updateTermsConditionsError,
} from './actions';
import { UPDATE_TERMS_CONDITIONS } from './constants';

function* updateTermsConditions({ callback }) {
  const requestURL = `${API_URL}/accepted_terms_and_conditions`;

  try {
    yield call(post, requestURL);
    yield put(updateTermsConditionsSuccess());
    if (callback) callback();
  } catch (error) {
    yield put(updateTermsConditionsError(error));
    if (callback) callback(error);
  }
}

// Individual exports for testing
export default function* updatedTermsConditionsSaga() {
  yield takeLatest(UPDATE_TERMS_CONDITIONS, updateTermsConditions);
}
