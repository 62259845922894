import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import DashboardIcon from '../../images/Header/dashboard.svg';
import MyPractitionerIcon from '../../images/Header/my-practitioner.svg';
import SessionIcon from '../../images/Header/sessions.svg';
import PreferenceIcon from '../../images/Header/preferences.svg';
import SupportIcon from '../../images/Header/support.svg';
import AdditionalServicesIcon from '../../images/Header/additional_service.svg';
import { connect } from 'react-redux';
import { makeSelectUserHasAdvisoryServices } from 'containers/UserInformation/selectors';

export const DASHBOARD = {
  name: 'Dashboard',
  formattedMessage: <FormattedMessage {...messages.dashboardLink} />,
  to: '/',
  icon: DashboardIcon,
};

export const MY_CARE_TEAM = {
  name: 'My Care Team',
  formattedMessage: <FormattedMessage {...messages.myPractitionerLink} />,
  to: '/my_care_provider',
  icon: MyPractitionerIcon,
};
export const APPOINTMENTS = {
  name: 'Appointments',
  formattedMessage: <FormattedMessage {...messages.appointmentsLink} />,
  to: '/sessions',
  icon: SessionIcon,
};

export const PREFERENCES = {
  name: 'Preferences',
  formattedMessage: <FormattedMessage {...messages.preferencesLink} />,
  to: '/preferences',
  icon: PreferenceIcon,
};

export const SUPPORT = {
  name: 'Support',
  formattedMessage: <FormattedMessage {...messages.supportLink} />,
  to: '/support',
  icon: SupportIcon,
};

export const ADVISORY_SERVICES = {
  name: 'Work, Health and Life Services',
  formattedMessage: <FormattedMessage {...messages.advisoryServices} />,
  to: '/services',
  icon: AdditionalServicesIcon,
};

export const COACHING_SERVICES = {
  name: 'Coaching Services',
  formattedMessage: <FormattedMessage {...messages.coachingServices} />,
  to: '/services',
  icon: AdditionalServicesIcon,
};

const hasAdvisoryServicesSelector = makeSelectUserHasAdvisoryServices();

export const withNavigationLinks = connect((state) => {
  const user = state.get('user');

  const hasAdvisoryServices = hasAdvisoryServicesSelector(state);

  if (user.getIn(['company', 'name']) === 'ATB') {
    return {
      navigationLinks: [
        DASHBOARD,
        MY_CARE_TEAM,
        APPOINTMENTS,
        COACHING_SERVICES,
        PREFERENCES,
        SUPPORT,
      ],
    };
  }

  if (hasAdvisoryServices) {
    return {
      navigationLinks: [
        DASHBOARD,
        MY_CARE_TEAM,
        APPOINTMENTS,
        ADVISORY_SERVICES,
        PREFERENCES,
        SUPPORT,
      ],
    };
  }

  return {
    navigationLinks: [
      DASHBOARD,
      MY_CARE_TEAM,
      APPOINTMENTS,
      PREFERENCES,
      SUPPORT,
    ],
  };
});
