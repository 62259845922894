import styled from 'styled-components';
import { phoneSize } from '../../global-styles';

export default styled.div`
  height: 140px;
  background-color: #ffffff;
  position: relative;
  z-index: 100;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.15);
  @media (max-width: ${phoneSize}) {
    height: 70px;
  }
`;
