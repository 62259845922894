import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Content from 'components/Content';
import H2 from 'components/H2';
import messages from '../messages';
import UnmatchDialog from './UnmatchDialog';
import ErrorDialog from './ErrorDialog';

const Container = styled.div`
  margin: 60px 0;
`;

class Unmatch extends Component {
  constructor(props) {
    super(props);
    this.state = { dialogOpen: false, errorDialogOpen: false };
  }

  toggleDialog = () => {
    this.setState((prevState) => ({
      dialogOpen: !prevState.dialogOpen,
    }));
  };

  toggleErrorDialog = () => {
    this.setState((prevState) => ({
      errorDialogOpen: !prevState.errorDialogOpen,
    }));
  };

  render() {
    const { errorDialogOpen } = this.state;
    const { provider } = this.props;
    return (
      <Container>
        <H2 style={{ marginBottom: 40 }}>
          <FormattedMessage {...messages.careProviderPreferences} />
        </H2>
        <Content>
          <FormattedMessage {...messages.doYouUnmatch} />
        </Content>
        <button
          onClick={
            provider.upcoming_session_yn
              ? this.toggleErrorDialog
              : this.toggleDialog
          }
          type="button"
          style={{
            cursor: 'pointer',
            marginTop: '20px',
            color: '#2e5fca',
            fontFamily: "'Source Sans Pro', sans-serif",
            fontWeight: '600',
            fontSize: '16px',
            padding: '0',
            letterSpacing: '0.2px',
          }}
        >
          <FormattedMessage {...messages.unmatch} />
        </button>
        <UnmatchDialog
          onRequestClose={this.toggleDialog}
          open={this.state.dialogOpen}
          provider={provider}
        />
        <ErrorDialog
          toggleOpen={this.toggleErrorDialog}
          open={errorDialogOpen}
        />
      </Container>
    );
  }
}

Unmatch.propTypes = {
  provider: PropTypes.object.isRequired,
};

export default Unmatch;
