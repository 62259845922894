/**
 *
 * UpdatedTermsConditions
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Map } from 'immutable';
import styled from 'styled-components';

import Dialog from 'components/Dialog';
import injectSaga from 'utils/injectSaga';
// import injectReducer from 'utils/injectReducer';
import { makeSelectUser } from 'containers/UserInformation/selectors';
import makeSelectTermsAndConditions from 'containers/TermsAndConditions/selectors';

import TermsAndConditionsForm from './TermsAndConditionsForm';
import Declined from './Declined';
// import reducer from './reducer';
import saga from './saga';
import { updateTermsConditions } from './actions';

const Container = styled.div`
  margin: 0 auto;
  text-align: center;
  max-width: 512px;
  width: 90%;

  h1 {
    margin-bottom: 20px;
  }

  h3 {
    margin-bottom: 10px;
  }

  .contact-support {
    margin-bottom: 20px;
  }
`;

/* eslint-disable react/prefer-stateless-function */
class UpdatedTermsConditions extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      declined: false,
      submittedForm: false,
    };
  }

  submitForm = () => this.setState({ submittedForm: true });

  toggleDeclined = () =>
    this.setState((prevState) => ({ declined: !prevState.declined }));

  render() {
    const { termsAndConditions, user, tokenLoaded } = this.props;
    const { declined, submittedForm } = this.state;
    const acceptedTerms = user.get('accepted_terms');
    const dialogOpen = acceptedTerms === false && !submittedForm;

    if (acceptedTerms) return <div />;

    return (
      <Dialog closeHidden maxWidth="698px" open={dialogOpen}>
        <Container>
          {!declined ? (
            <TermsAndConditionsForm
              submitForm={this.submitForm}
              loading={termsAndConditions.loading}
              termsAndConditions={termsAndConditions.data}
              toggleDeclined={this.toggleDeclined}
              tokenLoaded={tokenLoaded}
              updateTermsConditions={this.props.updateTermsConditions}
              company={user.get('company')}
            />
          ) : (
            <Declined toggleDeclined={this.toggleDeclined} />
          )}
        </Container>
      </Dialog>
    );
  }
}

UpdatedTermsConditions.propTypes = {
  termsAndConditions: PropTypes.object,
  tokenLoaded: PropTypes.bool.isRequired,
  updateTermsConditions: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Map),
};

const mapStateToProps = createStructuredSelector({
  termsAndConditions: makeSelectTermsAndConditions(),
  user: makeSelectUser(),
});

function mapDispatchToProps(dispatch) {
  return {
    updateTermsConditions: (callback) =>
      dispatch(updateTermsConditions(callback)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

// const withReducer = injectReducer({ key: 'updatedTermsConditions', reducer });
const withSaga = injectSaga({ key: 'updatedTermsConditions', saga });

export default compose(withSaga, withConnect)(UpdatedTermsConditions);
