/*
 *
 * UserInformation reducer
 *
 */

import { fromJS } from 'immutable';
import {
  FETCH_DEFAULT_CARD,
  FETCH_DEFAULT_CARD_ERROR,
  FETCH_DEFAULT_CARD_SUCCESS,
  UPDATE_DEFAULT_CARD,
  UPDATE_DEFAULT_CARD_SUCCESS,
  UPDATE_DEFAULT_CARD_ERROR,
  FETCH_FAILED_APPOINTMENTS,
  FETCH_FAILED_APPOINTMENTS_SUCCESS,
  FETCH_FAILED_APPOINTMENTS_ERROR,
  RETRY_FAILED_PAYMENTS,
  RETRY_FAILED_PAYMENTS_ERROR,
  RETRY_FAILED_PAYMENTS_SUCCESS,
} from './constants';

export const initialState = fromJS({
  loading: false,
  defaultCard: {},
  failedAppointments: [],
  processedPayments: [],
});

function failedPaymentsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DEFAULT_CARD:
      return state.set('loading', true);
    case FETCH_DEFAULT_CARD_ERROR:
      return state.set('loading', false).set('error', action.error);
    case FETCH_DEFAULT_CARD_SUCCESS:
      return state
        .set('loading', false)
        .mergeDeep({ defaultCard: action.card });
    case UPDATE_DEFAULT_CARD:
      return state.set('loading', true);
    case UPDATE_DEFAULT_CARD_ERROR:
      return state.set('loading', false).set('error', action.error);
    case UPDATE_DEFAULT_CARD_SUCCESS:
      return state
        .set('loading', false)
        .mergeDeep({ defaultCard: action.card });
    case FETCH_FAILED_APPOINTMENTS:
      return state.set('loading', true);
    case FETCH_FAILED_APPOINTMENTS_ERROR:
      return state.set('loading', false).set('error', action.error);
    case FETCH_FAILED_APPOINTMENTS_SUCCESS:
      return state
        .set('loading', false)
        .mergeDeep({ failedAppointments: action.appointments });
    case RETRY_FAILED_PAYMENTS:
      return state.set('loading', true);
    case RETRY_FAILED_PAYMENTS_ERROR:
      return state.set('loading', false).set('error', action.error);
    case RETRY_FAILED_PAYMENTS_SUCCESS:
      return state
        .set('loading', false)
        .mergeDeep({ processedPayments: action.ids });
    default:
      return state;
  }
}

export default failedPaymentsReducer;
