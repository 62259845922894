/*
 *
 * Dashboard constants
 *
 */
export const FETCH_COMPANY = 'app/Dashboard/FETCH_COMPANY';
export const FETCH_COMPANY_SUCCESS = 'app/Dashboard/FETCH_COMPANY_SUCCESS';
export const FETCH_COMPANY_ERROR = 'app/Dashboard/FETCH_COMPANY_ERROR';

export const FETCH_PRACTITIONER = 'app/Dashboard/FETCH_PRACTITIONER';
export const FETCH_PRACTITIONER_SUCCESS =
  'app/Dashboard/FETCH_PRACTITIONER_SUCCESS';
export const FETCH_PRACTITIONER_ERROR =
  'app/Dashboard/FETCH_PRACTITIONER_ERROR';

export const FETCH_BENEFITS_COVERAGE = 'app/Dashboard/FETCH_BENEFITS_COVERAGE';
export const FETCH_BENEFITS_COVERAGE_SUCCESS =
  'app/Dashboard/FETCH_BENEFITS_COVERAGE_SUCCESS';
export const FETCH_BENEFITS_COVERAGE_ERROR =
  'app/Dashboard/FETCH_BENEFITS_COVERAGE_ERROR';

export const FETCH_UPCOMING_SESSIONS = 'app/Dashboard/FETCH_UPCOMING_SESSIONS';
export const FETCH_UPCOMING_SESSIONS_SUCCESS =
  'app/Dashboard/FETCH_UPCOMING_SESSIONS_SUCCESS';
export const FETCH_UPCOMING_SESSIONS_ERROR =
  'app/Dashboard/FETCH_UPCOMING_SESSIONS_ERROR';

export const CHANGE_PASSWORD = 'app/Dashboard/CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'app/Dashboard/CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'app/Dashboard/CHANGE_PASSWORD_ERROR';

export const FETCH_COUPLES_INFO = 'app/Dashboard/FETCH_COUPLES_INFO';
export const FETCH_COUPLES_INFO_SUCCESS =
  'app/Dashboard/FETCH_COUPLES_INFO_SUCCESS';
export const FETCH_COUPLES_INFO_ERROR =
  'app/Dashboard/FETCH_COUPLES_INFO_ERROR';

export const FETCH_CARE_TEAM = 'app/Dashboard/FETCH_CARE_TEAM';
export const FETCH_CARE_TEAM_SUCCESS = 'app/Dashboard/FETCH_CARE_TEAM_SUCCESS';
export const FETCH_CARE_TEAM_ERROR = 'app/Dashboard/FETCH_CARE_TEAM_ERROR';

export const ENTER_PARTNER_CODE = 'app/Dashboard/ENTER_PARTNER_CODE';
export const ENTER_PARTNER_CODE_SUCCESS =
  'app/Dashboard/ENTER_PARTNER_CODE_SUCCESS';
export const ENTER_PARTNER_CODE_ERROR =
  'app/Dashboard/ENTER_PARTNER_CODE_ERROR';
