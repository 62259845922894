/**
 *
 * SingleCheckBoxContent
 *
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';

import Content from 'components/Content';
import P from 'components/P';
import { errorRed, phoneSize } from 'global-styles';

import SelectedSvg from './selected.svg';
import UnselectedSvg from './unselected.svg';

const StyledContainer = styled.div`
  p {
    margin: 0;
  }

  @media (max-width: ${phoneSize}) {
    margin-bottom: 10px;
  }
`;

const StyledCheckBox = styled.button`
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  position: relative;
  cursor: pointer;

  margin-left: -10px;
  .scb-label p {
    text-align: left;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.1px;
  }
`;

function SingleCheckBoxContent({
  input,
  meta,
  label,
  containerStyle,
  smallText,
}) {
  const { value, onChange } = input;
  const { touched, error } = meta;
  return (
    <StyledContainer style={containerStyle}>
      <StyledCheckBox type="button" onClick={() => onChange(!value)}>
        <div className="scb-icon">
          <SVG src={value ? SelectedSvg : UnselectedSvg} alt="selected" />
        </div>
        <div className={smallText ? 'scb-label' : ''}>
          <Content bold={!smallText}>{label}</Content>
        </div>
      </StyledCheckBox>
      {touched && error && <P color={errorRed}>{touched ? error : ''}</P>}
    </StyledContainer>
  );
}

SingleCheckBoxContent.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  containerStyle: PropTypes.object,
  smallText: PropTypes.bool,
};

SingleCheckBoxContent.defaultProps = {
  smallText: false,
};

export default SingleCheckBoxContent;
