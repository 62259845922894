import { takeLatest, put as reduxPut, call } from 'redux-saga/effects';

import { API_URL } from 'utils/environment';
import { get, post, put } from 'utils/request';

import {
  fetchDefaultCardSuccess,
  fetchDefaultCardError,
  updateDefaultCardSuccess,
  updateDefaultCardError,
  fetchFailedAppointmentsSuccess,
  fetchFailedAppointmentsError,
  retryFailedPaymentsSuccess,
  retryFailedPaymentsError,
} from './actions.js';
import {
  FETCH_DEFAULT_CARD,
  UPDATE_DEFAULT_CARD,
  FETCH_FAILED_APPOINTMENTS,
  RETRY_FAILED_PAYMENTS,
} from './constants.js';

function* fetchDefaultCard() {
  const requestURL = `${API_URL}/stripe_customers`;

  try {
    const card = yield call(get, requestURL);
    yield reduxPut(fetchDefaultCardSuccess(card));
  } catch (error) {
    yield reduxPut(fetchDefaultCardError(error));
  }
}

function* updateDefaultCard({ token, callback }) {
  const requestURL = `${API_URL}/stripe_customers`;

  try {
    const card = yield call(post, requestURL, token);
    yield reduxPut(updateDefaultCardSuccess(card));
    if (callback) yield callback(card);
  } catch (error) {
    yield reduxPut(updateDefaultCardError(error));
  }
}

function* fetchFailedAppointments() {
  const requestUrl = `${API_URL}/card_payment_retries`;
  try {
    const failedAppointments = yield call(get, requestUrl);
    yield reduxPut(fetchFailedAppointmentsSuccess(failedAppointments));
  } catch (error) {
    yield reduxPut(fetchFailedAppointmentsError(error));
  }
}

function* retryFailedPayments({ ids, callback }) {
  const requestUrl = `${API_URL}/card_payment_retries/retry`;

  try {
    const failedAppointments = yield call(put, requestUrl, { ids });
    yield reduxPut(retryFailedPaymentsSuccess(failedAppointments));
    if (callback) yield callback(failedAppointments);
  } catch (error) {
    yield reduxPut(retryFailedPaymentsError(error));
  }
}

export default function* failedPaymentsBannerSaga() {
  yield takeLatest(FETCH_DEFAULT_CARD, fetchDefaultCard);
  yield takeLatest(UPDATE_DEFAULT_CARD, updateDefaultCard);
  yield takeLatest(FETCH_FAILED_APPOINTMENTS, fetchFailedAppointments);
  yield takeLatest(RETRY_FAILED_PAYMENTS, retryFailedPayments);
}
