export const validEmail =
  /^[A-Z0-9.'_%+-]+@[A-Z0-9.éëêèâàæôœùûüçîïÿ-]+\.[A-Z]{2,15}$/i;

/**
 * Validate whether a provided string is a valid Canadian postal code.
 * @param {String} value Postal code candidate
 * @returns True if value is a possible Canadian postal code; otherwise; False
 */
export function validateCanadianPostalCode(value) {
  return /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i.test(
    value,
  );
}

/**
 * Validates whether a provided string is a valid US ZIP code, in either the traditional five digit
 * format or the post-1983 ZIP+4 format.
 * @param {String} value ZIP code candidate
 * @returns True if valid; otherwise, False
 */
export function validateUSZipCode(value) {
  return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
}

/**
 * Validates whether a provided string is a valid phone number in the North American Numbering Plan format,
 * excluding the country code (+1 prefix). For example, a valid phone number would be: 905-555-1234
 * @param {String} value Phone number candidate
 * @returns {Boolean} If provided string is a valid formatted phone number for North America
 */
export const validateNorthAmericanPhoneNumber = (value) =>
  /^\d{3}-\d{3}-\d{4}$/.test(value);

export const normalizeCanadianPostal = (value) => {
  if (!value) {
    return value;
  }

  const noSpace = value.replace(' ', '');
  const onlyUpperCase = noSpace.replace(/[a-z]/g, (str) => str.toUpperCase());

  if (onlyUpperCase.length <= 3) {
    return onlyUpperCase;
  }

  return `${onlyUpperCase.slice(0, 3)} ${onlyUpperCase.slice(3, 6)}`;
};

export const normalizeUSZip = (value) => {
  if (!value) {
    return value;
  }

  const hasLastDash = value.match(/-$/);
  const onlyDigit = value.replace(/[^\d+]/, '');

  if (onlyDigit.length <= 4) {
    return onlyDigit;
  }

  if (onlyDigit.length === 5) {
    if (hasLastDash) {
      return `${onlyDigit.slice(0, 5)}-`;
    }
    return onlyDigit;
  }

  if (onlyDigit.length === 5 && hasLastDash) {
    return `${onlyDigit.slice(0, 5)}-`;
  }

  return `${onlyDigit.slice(0, 5)}-${onlyDigit.slice(5, 9)}`;
};

export const GSCPolicyIdValidationStatus = {
  Valid: 0,
  InvalidNotAlphanumeric: 1,
  InvalidContainsDependentCode: 2,
  InvalidNotAllCaps: 3,
};

/**
 * Checks if the current policyId in the state is valid
 * @returns {GSCPolicyIdValidationStatus} Tuple with validation status and error message. If validation fails (and first item is false),
 * then an error message is includeed in the second item.
 */
export function validateGSCPolicyId(policyId) {
  const re = /^[A-Z0-9]+$/;
  const isValid = re.test(policyId);
  if (!isValid) {
    const hasDash = policyId.includes('-');
    if (hasDash) {
      return GSCPolicyIdValidationStatus.InvalidContainsDependentCode;
    }

    if (policyId.toUpperCase() !== policyId) {
      return GSCPolicyIdValidationStatus.InvalidNotAllCaps;
    }

    return GSCPolicyIdValidationStatus.InvalidNotAlphanumeric;
  }

  return GSCPolicyIdValidationStatus.Valid;
}

export const GSCDependantCodeValidationStatus = {
  Valid: 0,
  Invalid: 1,
};

/**
 * Validates whether a provided string is a valid GSC Dependant Code. Tests only the format of the value,
 * and does not validate whether the dependent exists at GSC.
 * @param {String} dependantCode Dependent code candidate
 * @returns {GSCDependantCodeValidationStatus}
 */
export function validateGSCDependantCode(dependantCode) {
  const re = /^[0-9]{2}$/;
  const isValid = re.test(dependantCode);
  if (!isValid) {
    return GSCDependantCodeValidationStatus.Invalid;
  }

  return GSCDependantCodeValidationStatus.Valid;
}
