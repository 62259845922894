/* eslint-disable no-continue */
/* eslint-disable indent */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { validEmail } from 'utils/validation';
import messages from './messages';

const pathOpening = ['intro', 'select-path', 'ors1', 'ors2', 'ors3', 'ors4'];
const pathExpress = [
  'dass1',
  'dass2',
  'dass3',
  'dass4',
  'gen',
  'phys',
  'diagnosis',
  'stress',
  'ws',
];
const pathComprehensive = [
  'dass1',
  'dass5',
  'dass2',
  'dass6',
  'dass3',
  'dass7',
  'gen',
  'dep',
  'ptsd',
  'sub',
  'phys',
  'diagnosis',
  'stress',
  'ws',
];
const pathWork = ['work'];
const pathSchool = ['school', 'school_level'];
const pathInsurance = ['insurance_yes'];
const pathClosing = [
  'language',
  'specialties',
  'religion',
  'general_availability',
  'match-list',
  'phone',
  'tos',
  'end!',
];

const pathSetInsurance = [
  {
    valueName: 'insurance_select',
    path: { 0: ['insurance'], 1: ['insurance_usa'] },
  },
  {
    valueName: 'insurance_type',
    path: { 0: pathInsurance, 1: [], 2: [], 999: [] }, // 999 means skip only for navigation of pages
  },
  {
    valueName: 'insurance_type_usa',
    path: { 0: ['insurance_usa_yes'], 1: [], 999: [] },
  },
];

const rootPathIndividual = [
  { valueName: 'opening', path: { 0: pathOpening } },
  { valueName: 'match_type', path: { 0: pathExpress, 1: pathComprehensive } },
  {
    valueName: 'ws_type',
    path: {
      0: pathWork,
      1: pathSchool,
      2: [...pathWork, ...pathSchool],
      3: [],
    },
  },
  { valueName: 'location_select', path: { 0: ['location'] } },
  // { valueName: 'atb', path: { 0: [], 1: pathAtb } },
  ...pathSetInsurance,
  { valueName: 'closing', path: { 0: pathClosing } },
];

// couples counselling path settings
const pathCouplesOpening = [
  'intro-couples',
  'rors1',
  'rors2',
  'rors3',
  'rors4',
  'rgoal',
  'location',
];

const pathCouplesClosing = [
  'language',
  'religion',
  'general_availability',
  'match-list',
  'phone',
  'couples-invitation',
  'tos',
  'end!',
];

const rootPathCouples = [
  { valueName: 'opening', path: { 0: pathCouplesOpening } },
  ...pathSetInsurance,
  { valueName: 'closing', path: { 0: pathCouplesClosing } },
];

let rootPath = rootPathIndividual;
let skipTotalSteps = -5; // skip steps in total screens to calculate progress percent
let skipIntoSteps = -2; // skip steps in intro screens to calculate progress percent

export const setRootPath = (providerType) => {
  rootPath = rootPathIndividual;
  skipTotalSteps = -5;
  skipIntoSteps = -2;

  if (providerType === 'couples') {
    rootPath = rootPathCouples;
    skipTotalSteps = -5; // [match-list, phone, tos, invitation, end!]
    skipIntoSteps = -1; // [intro-couples]
  }
};

const insertAtbQuestions = () => {
  const pathAtb = ['atb_work', 'atb_productivity'];
  if (!rootPath[1].path[0].includes('atb_work')) {
    const diagnosisIndex = rootPath[1].path[0].indexOf('diagnosis');

    rootPath[1].path[0].splice(diagnosisIndex, 0, ...pathAtb); // inserting into express matching

    rootPath[1].path[1].splice(diagnosisIndex, 0, ...pathAtb); // inserting into comprehensive matching
  }
};

const insertParentGuardianQuestions = () => {
  if (!rootPath[3].path[0].includes('parent_guardian')) {
    const locationIndex = rootPath[3].path[0].indexOf('location');
    rootPath[3].path[0].splice(locationIndex + 1, 0, 'parent_guardian'); // express
  }
};

export const getNextPageId = (
  current,
  values,
  forward = true,
  skipInsurance = false,
  atb = false,
  minorStatus = false,
) => {
  if (atb) {
    insertAtbQuestions(); // ATB has two additional questions
  }
  if (minorStatus) {
    insertParentGuardianQuestions();
  }

  for (let i = 0; i < rootPath.length; i += 1) {
    const path = rootPath[i].path[values[rootPath[i].valueName]];
    if (!path) {
      return '';
    }

    // look for index of current Id
    const index = path.indexOf(current);
    if (index < 0) {
      continue;
    }

    // search direction
    if (forward) {
      // next page
      if (index < path.length - 1) {
        // in the same path array
        return path[index + 1];
      }
      // in the next path array
      for (let j = 0; j < rootPath.length - i; j += 1) {
        const nextPath =
          rootPath[i + j + 1].path[values[rootPath[i + j + 1].valueName]];
        // out of range
        if (!nextPath) {
          return '';
        }

        // skip empty path array
        if (nextPath.length <= 0) {
          continue;
        }
        // Skip insurance page if Gallivan or part of corporate account
        if (
          skipInsurance &&
          (nextPath[0] === 'insurance' ||
            nextPath[0] === 'insurance_yes' ||
            nextPath[0] === 'insurance_usa' ||
            nextPath[0] === 'insurance_usa_yes')
        ) {
          continue;
        }

        return nextPath[0];
      }
    }

    // previous page
    if (index >= 1) {
      // in the same path array
      return path[index - 1];
    }

    // in the previous path array
    for (let j = 0; j < i; j += 1) {
      const prevPath =
        rootPath[i - j - 1].path[values[rootPath[i - j - 1].valueName]];

      // out of range
      if (!prevPath) {
        return '';
      }

      // skip empty path array
      if (prevPath.length <= 0) {
        continue;
      }

      // Skip insurance page if Gallivan or part of corporate account
      if (
        skipInsurance &&
        (prevPath[0] === 'insurance' ||
          prevPath[0] === 'insurance_yes' ||
          prevPath[0] === 'insurance_usa' ||
          prevPath[0] === 'insurance_usa_yes')
      ) {
        continue;
      }

      return prevPath[prevPath.length - 1];
    }
  }

  // no matches
  return '';
};

export const getProgressPercentage = (current, values) => {
  let isMatched = false;
  let totalStep = skipTotalSteps;
  let currentStep = skipIntoSteps;

  for (let i = 0; i < rootPath.length; i += 1) {
    const path = rootPath[i].path[values[rootPath[i].valueName]];
    if (!path) {
      break;
    }

    if (!isMatched) {
      const indexCurrent = path.indexOf(current);
      if (indexCurrent >= 0) {
        currentStep += indexCurrent;
        isMatched = true;
      } else {
        currentStep += path.length;
      }
    }

    const indexEnd = path.indexOf('end!');
    if (indexEnd >= 0) {
      totalStep += indexEnd;
      break;
    } else {
      totalStep += path.length;
    }
  }

  const percentage = parseInt((currentStep / totalStep) * 100, 10);
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(percentage) || percentage < 0) {
    return 0;
  }
  return percentage;
};

export const normalizeLoanAmount = (value) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d/.]/g, '');
  return onlyNums;
};

export const validateLoanAmount = (value) =>
  value && !/^\d{0,8}(\.\d{1,2})?$/.test(value) ? (
    <div aria-live="polite" role="alert">
      <FormattedMessage {...messages.invalidLoanAmount} />
    </div>
  ) : undefined;

export const normalizeCountryCode = (value) => {
  if (!value) {
    return value;
  }
  return value.replace(/[^\d]/g, '');
};

export const normalizePin = (value) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  return onlyNums.slice(0, 7);
};

export const normalizeEmail = (value) =>
  value && !validEmail.test(value) ? (
    <div aria-live="polite" role="alert">
      <FormattedMessage
        {...messages.enterAnEmailWithExample}
        style={{ width: '220px' }}
        tagName="p"
      />
    </div>
  ) : undefined;

// Return the answer for a single answer question
export const singleSelectAnswer = (currentMatch, questionCode) => {
  const answers = currentMatch.get('answers');
  let value;

  if (answers) {
    value = answers.filter(
      (answer) => answer.get('question_code') === questionCode,
    );

    if (value && value.get(0)) {
      value = parseInt(value.get(0).get('value'), 10);
    }

    return value;
  }
};

// Return the answers for a multi select question
export const multiSelectAnswers = (currentMatch, questionCode) => {
  const answers = currentMatch.get('answers');
  const values = {};

  if (answers) {
    const filteredAnswers = answers.filter(
      (answer) => answer.get('question_code') === questionCode,
    );

    for (let i = 0; i < filteredAnswers.size; i += 1) {
      const value = filteredAnswers.get(i).get('value');
      values[value] = true;
    }
  }

  return values;
};

// Return other answer from question code
export const getOtherSelectAnswer = (currentMatch, questionCode) => {
  const otherAnswers = currentMatch.get('other_answers');
  let value;
  if (otherAnswers) {
    const otherAnswer = otherAnswers.find(
      (answer) => answer.get('question_code') === questionCode,
    );
    if (otherAnswer) value = otherAnswer.get('answer');
  }
  return value;
};

export const genders = [
  {
    label: <FormattedMessage {...messages.ymMan} />,
    value: 'MAN',
  },
  {
    label: <FormattedMessage {...messages.ymWoman} />,
    value: 'WOMAN',
  },
  {
    label: <FormattedMessage {...messages.ymGender} />,
    value: 'NON-BINARY',
  },
  {
    label: <FormattedMessage {...messages.ymTrans} />,
    value: 'TRANSGENDER',
  },
  {
    value: 'PREFER-NO-SAY',
    label: <FormattedMessage {...messages.notSpecified} />,
  },
  {
    value: 'DO-NOT-KNOW',
    label: <FormattedMessage {...messages.doNotKnow} />,
  },
  {
    value: 'GENDER-FLUID-NON-CONFORMING',
    label: <FormattedMessage {...messages.genderNonConforming} />,
  },
  {
    value: 'GENDER-QUESTIONING',
    label: <FormattedMessage {...messages.genderQuestioning} />,
  },
  {
    value: 'GENDERQUEER',
    label: <FormattedMessage {...messages.genderQueer} />,
  },
  {
    value: 'INTERSEX',
    label: <FormattedMessage {...messages.intersex} />,
  },
  {
    value: 'OTHER',
    label: <FormattedMessage {...messages.ymOther} />,
  },
  {
    value: 'TRANS-MAN',
    label: <FormattedMessage {...messages.transMan} />,
  },
  {
    value: 'TRANS-WOMAN',
    label: <FormattedMessage {...messages.transWoman} />,
  },
  {
    value: 'TWO-SPIRIT',
    label: <FormattedMessage {...messages.twoSpirit} />,
  },
];

export const provinces = [
  { value: 1, label: <FormattedMessage {...messages.alberta} />, code: 'AB' },
  {
    value: 2,
    label: <FormattedMessage {...messages.britishColumbia} />,
    code: 'BC',
  },
  { value: 3, label: <FormattedMessage {...messages.manitoba} />, code: 'MB' },
  {
    value: 4,
    label: <FormattedMessage {...messages.newBrunswick} />,
    code: 'NB',
  },
  {
    value: 5,
    label: <FormattedMessage {...messages.newfoundland} />,
    code: 'NL',
  },
  { value: 6, label: <FormattedMessage {...messages.northwest} />, code: 'NT' },
  {
    value: 7,
    label: <FormattedMessage {...messages.novaScotia} />,
    code: 'NS',
  },
  { value: 8, label: <FormattedMessage {...messages.nunavut} />, code: 'NU' },
  { value: 9, label: <FormattedMessage {...messages.ontario} />, code: 'ON' },
  { value: 10, label: <FormattedMessage {...messages.pei} />, code: 'PE' },
  { value: 11, label: <FormattedMessage {...messages.quebec} />, code: 'QC' },
  {
    value: 12,
    label: <FormattedMessage {...messages.saskatchewan} />,
    code: 'SK',
  },
  { value: 13, label: <FormattedMessage {...messages.yukon} />, code: 'YT' },
];

export const states = [
  { value: 17, label: 'Alabama', code: 'AL' },
  { value: 18, label: 'Alaska', code: 'AK' },
  { value: 19, label: 'Arizona', code: 'AZ' },
  { value: 20, label: 'Arkansas', code: 'AR' },
  { value: 21, label: 'California', code: 'CA' },
  { value: 22, label: 'Colorado', code: 'CO' },
  { value: 23, label: 'Connecticut', code: 'CT' },
  { value: 24, label: 'Delaware', code: 'DE' },
  { value: 25, label: 'Florida', code: 'FL' },
  { value: 26, label: 'Georgia', code: 'GA' },
  { value: 27, label: 'Hawaii', code: 'HI' },
  { value: 28, label: 'Idaho', code: 'ID' },
  { value: 29, label: 'Illinois', code: 'IL' },
  { value: 30, label: 'Indiana', code: 'IN' },
  { value: 31, label: 'Iowa', code: 'IA' },
  { value: 32, label: 'Kansas', code: 'KS' },
  { value: 33, label: 'Kentucky', code: 'KY' },
  { value: 34, label: 'Louisiana', code: 'LA' },
  { value: 35, label: 'Maine', code: 'ME' },
  { value: 36, label: 'Maryland', code: 'MD' },
  { value: 15, label: 'Massachusetts', code: 'MA' },
  { value: 37, label: 'Michigan', code: 'MI' },
  { value: 38, label: 'Minnesota', code: 'MN' },
  { value: 39, label: 'Mississippi', code: 'MS' },
  { value: 40, label: 'Missouri', code: 'MO' },
  { value: 41, label: 'Montana', code: 'MT' },
  { value: 42, label: 'Nebraska', code: 'NE' },
  { value: 43, label: 'Nevada', code: 'NV' },
  { value: 44, label: 'New Hampshire', code: 'NH' },
  { value: 45, label: 'New Jersey', code: 'NJ' },
  { value: 46, label: 'New Mexico', code: 'NM' },
  { value: 47, label: 'New York', code: 'NY' },
  { value: 48, label: 'North Carolina', code: 'NC' },
  { value: 49, label: 'North Dakota', code: 'ND' },
  { value: 50, label: 'Ohio', code: 'OH' },
  { value: 51, label: 'Oklahoma', code: 'OK' },
  { value: 52, label: 'Oregon', code: 'OR' },
  { value: 53, label: 'Pennsylvania', code: 'PA' },
  { value: 54, label: 'Rhode Island', code: 'RI' },
  { value: 55, label: 'South Carolina', code: 'SC' },
  { value: 56, label: 'South Dakota', code: 'SD' },
  { value: 57, label: 'Tennessee', code: 'TN' },
  { value: 58, label: 'Texas', code: 'TX' },
  { value: 59, label: 'Utah', code: 'UT' },
  { value: 60, label: 'Vermont', code: 'VT' },
  { value: 61, label: 'Virginia', code: 'VA' },
  { value: 62, label: 'Washington', code: 'WA' },
  { value: 63, label: 'West Virginia', code: 'WV' },
  { value: 64, label: 'Wisconsin', code: 'WI' },
  { value: 65, label: 'Wyoming', code: 'WY' },
];

export const countries = [
  { value: 'Canada', label: 'Canada' },
  { value: 'United States', label: 'United States' },
];

export const matchingProperties = (page, match = {}) => ({
  match_id: match.id,
  match_type: match.match_type,
  page,
});

export const atbWorkOptions = [
  { label: <FormattedMessage {...messages.atbNone} />, value: 0 },
  {
    label: (
      <FormattedMessage {...messages.atbDays} values={{ amount: '11-20' }} />
    ),
    value: 4,
  },
  {
    label: <FormattedMessage {...messages.atbDay} values={{ amount: '0-1' }} />,
    value: 1,
  },
  {
    label: (
      <FormattedMessage {...messages.atbDays} values={{ amount: '21-30' }} />
    ),
    value: 5,
  },
  {
    label: (
      <FormattedMessage {...messages.atbDays} values={{ amount: '2-4' }} />
    ),
    value: 2,
  },
  {
    label: (
      <FormattedMessage {...messages.atbDays} values={{ amount: '>30' }} />
    ),
    value: 6,
  },
  {
    label: (
      <FormattedMessage {...messages.atbDays} values={{ amount: '5-10' }} />
    ),
    value: 3,
  },
];

export const atbProductivityOptions = [
  { label: <FormattedMessage {...messages.atbNone} />, value: 0 },
  {
    label: (
      <FormattedMessage {...messages.atbHours} values={{ amount: '13-16' }} />
    ),
    value: 4,
  },
  {
    label: (
      <FormattedMessage {...messages.atbHours} values={{ amount: '0-4' }} />
    ),
    value: 1,
  },
  {
    label: (
      <FormattedMessage {...messages.atbHours} values={{ amount: '17-20' }} />
    ),
    value: 5,
  },
  {
    label: (
      <FormattedMessage {...messages.atbHours} values={{ amount: '4-8' }} />
    ),
    value: 2,
  },
  {
    label: (
      <FormattedMessage {...messages.atbHours} values={{ amount: '>20' }} />
    ),
    value: 6,
  },
  {
    label: (
      <FormattedMessage {...messages.atbHours} values={{ amount: '9-12' }} />
    ),
    value: 3,
  },
];

export const gallivanCountries = [
  { value: 'Canada', label: 'Canada' },
  {
    value: 'Australia',
    label: 'Australia',
  },
  {
    value: 'Bangladesh',
    label: 'Bangladesh',
  },
  // {
  //   value: 'Belgium',
  //   label: 'Belgium',
  // },
  // {
  //   value: 'Brazil',
  //   label: 'Brazil',
  // },
  // {
  //   value: 'Bulgaria',
  //   label: 'Bulgaria',
  // },
  // {
  //   value: 'China',
  //   label: 'China',
  // },
  {
    value: 'Croatia',
    label: 'Croatia',
  },
  {
    value: 'Denmark',
    label: 'Denmark',
  },
  // {
  //   value: 'Dominican Republic',
  //   label: 'Dominican Republic',
  // },
  // {
  //   value: 'Ecuador',
  //   label: 'Ecuador',
  // },
  // {
  //   value: 'France',
  //   label: 'France',
  // },
  // {
  //   value: 'Germany',
  //   label: 'Germany',
  // },
  {
    value: 'Greece',
    label: 'Greece',
  },
  {
    value: 'India',
    label: 'India',
  },
  // {
  //   value: 'Italy',
  //   label: 'Italy',
  // },
  // {
  //   value: 'Ireland',
  //   label: 'Ireland',
  // },
  // {
  //   value: 'Jamaica',
  //   label: 'Jamaica',
  // },
  // {
  //   value: 'Japan',
  //   label: 'Japan',
  // },
  // {
  //   value: 'Kenya',
  //   label: 'Kenya',
  // },
  // {
  //   value: 'Malta',
  //   label: 'Malta',
  // },
  {
    value: 'Mauritius',
    label: 'Mauritius',
  },
  // {
  //   value: 'Mexico',
  //   label: 'Mexico',
  // },
  // {
  //   value: 'Netherlands',
  //   label: 'Netherlands',
  // },
  // {
  //   value: 'New Zealand',
  //   label: 'New Zealand',
  // },
  // {
  //   value: 'Norway',
  //   label: 'Norway',
  // },
  {
    value: 'Pakistan',
    label: 'Pakistan',
  },
  // {
  //   value: 'Philippines',
  //   label: 'Philippines',
  // },
  // {
  //   value: 'Poland',
  //   label: 'Poland',
  // },
  // {
  //   value: 'Romania',
  //   label: 'Romania',
  // },
  // {
  //   value: 'Slovenia',
  //   label: 'Slovenia',
  // },
  // {
  //   value: 'South Korea',
  //   label: 'South Korea',
  // },
  {
    value: 'Spain',
    label: 'Spain',
  },
  // {
  //   value: 'Sweden',
  //   label: 'Sweden',
  // },
  // {
  //   value: 'Switzerland',
  //   label: 'Switzerland',
  // },
  // {
  //   value: 'United Kingdom',
  //   label: 'United Kingdom',
  // },
];
