/*
 *
 * MyCareProvider constants
 *
 */

export const DEFAULT_ACTION = 'app/MyCareProvider/DEFAULT_ACTION';
export const DESTROY_MATCH = 'app/MyCareProvider/DESTROY_MATCH';
export const DESTROY_MATCH_SUCCESS = 'app/MyCareProvider/DESTROY_MATCH_SUCCESS';
export const DESTROY_MATCH_ERROR = 'app/MyCareProvider/DESTROY_MATCH_ERROR';

export const FETCH_PRACTITIONER = 'app/MyCareProvider/FETCH_PRACTITIONER';
export const FETCH_PRACTITIONER_SUCCESS =
  'app/MyCareProvider/FETCH_PRACTITIONER_SUCCESS';
export const FETCH_PRACTITIONER_ERROR =
  'app/MyCareProvider/FETCH_PRACTITIONER_ERROR';

export const UPLOAD_FILE = 'app/MyCareProvider/UPLOAD_FILE';
export const UPLOAD_FILE_SUCCESS = 'app/MyCareProvider/UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_ERROR = 'app/MyCareProvider/UPLOAD_FILE_ERROR';

export const FETCH_COUPLES_INFO = 'app/MyCareProvider/FETCH_COUPLES_INFO';
export const FETCH_COUPLES_INFO_SUCCESS =
  'app/MyCareProvider/FETCH_COUPLES_INFO_SUCCESS';
export const FETCH_COUPLES_INFO_ERROR =
  'app/MyCareProvider/FETCH_COUPLES_INFO_ERROR';

export const FETCH_CARE_TEAM = 'app/MyCareProvider/FETCH_CARE_TEAM';
export const FETCH_CARE_TEAM_SUCCESS =
  'app/MyCareProvider/FETCH_CARE_TEAM_SUCCESS';
export const FETCH_CARE_TEAM_ERROR = 'app/MyCareProvider/FETCH_CARE_TEAM_ERROR';

export const FETCH_COMPANY = 'app/MyCareProvider/FETCH_COMPANY';
export const FETCH_COMPANY_SUCCESS = 'app/MyCareProvider/FETCH_COMPANY_SUCCESS';
export const FETCH_COMPANY_ERROR = 'app/MyCareProvider/FETCH_COMPANY_ERROR';

export const ENTER_PARTNER_CODE = 'app/MyCareProvider/ENTER_PARTNER_CODE';
export const ENTER_PARTNER_CODE_SUCCESS =
  'app/MyCareProvider/ENTER_PARTNER_CODE_SUCCESS';
export const ENTER_PARTNER_CODE_ERROR =
  'app/MyCareProvider/ENTER_PARTNER_CODE_ERROR';
