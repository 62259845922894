import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectPrivacyConsentsDomain = (state) =>
  state.get('privacyConsents', initialState);

const makeSelectPrivacyConsents = () =>
  createSelector(selectPrivacyConsentsDomain, (substate) => substate.toJS());

export default makeSelectPrivacyConsents;
export { selectPrivacyConsentsDomain };
