import { defineMessages } from 'react-intl';

export const scope = 'app.containers.UpdatedPrivacyConsents';
export default defineMessages({
  updatedPC: {
    defaultMessage: 'Updated Privacy Policy',
  },
  lastRevised: {
    defaultMessage: 'Last Revised:',
  },
  accept: {
    defaultMessage: 'Accept',
  },
  decline: {
    defaultMessage: 'Decline',
  },
  pleaseRead: {
    defaultMessage: 'Please read the following privacy policy.',
  },
  contactSupport: {
    defaultMessage:
      'If you have any questions, please contact {email} or click on the green icon at the bottom right of your screen to chat with a live agent.',
    tagName: 'p',
  },
  mustAccept: {
    defaultMessage:
      'You must accept the revised Privacy Policy in order to continue using Inkblot.',
  },
  goBack: {
    defaultMessage: 'Go back',
  },
  logout: {
    defaultMessage: 'Log out',
  },
  tncCheckBoxErrorMessages: {
    defaultMessage: 'Please indicate your agreement by checking the box. ',
  },
});
