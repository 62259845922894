import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import SpinnerIcon from 'images/FailedPayments/spinner-pink.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 24px;
  text-align: center;

  h2 {
    color: #102146;
    font-family: 'Barlow';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
  }
`;

const Processing = () => (
  <Wrapper>
    <div>
      <h2>
        <FormattedMessage defaultMessage="Processing your payment, please do not leave this page until this has been completed." />
      </h2>
    </div>
    <img alt="alert-icon" src={SpinnerIcon} width={56} />
  </Wrapper>
);

export default Processing;
