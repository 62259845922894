/**
 *
 * LanguageButton
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import messages from './messages';
import { mediumBlue } from '../../global-styles';

const StyledLanuageButton = styled.button`
  width: 80px;
  height: 65px;
  margin: 10px;
  padding: 8px 0 9px 0;
  border-radius: 4px;
  box-shadow: ${(props) =>
    props.checked
      ? '0 0 10px 0 rgba(99, 140, 177, 0.2)'
      : '0 0 10px 0 rgba(99, 140, 177, 0.1)'};
  border: ${(props) =>
    props.checked
      ? `solid 2px ${mediumBlue}`
      : 'solid 1px rgba(46, 95, 202, 0.1)'};
  background-color: #ffffff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  .lb-label {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    color: #0f2045;
  }
  &:enabled {
    &:focus,
    &:hover {
      box-shadow: 0 0 10px 0 rgba(99, 150, 177, 0.4);
      outline: none;
    }
  }
`;

const languages = [
  {
    code: 'English',
    label: <FormattedMessage {...messages.English} />,
  },
  {
    code: 'French',
    label: <FormattedMessage {...messages.French} />,
  },
  {
    code: 'Arabic',
    label: <FormattedMessage {...messages.Arabic} />,
  },
  {
    code: 'Bengali',
    label: <FormattedMessage {...messages.Bengali} />,
  },
  {
    code: 'Bosnian',
    label: <FormattedMessage {...messages.Bosnian} />,
  },
  {
    code: 'Cantonese',
    label: <FormattedMessage {...messages.Cantonese} />,
  },
  {
    code: 'Croatian',
    label: <FormattedMessage {...messages.Croatian} />,
  },
  {
    code: 'Farsi',
    label: <FormattedMessage {...messages.Farsi} />,
  },
  {
    code: 'Filipino',
    label: <FormattedMessage {...messages.Filipino} />,
  },
  {
    code: 'Greek',
    label: <FormattedMessage {...messages.Greek} />,
  },
  {
    code: 'German',
    label: <FormattedMessage {...messages.German} />,
  },
  {
    code: 'Hebrew',
    label: <FormattedMessage {...messages.Hebrew} />,
  },
  {
    code: 'Hindi',
    label: <FormattedMessage {...messages.Hindi} />,
  },
  {
    code: 'Hungarian',
    label: <FormattedMessage {...messages.Hungarian} />,
  },
  {
    code: 'Italian',
    label: <FormattedMessage {...messages.Italian} />,
  },
  {
    code: 'Japanese',
    label: <FormattedMessage {...messages.Japanese} />,
  },
  {
    code: 'Mandarin',
    label: <FormattedMessage {...messages.Mandarin} />,
  },
  {
    code: 'Polish',
    label: <FormattedMessage {...messages.Polish} />,
  },
  {
    code: 'Portuguese',
    label: <FormattedMessage {...messages.Portuguese} />,
  },
  {
    code: 'Punjabi',
    label: <FormattedMessage {...messages.Punjabi} />,
  },
  {
    code: 'Russian',
    label: <FormattedMessage {...messages.Russian} />,
  },
  {
    code: 'Serbian',
    label: <FormattedMessage {...messages.Serbian} />,
  },
  {
    code: 'Spanish',
    label: <FormattedMessage {...messages.Spanish} />,
  },
  { code: 'Urdu', label: <FormattedMessage {...messages.Urdu} /> },
  { code: 'ASL', label: <FormattedMessage {...messages.ASL} /> },
  { code: 'Macedonian', label: <FormattedMessage {...messages.Macedonian} /> },
  { code: 'Somali', label: <FormattedMessage {...messages.Somali} /> },
  { code: 'Marathi', label: <FormattedMessage {...messages.Marathi} /> },
  {
    code: 'Other',
    label: <FormattedMessage {...messages.Other} />,
  },
];
/* eslint-enable prettier/prettier */

const getLanguage = (code) => {
  const len = languages.length;

  for (let i = 0; i < len; i += 1) {
    if (languages[i].code === code) {
      return languages[i];
    }
  }

  return { code, flag: null, label: code };
};

/* eslint-disable react/prefer-stateless-function */
class LanguageButton extends React.PureComponent {
  render() {
    const { code, checked, handleClick, disabled, id } = this.props;
    const lang = getLanguage(code);

    return (
      <StyledLanuageButton
        id={id}
        type="button"
        checked={checked}
        disabled={disabled}
        onClick={() => handleClick(code, checked)}
      >
        <div className="lb-label">{lang.label}</div>
      </StyledLanuageButton>
    );
  }
}

LanguageButton.propTypes = {
  code: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
};

LanguageButton.defaultProps = {
  handleClick: () => {},
  checked: false,
  disabled: false,
};

export default LanguageButton;
