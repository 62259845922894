import React from 'react';
import styled from 'styled-components';
import Background from '../SignUp/Background';
import SignInForm from './SignInForm';

const SignInWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: white;
`;
const SignIn = () => (
  <SignInWrapper>
    <SignInForm />
    <Background />
  </SignInWrapper>
);

export default SignIn;
