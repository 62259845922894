import { fromJS } from 'immutable';
import {
  SUBMIT_EMERGENCY_RELIEF_FUND,
  SUBMIT_EMERGENCY_RELIEF_FUND_ERROR,
  SUBMIT_EMERGENCY_RELIEF_FUND_SUCCESS,
} from './constants';

export const initialState = fromJS({
  loading: false,
  error: false,
});

function emergencyReliefFundReducer(state = initialState, action) {
  switch (action.type) {
    case SUBMIT_EMERGENCY_RELIEF_FUND:
      return state.set('loading', true).set('error', false);
    case SUBMIT_EMERGENCY_RELIEF_FUND_SUCCESS:
      return state.set('loading', false);
    case SUBMIT_EMERGENCY_RELIEF_FUND_ERROR:
      return state.set('loading', false).set('error', action.error);
    default:
      return state;
  }
}

export default emergencyReliefFundReducer;
