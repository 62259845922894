// This tracking script is for Meta Pixel Code
export function injectMetaScript(pageLabel) {
  const Id = `Meta-Track-${pageLabel}`;
  // add once only
  if (document.getElementById(Id)) return;

  const MetaScript = document.createElement('script');
  const MetaNoScript = document.createElement('noscript');
  MetaScript.id = Id;
  MetaScript.text = `!function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '790096232497506');
  fbq('track', '${pageLabel}');`;
  MetaNoScript.innerHTML = `<img height="1" width="1" style="display:none"
  src="https://www.facebook.com/tr?id=790096232497506&ev=${pageLabel}&noscript=1"
  />`;
  document.head.appendChild(MetaScript);
  document.head.appendChild(MetaNoScript);
}
