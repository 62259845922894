import {
  FETCH_CURRENT_PRIVACY_CONSENTS_SUCCESS,
  FETCH_CURRENT_PRIVACY_CONSENTS_ERROR,
  FETCH_CURRENT_PRIVACY_CONSENTS,
} from './constants';

export function fetchCurrentPrivacyConsents(callback) {
  return {
    type: FETCH_CURRENT_PRIVACY_CONSENTS,
    callback,
  };
}

export function fetchCurrentPrivacyConsentsSuccess(PrivacyConsents) {
  return {
    type: FETCH_CURRENT_PRIVACY_CONSENTS_SUCCESS,
    PrivacyConsents,
  };
}

export function fetchCurrentPrivacyConsentsError(error) {
  return {
    type: FETCH_CURRENT_PRIVACY_CONSENTS_ERROR,
    error,
  };
}
