import React from 'react';
import styled from 'styled-components';

import SVG from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';
import P from 'components/P';
import H3 from 'components/H3';
import SunnySvg from '../../../images/YourMatches/sunny.svg';
import MoonSvg from '../../../images/YourMatches/moon.svg';
import messages from '../messages';
import CheckSvg from '../../../images/YourMatches/check.svg';

const Container = styled.div`
  .fx-row {
    display: flex;
  }
  .wd-80 {
    width: 80px;
  }
  .wd-100 {
    width: 100px;
  }
  .hg-50 {
    height: 50px;
  }
  .hg-60 {
    height: 60px;
  }
  .vh-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bg-grey {
    background-color: rgba(46, 95, 202, 0.02);
  }
  p {
    margin: 0;
  }
`;

const Line = styled.div`
  height: 0;
  border: solid 1px rgba(15, 32, 69, 0.25);
`;
const StyledH3 = styled(H3)`
  margin-bottom: 20px;
`;
const GeneralAvailabilityInfo = ({ data, ...props }) => {
  if (!data) return <></>;
  return (
    <div {...props}>
      <StyledH3>
        <FormattedMessage {...messages.ymAvailability} />
      </StyledH3>
      <Container>
        <div className="fx-row">
          <div className="wd-80 hg-50" />
          <div className="wd-100 hg-50 vh-center">
            <SVG src={SunnySvg} />
          </div>
          <div className="wd-100 hg-50 vh-center">
            <SVG src={MoonSvg} />
          </div>
        </div>
        <div className="fx-row">
          <div className="wd-80 hg-60 vh-center">
            <P>
              <FormattedMessage {...messages.monFri} />
            </P>
          </div>
          <div className="wd-100 hg-60 vh-center">
            {data && data.weekday_day_yn && <SVG src={CheckSvg} />}
          </div>
          <div className="wd-100 hg-60 vh-center bg-grey">
            {data && data.weekday_night_yn && <SVG src={CheckSvg} />}
          </div>
        </div>
        <div className="fx-row">
          <Line className="wd-80" />
          <Line className="wd-100" />
          <Line className="wd-100" />
        </div>
        <div className="fx-row">
          <div className="wd-80 hg-60 vh-center">
            <P>
              <FormattedMessage {...messages.satSun} />
            </P>
          </div>
          <div className="wd-100 hg-60 vh-center">
            {data && data.weekend_day_yn && <SVG src={CheckSvg} />}
          </div>
          <div className="wd-100 hg-60 vh-center bg-grey">
            {data && data.weekend_night_yn && <SVG src={CheckSvg} />}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default GeneralAvailabilityInfo;
