/*
 *
 * Session actions
 *
 */

import {
  FETCH_UPCOMING_APPOINTMENTS,
  FETCH_UPCOMING_APPOINTMENTS_SUCCESS,
  FETCH_UPCOMING_APPOINTMENTS_ERROR,
  FETCH_PREVIOUS_APPOINTMENTS,
  FETCH_PREVIOUS_APPOINTMENTS_SUCCESS,
  FETCH_PREVIOUS_APPOINTMENTS_ERROR,
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_ERROR,
  FETCH_APPOINTMENT_ANALYTICS,
  FETCH_APPOINTMENT_ANALYTICS_SUCCESS,
  FETCH_APPOINTMENT_ANALYTICS_ERROR,
  CANCEL_SESSION,
  CANCEL_SESSION_SUCCESS,
  CANCEL_SESSION_ERROR,
  FETCH_COUPLES_INFO,
  FETCH_COUPLES_INFO_SUCCESS,
  FETCH_COUPLES_INFO_ERROR,
  FETCH_CARE_TEAM,
  FETCH_CARE_TEAM_SUCCESS,
  FETCH_CARE_TEAM_ERROR,
  FETCH_PREVIOUS_RECEIPTS,
  FETCH_PREVIOUS_RECEIPTS_SUCCESS,
  FETCH_PREVIOUS_RECEIPTS_ERROR,
} from './constants';

export function fetchUpcomingAppointments() {
  return {
    type: FETCH_UPCOMING_APPOINTMENTS,
  };
}

export function fetchUpcomingAppointmentsSuccess(appointmentDetails) {
  return {
    type: FETCH_UPCOMING_APPOINTMENTS_SUCCESS,
    appointmentDetails,
  };
}

export function fetchUpcomingAppointmentsError(error) {
  return {
    type: FETCH_UPCOMING_APPOINTMENTS_ERROR,
    error,
  };
}

export function fetchPreviousAppointments() {
  return {
    type: FETCH_PREVIOUS_APPOINTMENTS,
  };
}

export function fetchPreviousAppointmentsSuccess(appointmentDetails) {
  return {
    type: FETCH_PREVIOUS_APPOINTMENTS_SUCCESS,
    appointmentDetails,
  };
}

export function fetchPreviousAppointmentsError(error) {
  return {
    type: FETCH_PREVIOUS_APPOINTMENTS_ERROR,
    error,
  };
}

export function fetchUser() {
  return {
    type: FETCH_USER,
  };
}

export function fetchUserSuccess(user) {
  return {
    type: FETCH_USER_SUCCESS,
    user,
  };
}

export function fetchUserError(error) {
  return {
    type: FETCH_USER_ERROR,
    error,
  };
}

export function fetchAnalytics() {
  return {
    type: FETCH_APPOINTMENT_ANALYTICS,
  };
}

export function fetchAnalyticsSuccess(analytics) {
  return {
    type: FETCH_APPOINTMENT_ANALYTICS_SUCCESS,
    analytics,
  };
}

export function fetchAnalyticsError(error) {
  return {
    type: FETCH_APPOINTMENT_ANALYTICS_ERROR,
    error,
  };
}

export function cancelSession(id, callback) {
  return {
    type: CANCEL_SESSION,
    id,
    callback,
  };
}

export function cancelSessionSuccess(id) {
  return {
    type: CANCEL_SESSION_SUCCESS,
    id,
  };
}

export function cancelSessionError(error) {
  return {
    type: CANCEL_SESSION_ERROR,
    error,
  };
}

export function fetchCouplesInfo() {
  return {
    type: FETCH_COUPLES_INFO,
  };
}

export function fetchCouplesInfoSuccess(couplesInfo) {
  return {
    type: FETCH_COUPLES_INFO_SUCCESS,
    couplesInfo,
  };
}

export function fetchCouplesInfoError(error) {
  return {
    type: FETCH_COUPLES_INFO_ERROR,
    error,
  };
}

export function fetchCareTeam() {
  return {
    type: FETCH_CARE_TEAM,
  };
}

export function fetchCareTeamSuccess(careTeam) {
  return {
    type: FETCH_CARE_TEAM_SUCCESS,
    careTeam,
  };
}

export function fetchCareTeamError(error) {
  return {
    type: FETCH_CARE_TEAM_ERROR,
    error,
  };
}

export function fetchPreviousReceipts(page, callback) {
  return {
    type: FETCH_PREVIOUS_RECEIPTS,
    page,
    callback,
  };
}

export function fetchPreviousReceiptsSuccess(paginatedAppointments) {
  return {
    type: FETCH_PREVIOUS_RECEIPTS_SUCCESS,
    paginatedAppointments,
  };
}

export function fetchPreviousReceiptsError(error) {
  return {
    type: FETCH_PREVIOUS_RECEIPTS_ERROR,
    error,
  };
}
