import styled from 'styled-components';

import { phoneSize } from '../../global-styles';

const Item = styled.div`
  border-top: ${({ divider }) => (divider ? '1px solid #dfe7f7' : 'none')};
  margin: 0 33px;
  padding: 60px 0;
  @media (max-width: ${phoneSize}) {
    margin: 0 33px;
  }
`;

export default Item;
