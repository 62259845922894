import styled from 'styled-components';

import NormalImg from 'components/Img';

const Logo = styled(NormalImg)`
  width: 30px;
  height: 30px;
  margin-right: 10px;
  display: inline-block;
`;

export default Logo;
