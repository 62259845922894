/**
 *
 * ConfirmSignUp
 *
 */

import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import H2 from 'components/H2';
import P from 'components/P';
import messages from './messages';

const ConfirmSignUpWrapper = styled.div`
  background-color: #ffffff;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  width: 66%;
  padding: 60px;
  margin: 0 auto;
  margin-top: 200px;
`;

function ConfirmSignUp() {
  return (
    <ConfirmSignUpWrapper>
      <H2>
        <FormattedMessage {...messages.confirmAccount} />
      </H2>
      <P>
        <FormattedMessage {...messages.alreadyConfirmed} />{' '}
        <a
          href="https://ink.inkblottherapy.com/?signin=true"
          style={{ color: '#2e5fca' }}
        >
          <FormattedMessage {...messages.signIn} />
        </a>
      </P>
      <P>
        <FormattedMessage {...messages.sentConfirmation} />{' '}
      </P>
    </ConfirmSignUpWrapper>
  );
}

ConfirmSignUp.propTypes = {};

export default ConfirmSignUp;
