import React from 'react';
import Body from 'components/Body';

import { FormattedMessage } from 'react-intl';
import H3 from 'components/H3';
import messages from '../messages';
import { translations } from '../translations';

const getTranslations = (code) => {
  for (let i = 0; i < translations.length; i += 1) {
    if (translations[i].code === code) {
      return translations[i].label;
    }
  }

  return <span>{code}</span>;
};

const IdentitiesInfo = ({ data, ...props }) => {
  const allIdentities = [];

  const Identification = data.provider_self_identification;
  const races = Identification && Identification.display_race_yn;
  const displayGender =
    Identification && Identification.display_gender_identity_yn;
  const isLgbtq =
    Identification &&
    Identification.display_lgbtq2sp_yn &&
    Identification.lgbtq2sp_yn;
  const isNeurodivergent =
    Identification &&
    Identification.display_neurodivergent_yn &&
    Identification.neurodivergent_yn;
  const isWithDisability =
    Identification &&
    Identification.display_with_disability_yn &&
    Identification.with_disability_yn;

  if (races) {
    const racesArray = data.races.map((race) => race.name);
    const result = racesArray.map((noun) => getTranslations(noun));
    result.map((race) => allIdentities.push(race));
  }
  if (displayGender) {
    const genderArray = data.gender_identities.map((iden) => iden.name);
    const result = genderArray.map((noun) => getTranslations(noun));
    result.map((identity) => allIdentities.push(identity));
  }
  if (isLgbtq) {
    allIdentities.push(<FormattedMessage {...messages.ymLgbtq} />);
  }
  if (isNeurodivergent) {
    allIdentities.push(<FormattedMessage {...messages.ymNeurodivergent} />);
  }
  if (isWithDisability) {
    allIdentities.push(<FormattedMessage {...messages.ymWithDisability} />);
  }

  const allIdentitiesArray = [];

  for (let i = 0; i < allIdentities.length - 1; i += 1) {
    allIdentitiesArray.push(<span key={i}>{allIdentities[i]}, </span>);
  }
  allIdentitiesArray.push(
    <span key="last">{allIdentities[allIdentities.length - 1]}</span>,
  );

  return (
    <div {...props}>
      <H3 style={{ marginBottom: '20px' }}>
        <FormattedMessage {...messages.ymIdentifyAs} />
      </H3>
      {allIdentities.length > 1 && (
        <Body style={{ margin: '20px 0 60px 0' }}>{allIdentitiesArray}</Body>
      )}
    </div>
  );
};

export default IdentitiesInfo;
