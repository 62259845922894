import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectFailedPaymentsPageDomain = (state) =>
  state.get('failedPaymentsPage', initialState);

const makeSelectDefaultCard = () =>
  createSelector(selectFailedPaymentsPageDomain, (substate) =>
    substate.get('defaultCard'),
  );

const makeSelectFailedAppointments = () =>
  createSelector(selectFailedPaymentsPageDomain, (substate) =>
    substate.get('failedAppointments'),
  );

const makeSelectProcessedPayments = () =>
  createSelector(selectFailedPaymentsPageDomain, (substate) =>
    substate.get('processedPayments'),
  );

export {
  makeSelectDefaultCard,
  makeSelectFailedAppointments,
  makeSelectProcessedPayments,
};
