/**
 *
 * PreviousButton
 *
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Body from 'components/Body';
import { mediumBlue } from '../../global-styles';

const Button = styled.button`
  margin-top: 10px;
  cursor: pointer;
`;

function PreviousButton({ label, onClick, style }) {
  return (
    <Button type="button" onClick={onClick} style={style}>
      <Body bold color={mediumBlue}>
        <FormattedMessage {...label} />
      </Body>
    </Button>
  );
}

PreviousButton.propTypes = {
  label: PropTypes.object,
  onClick: PropTypes.func,
  style: PropTypes.object,
};

export default PreviousButton;
