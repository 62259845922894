import { defineMessages } from 'react-intl';

export const scope = 'app.containers.WMHSignUp';

export default defineMessages({
  createAccount: {
    defaultMessage: 'Create an Account',
  },
  alreadyHaveAccount: {
    defaultMessage: 'Already have an account? {link}',
  },
  signIn: {
    defaultMessage: 'Sign In',
  },
  firstName: {
    defaultMessage: 'First Name',
  },
  lastName: {
    defaultMessage: 'Last Name',
  },
  email: {
    defaultMessage: 'Email',
  },
  password: {
    defaultMessage: 'Password',
  },
  retypePassword: {
    defaultMessage: 'Retype Password',
  },
  tcppa: {
    defaultMessage: 'Terms and Conditions & Privacy Policy agreement',
  },
  agreements: {
    defaultMessage:
      'By creating an account, I agree to {tc} and {pp} in their entirety.',
  },
  inkblotTermCondition: {
    defaultMessage: "Inkblot's Terms and Conditions",
  },
  privacyPolicy: {
    defaultMessage: 'Privacy Policy',
  },
  byAgreeing: {
    defaultMessage:
      "By creating an account, I agree to Inkblot's Terms and Conditions and Privacy Policy in their entirety.",
  },
  createOwnAccount: {
    defaultMessage:
      'You should create your own account for accessing Inkblot Therapy and keep your login and password information private. No one else should be able to access your account.',
  },
  communicationPermission: {
    defaultMessage: 'Communication permission',
  },
  communicationConsent: {
    defaultMessage:
      'I consent to receiving articles, product information, or requests for feedback in the future. I understand that I can opt out at any time later.',
  },
  crisisSupport: {
    defaultMessage:
      'If you or someone you know is in crisis and requires immediate support, call 911 or go to your nearest emergency room. Alternately, please contact the Canada Suicide Prevention Service at 1-833-456-4566 (24/7). For residents of Québec, call 1 866 APPELLE (1-866-277-3553). Please know you are not alone. Support is available.',
  },
  gsCaresAboutWMH: {
    defaultMessage: "GreenShield Cares about women's mental health.",
  },
  signUpFree: {
    defaultMessage:
      'Sign up to receive your {value} free hours of talk therapy and complimentary access to iCBT.',
  },
  two: {
    defaultMessage: 'two',
  },
  five: {
    defaultMessage: 'five',
  },
  reliefSignup: {
    defaultMessage:
      'Inkblot by GreenShield is committed to supporting the mental health of Canadians impacted by natural disasters. Sign up to receive your two free hours of virtual counselling.',
  },
  mayBeStruggling: {
    defaultMessage:
      'While the Israel-Hamas war is happening on the other side of the world, for many Canadians it feels close to home.  Whether through personal relationships, affiliations, and/or simply seeing the unfathomable scenes broadcast over social media, we recognize that you may be struggling.',
  },
  standsByThose: {
    defaultMessage:
      "GreenShield stands by those that have been impacted and are committed to supporting your mental health. Sign up to receive two hours of free virtual counselling provided by Inkblot, GreenShield's mental health services.",
  },
});
