import { fromJS } from 'immutable';
import { PHONE_VERIFICATION_DIALOG, UPDATE_TRIGGERS } from './constants';

export const initialState = fromJS({
  [PHONE_VERIFICATION_DIALOG]: false,
});

function dialogTriggerReducer(state = initialState, action) {
  const { dialogName, value } = action.states || {};
  switch (action.type) {
    case UPDATE_TRIGGERS:
      return state.set(dialogName, value);
    default:
      return state;
  }
}

export default dialogTriggerReducer;
