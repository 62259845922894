/*
 *
 * Sessions constants
 *
 */

export const FETCH_UPCOMING_APPOINTMENTS =
  'app/Sessions/FETCH_UPCOMING_APPOINTMENTS';
export const FETCH_UPCOMING_APPOINTMENTS_SUCCESS =
  'app/Sessions/FETCH_UPCOMING_APPOINTMENTS_SUCCESS';
export const FETCH_UPCOMING_APPOINTMENTS_ERROR =
  'app/Sessions/FETCH_UPCOMING_APPOINTMENTS_ERROR';
export const FETCH_PREVIOUS_APPOINTMENTS =
  'app/Sessions/FETCH_PREVIOUS_APPOINTMENTS';
export const FETCH_PREVIOUS_APPOINTMENTS_SUCCESS =
  'app/Sessions/FETCH_PREVIOUS_APPOINTMENTS_SUCCESS';
export const FETCH_PREVIOUS_APPOINTMENTS_ERROR =
  'app/Sessions/FETCH_PREVIOUS_APPOINTMENTS_ERROR';
export const FETCH_USER = 'app/Sessions/FETCH_USER';
export const FETCH_USER_SUCCESS = 'app/Sessions/FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR = 'app/Sessions/FETCH_USER_ERROR';
export const FETCH_APPOINTMENT_ANALYTICS = 'app/Sessions/FETCH_USER';
export const FETCH_APPOINTMENT_ANALYTICS_SUCCESS =
  'app/Sessions/FETCH_APPOINTMENT_ANALYTICS_SUCCESS';
export const FETCH_APPOINTMENT_ANALYTICS_ERROR =
  'app/Sessions/FETCH_APPOINTMENT_ANALYTICS_ERROR';

export const CANCEL_SESSION = 'app/Sessions/CANCEL_SESSION';
export const CANCEL_SESSION_SUCCESS =
  'app/Sessions/CANCEL_SESSION_ANALYTICS_SUCCESS';
export const CANCEL_SESSION_ERROR = 'app/Sessions/CANCEL_SESSION_ERROR';

export const FETCH_COUPLES_INFO = 'app/Sessions/FETCH_COUPLES_INFO';
export const FETCH_COUPLES_INFO_SUCCESS =
  'app/Sessions/FETCH_COUPLES_INFO_SUCCESS';
export const FETCH_COUPLES_INFO_ERROR = 'app/Sessions/FETCH_COUPLES_INFO_ERROR';

export const FETCH_CARE_TEAM = 'app/Sessions/FETCH_CARE_TEAM';
export const FETCH_CARE_TEAM_SUCCESS = 'app/Sessions/FETCH_CARE_TEAM_SUCCESS';
export const FETCH_CARE_TEAM_ERROR = 'app/Sessions/FETCH_CARE_TEAM_ERROR';

export const FETCH_PREVIOUS_RECEIPTS = 'app/Sessions/FETCH_PREVIOUS_RECEIPTS';
export const FETCH_PREVIOUS_RECEIPTS_SUCCESS =
  'app/Sessions/FETCH_PREVIOUS_RECEIPTS_SUCCESS';
export const FETCH_PREVIOUS_RECEIPTS_ERROR =
  'app/Sessions/FETCH_PREVIOUS_RECEIPTS_ERROR';

//
// export const UPDATE_CONTACT_DETAILS = 'app/Preferences/UPDATE_CONTACT_DETAILS';
// export const UPDATE_CONTACT_DETAILS_SUCCESS =
//   'app/Preferences/UPDATE_CONTACT_DETAILS_SUCCESS';
// export const UPDATE_CONTACT_DETAILS_ERROR =
//   'app/Preferences/UPDATE_CONTACT_DETAILS_ERROR';
//
// export const UPDATE_PASSWORD = 'app/Preferences/UPDATE_PASSWORD';
// export const UPDATE_PASSWORD_SUCCESS =
//   'app/Preferences/UPDATE_PASSWORD_SUCCESS';
// export const UPDATE_PASSWORD_ERROR = 'app/Preferences/UPDATE_PASSWORD_ERROR';
//
// export const CREATE_DEPENDANT = 'app/Preferences/CREATE_DEPENDANT';
// export const CREATE_DEPENDANT_SUCCESS =
//   'app/Preferences/CREATE_DEPENDANT_SUCCESS';
// export const CREATE_DEPENDANT_ERROR = 'app/Preferences/CREATE_DEPENDANT_ERROR';
