/**
 *
 * TextInput
 *
 */

import React from 'react';
// import PropTypes from 'prop-types';
import { TextInput, InkblotTheme } from 'inkblot-ui';
import { FormattedMessage } from 'react-intl';

/* eslint-disable react/prefer-stateless-function */
export default function Input(props) {
  return (
    <InkblotTheme>
      <TextInput
        {...props.input}
        inputStyle={{
          display: 'inline-block',
          width: '100%',
          minWidth: 'unset',
          ...props.inputStyle,
        }}
        type={props.type}
        label={props.label ? <FormattedMessage {...props.label} /> : ''}
        value={props.defaultValue || props.input.value}
        error={(props.meta.touched && props.meta.error) || props.error}
        inlineMessage={
          (props.meta.touched && props.meta.error) || props.inlineMessage
        }
        disabled={props.disabled}
        placeholder={props.placeholder}
      />
    </InkblotTheme>
  );
}

TextInput.propTypes = {};
