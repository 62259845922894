/*
 * TermsAndConditions Messages
 *
 * This contains all the text for the TermsAndConditions container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.PrivacyConsents';

export default defineMessages({
  confirmReadTos: {
    defaultMessage:
      'I have read and agree to the privacy policy above in its entirety.',
  },
  loading: {
    defaultMessage: 'Loading...',
  },
});
