import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import H2 from 'components/H2';
import ContentBold from 'components/ContentBold';
import Img from 'components/Img';
import messages from '../messages';
import { tabletSize, phoneSize } from '../../../global-styles';
import MarginWrapper from './MarginWrapper';

const Avatar = styled(Img)`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
`;

const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
  width: 40px;
  height: 20px;
  border-radius: 10px;
  background-image: linear-gradient(to left, #e938cc, #fc587f);
`;

const Title = styled(H2)`
  margin-bottom: 40px;
`;

const OuterWrapper = styled.div`
  margin: 60px 60px;
  @media (max-width: ${phoneSize}) {
    margin: 60px 20px 0 20px;
  }
`;

const ResponsiveWrapperItem = styled.div`
  @media (max-width: ${tabletSize}) {
    width: 100%;
    display: inline-block;
  }
  @media (max-width: ${phoneSize}) {
    width: 100%;
    display: block;
  }
`;

const ResponsiveWrapper = styled.div`
  @media (max-width: ${tabletSize}) {
    display: flex;
  }
  @media (max-width: ${phoneSize}) {
    display: block;
  }
`;

const ChatListing = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 120px;
  margin-bottom: 60px;
  background-color: ${(props) => (props.activeChat ? ' #2e5fca0d' : 'white')};
`;

const ConversationItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-left: 60px;

  @media (max-width: ${phoneSize}) {
    margin-left: 20px;
  }
`;

const NoAvatar = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  color: #fefefe;
  background-color: #4e7bcd;
  text-align: center;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
`;

const renderUserImage = (user) => {
  if (user.avatar && user.avatar.url) {
    return <Avatar src={`${user.avatar.url}`} alt="Headshot of provider" />;
  }
  return (
    <NoAvatar>
      {_.get(user, 'first_name', '').charAt(0).toUpperCase()}
      {_.get(user, 'last_name', '').charAt(0).toUpperCase()}
    </NoAvatar>
  );
};

const renderConversation = (chat, onChatClick, i, activeChat) => (
  <ChatListing
    activeChat={chat.id === activeChat.id}
    key={i}
    style={{ marginBottom: '0rem' }}
  >
    <ConversationItem onClick={() => onChatClick(chat)}>
      {renderUserImage(chat.recipient)}
      <ContentBold>{`${chat.recipient.first_name} ${chat.recipient.last_name}`}</ContentBold>
      {chat.unread_yn ? <Badge /> : null}
    </ConversationItem>
  </ChatListing>
);

const Conversations = (props) => (
  <div>
    <OuterWrapper id="Conversations">
      <MarginWrapper>
        <ResponsiveWrapper>
          <ResponsiveWrapperItem>
            <Title>
              <FormattedMessage {...messages.conversations} />
            </Title>
          </ResponsiveWrapperItem>
        </ResponsiveWrapper>
      </MarginWrapper>
    </OuterWrapper>
    <ResponsiveWrapper>
      <ResponsiveWrapperItem>
        {_.map(props.chats, (chat, i) =>
          renderConversation(chat, props.onChatClick, i, props.activeChat),
        )}
      </ResponsiveWrapperItem>
    </ResponsiveWrapper>
  </div>
);

Conversations.propTypes = {
  onChatClick: PropTypes.func.isRequired,
};

export default Conversations;
