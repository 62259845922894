import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Button from 'components/Button';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import H4 from 'components/H4';
import messages from '../messages';

const Wrapper = styled.div`
  margin-top: 3rem;

  h4 {
    max-width: 620px;
    margin-bottom: 6rem;
  }
`;

class Confirm extends PureComponent {
  render() {
    return (
      <Wrapper>
        <H4>
          <FormattedMessage {...messages.confirmationCopy} />
        </H4>
        <Button
          secondary
          onClick={() => this.props.history.push('/')}
          label={messages.returnToDash}
          style={{ margin: '0' }}
        />
      </Wrapper>
    );
  }
}

Confirm.propTypes = {
  history: PropTypes.object,
};

export default connect()(withRouter(Confirm));
