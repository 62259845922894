export const VERIFY_PHONE_NUMBER = 'app/PhoneNumber/VERIFY_PHONE_NUMBER';
export const VERIFY_PHONE_NUMBER_SUCCESS =
  'app/PhoneNumber/VERIFY_PHONE_NUMBER_SUCCESS';
export const VERIFY_PHONE_NUMBER_ERROR =
  'app/PhoneNumber/VERIFY_PHONE_NUMBER_ERROR';

export const CREATE_PHONE_NUMBER = 'app/PhoneNumber/CREATE_PHONE_NUMBER';
export const CREATE_PHONE_NUMBER_SUCCESS =
  'app/PhoneNumber/CREATE_PHONE_NUMBER_SUCCESS';
export const CREATE_PHONE_NUMBER_ERROR =
  'app/PhoneNumber/CREATE_PHONE_NUMBER_ERROR';
