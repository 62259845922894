/*
 *
 * UpdatedTermsConditions constants
 *
 */

export const UPDATE_PRIVACY_CONSENTS =
  'app/UpdatedPrivacyConsents/UPDATE_PRIVACY_CONSENTS';
export const UPDATE_PRIVACY_CONSENTS_SUCCESS =
  'app/UpdatedPrivacyConsents/UPDATE_PRIVACY_CONSENTS_SUCCESS';
export const UPDATE_PRIVACY_CONSENTS_ERROR =
  'app/UpdatedPrivacyConsents/UPDATE_PRIVACY_CONSENTS_ERROR';
