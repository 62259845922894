import * as React from 'react';
import { MsalProvider } from '@azure/msal-react';
import { parse } from 'query-string';
import PropTypes from 'prop-types';

import { createClient } from './client';

export const Azure = ({ children }) => {
  const { policy_id: policyId } = parse(window.location.search);
  const client = React.useRef(createClient(policyId));
  return <MsalProvider instance={client.current}>{children}</MsalProvider>;
};

Azure.propTypes = {
  children: PropTypes.node.isRequired,
};
