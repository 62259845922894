/*
 *
 * UserInformation constants
 *
 */

export const FETCH_USER = 'app/UserInformation/FETCH_USER';
export const FETCH_USER_SUCCESS = 'app/UserInformation/FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR = 'app/UserInformation/FETCH_USER_ERROR';

export const UPDATE_USER = 'app/UserInformation/UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'app/UserInformation/UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'app/UserInformation/UPDATE_USER_ERROR';

export const UPDATE_EMAIL = 'app/UserInformation/UPDATE_EMAIL';
export const UPDATE_EMAIL_SUCCESS = 'app/UserInformation/UPDATE_EMAIL_SUCCESS';
export const UPDATE_EMAIL_ERROR = 'app/UserInformation/UPDATE_EMAIL_ERROR';

export const CONFIRM_EMAIL = 'app/UserInformation/CONFIRM_EMAIL';
export const CONFIRM_EMAIL_SUCCESS =
  'app/UserInformation/CONFIRM_EMAIL_SUCCESS';
export const CONFIRM_EMAIL_ERROR = 'app/UserInformation/CONFIRM_EMAIL_ERROR';

export const TOGGLE_FREE_SESSION = 'app/UserInformation/TOGGLE_FREE_SESSION';
