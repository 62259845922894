import React, { useState } from 'react';
import styled from 'styled-components';

import Dialog from 'components/Dialog';
import InkAvatar from 'images/MigrationModal/ink-avatar.png';
import GsAvatar from '../../images/MigrationModal/gs-avatar.png';
import ArrowRight from '../../images/MigrationModal/arrow-right.svg';
import { FormattedMessage, injectIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { makeSelectUser } from 'containers/UserInformation/selectors';

const Container = styled.div`
  text-align: center;

  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: 'Gilroy', sans-serif;
`;

const ImgDiv = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 20px;

  .avatar {
    width: 46px;
    height: 46px;
  }

  .svg {
    width: 20px;
    height: 20px;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;

  color: #545959;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.25px;

  p {
    margin-top: 0px;
  }
`;

const InfoText = styled.div`
  color: #09709c;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 8px;

  a {
    color: inherit;
    text-decoration: underline;
  }
`;

const TextLink = styled.a`
  color: #09709c;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 8px;
  text-decoration: underline;
`;

const Header = styled.h1`
  color: #303333;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  margin-bottom: 8px;

  font-family: inherit;
`;

const ButtonDiv = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  margin-top: 36px;
`;

const ActionButton = styled.button`
  border-radius: 999px;
  border: 1px solid var(--alias-color-border-base, #d0dbdb);
  width: 100%;
  color: #545959;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  height: 48px;
`;

const ActionButtonGS = styled.button`
  border-radius: 999px;
  background: #00544e;
  width: 100%;
  color: #d9f0fa;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  height: 48px;
  a {
    text-decoration: none;
    color: inherit;
  }
`;

const MigrationModal = ({ intl, user }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleRemindMeLater = () => {
    setIsOpen(false);
  };

  if (!user.get('is_d2c')) return <></>;

  return (
    <Dialog
      maxWidth="698px"
      open={isOpen}
      onRequestClose={handleRemindMeLater}
      paddingBottom={'0px'}
    >
      <Container>
        <ImgDiv>
          <img className="avatar" alt="inkblot logo" src={InkAvatar} />
          <img className="svg" alt="arrow pointing right" src={ArrowRight} />
          <img className="avatar" alt="greenshield plus logo" src={GsAvatar} />
        </ImgDiv>
        <InfoText>
          <FormattedMessage defaultMessage="Attention" />
        </InfoText>
        <Header>
          <FormattedMessage defaultMessage="Transition to GreenShield+" />
        </Header>
        <Body>
          <p>
            <FormattedMessage
              defaultMessage={
                'We are transitioning to GreenShield+ to give you a better, easier way to access the mental health services that you have used on Inkblot.'
              }
            />
          </p>
          <p>
            <FormattedMessage defaultMessage="By December 31st 2024, Inkblot will no longer be available, but you will be able to access your care provider and view your appointments on our new platform." />
          </p>
          <p>
            <FormattedMessage defaultMessage="You can choose to sign up on GreenShield+ now and begin exploring. We're excited for you to get started!" />
          </p>
        </Body>
        <TextLink
          href={
            intl.locale === 'fr'
              ? 'https://inkblot.zendesk.com/hc/fr-ca/articles/25450855312660-Passage-de-Th%C3%A9rapie-Inkblot-%C3%A0-GreenShield-Sant%C3%A9-mentale'
              : 'https://inkblot.zendesk.com/hc/en-us/articles/25450855312660-Moving-from-Inkblot-Therapy-to-GreenShield-Mental-Health'
          }
        >
          <FormattedMessage defaultMessage="Learn more about this change" />
        </TextLink>
        <ButtonDiv>
          <ActionButton onClick={handleRemindMeLater}>
            <FormattedMessage defaultMessage="Remind me later" />
          </ActionButton>
          <ActionButtonGS>
            <a
              href={`https://app.greenshieldplus.ca/${
                intl.locale === 'fr' ? 'fr' : 'en'
              }/sign-up`}
            >
              <FormattedMessage defaultMessage="Go to GreenShield+" />
            </a>
          </ActionButtonGS>
        </ButtonDiv>
      </Container>
    </Dialog>
  );
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});
const withConnect = connect(mapStateToProps);

export default compose(withConnect)(injectIntl(MigrationModal));
