/*
 *
 * Misc actions
 *
 */

import {
  FETCH_STATES,
  FETCH_STATES_ERROR,
  FETCH_STATES_SUCCESS,
} from './constants';

export function fetchStates() {
  return {
    type: FETCH_STATES,
  };
}

export function fetchStatesSuccess(states) {
  return {
    type: FETCH_STATES_SUCCESS,
    states,
  };
}

export function fetchStatesError(error) {
  return {
    type: FETCH_STATES_ERROR,
    error,
  };
}
