/**
 *
 * ErrorDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import Button from 'components/Button';
import Body from 'components/Body';
import Dialog from 'components/Dialog';

import messages from './messages';

const Container = styled.div`
  text-align: center;

  h2,
  h4 {
    width: 90%;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  h2 {
    margin-bottom: 30px;
  }
`;

const ErrorDialog = ({ onClose, message, open }) => (
  <Dialog onRequestClose={onClose} open={open}>
    <Container>
      <Body bold>{message}</Body>
      <Button
        singleClick={false}
        label={messages.close}
        onClick={onClose}
        tertiary
      />
    </Container>
  </Dialog>
);

ErrorDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  open: PropTypes.bool.isRequired,
};

ErrorDialog.defaultProps = {
  message: <FormattedMessage {...messages.generalErrorMessage} />,
};

export default ErrorDialog;
