import { defineMessages } from 'react-intl';

export const scope = 'app.containers.SignIn';

export default defineMessages({
  title: {
    defaultMessage: 'Sign In',
  },
  signup: {
    defaultMessage: 'Sign Up',
  },
  dontHaveAcc: {
    defaultMessage: "Don't have an account? {link}",
  },
  next: {
    defaultMessage: 'Next',
  },
  country: {
    defaultMessage: 'Country',
  },
  email: {
    defaultMessage: 'Email Address',
  },
  password: {
    defaultMessage: 'Password',
  },
  forgotPassword: {
    defaultMessage: 'I forgot my password',
  },
  enterAnEmailWithExample: {
    defaultMessage: 'Please enter an email address - Example, user@example.com',
  },
  crisisAlert: {
    defaultMessage:
      'If you or someone you know is in crisis and requires immediate support, call 911 or go to your nearest emergency room. Alternately, please contact the Canada Suicide Prevention Service at 1-833-456-4566 (24/7). For residents of Québec, call 1 866 APPELLE (1-866-277-3553). Please know you are not alone. Support is available.',
  },
  america: {
    defaultMessage: 'United States',
  },
  ifYouAreACounsellor: {
    defaultMessage: 'If you are a counsellor, sign in here',
  },
  required: {
    defaultMessage: 'This is a required field',
  },
});
