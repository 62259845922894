import styled from 'styled-components';
import PropTypes from 'prop-types';
import React from 'react';
import NormalImg from 'components/Img';
import Ximg from '../../images/Header/X.svg';
import { phoneSize } from '../../global-styles';

const MobileButton = styled.button`
  cursor: pointer;
  display: none;
  @media (max-width: ${phoneSize}) {
    display: ${(props) => (props.open ? 'none' : 'block')};
    margin-top: -65px;
    margin-left: 190px;
    margin-bottom: 47px;
  }
`;

const XIcon = styled(NormalImg)`
  width: 12px;
  height: 12px;
`;

const X = (props) => (
  <MobileButton onClick={props.toggleNavSide}>
    <XIcon src={Ximg} alt="X" />
  </MobileButton>
);

X.propTypes = {
  toggleNavSide: PropTypes.func.isRequired,
};

export default X;
