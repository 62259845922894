import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import ContentBold from 'components/ContentBold';
import messages from '../messages';
import { tabletSize, phoneSize } from '../../../global-styles';
import Logo from '../Logo';

const Container = styled.div`
  display: flex;
  border-radius: 20px;
  box-shadow: 0 0 20px 0 rgba(99, 150, 177, 0.2);
  min-height: 700px;
  max-width: 945px;
  margin: 0 auto;
  margin-top: 20px;
  @media (max-width: ${tabletSize}) {
    flex-direction: column;
  }
`;

const Placeholder = styled.div`
  width: 130px;
  height: 130px;
  margin: 0 auto;
  border-radius: 50%;
  display: block;
  background-color: #ecf2f4;
  margin-bottom: 20px;
  @media (max-width: ${tabletSize}) {
    width: 60px;
    height: 60px;
    margin-right: 20px;
    margin: 0;
  }
`;

const Avatar = styled.img`
  width: 130px;
  height: 130px;
  border-radius: 50%;
  margin: 0 auto;
  display: block;
  margin-bottom: 20px;
  object-fit: cover;

  @media (max-width: ${tabletSize}) {
    width: 60px;
    height: 60px;
    margin: 0;
    margin-right: 20px;
  }
`;

const CounsellorMessage = styled(ContentBold)`
  margin: 0 auto;
  max-width: 130px;
  text-align: center;
  @media (max-width: ${tabletSize}) {
    max-width: 200px;
    margin: 0;
  }
`;

const AvatarMessageContainer = styled.div`
  @media (max-width: ${tabletSize}) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const PanelContainer = styled.div`
  :first-of-type {
    background-color: #ffffff;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    width: 30%;
    padding: 20px;
  }

  :last-of-type {
    background-color: #fafafa;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 70%;
  }

  @media (max-width: ${tabletSize}) {
    :first-of-type {
      width: 100%;
      min-height: 175px;
      border-bottom-left-radius: 0px;
      border-top-right-radius: 20px;
      padding: 40px;
    }

    :last-of-type {
      width: 100%;
      min-height: 525px;
      border-top-right-radius: 0px;
      border-bottom-left-radius: 20px;
    }
  }

  @media (max-width: ${phoneSize}) {
    :first-of-type {
      padding: 20px;
    }
  }
`;

function Panel({ children, openExitDialog, firstName, avatarUrl }) {
  return (
    <Container>
      <PanelContainer>
        <Logo openExitDialog={openExitDialog} />
        <AvatarMessageContainer>
          {avatarUrl ? <Avatar alt="" src={`${avatarUrl}`} /> : <Placeholder />}
          <CounsellorMessage bold>
            <FormattedMessage
              {...messages.confirmMeet}
              values={{ name: firstName }}
            />
          </CounsellorMessage>
        </AvatarMessageContainer>
      </PanelContainer>
      <PanelContainer>{children}</PanelContainer>
    </Container>
  );
}

Panel.propTypes = {
  children: PropTypes.node,
  openExitDialog: PropTypes.func,
  firstName: PropTypes.string,
  avatarUrl: PropTypes.string,
};

export default Panel;
