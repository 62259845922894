import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { InkblotTheme, Button } from 'inkblot-ui';
import ChatContents from '../ChatContents';
import messages from '../messages';
import PaperPlane from '../../../images/Messaging/paper-plane.svg';
import { mediumBlue, phoneSize } from '../../../global-styles';

const ChatWrapper = styled.div`
  width: 100%;
  min-height: 650px;
  border-radius: 20px;
  border: solid 1px #dfe7f7;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 20px 20px 20px;
  margin-bottom: 40px;
  @media (max-width: ${phoneSize}) {
    padding: 10px 10px 5px 10px;
  }
`;

const ChatInput = styled.textarea`
  background-color: rgba(240, 242, 245, 0.79);
  border-radius: 6px;
  min-height: 142px;
  padding: 20px 140px 20px 20px;
  resize: none;
  height: 22%;
  width: 100%;
  outline: none;
  @media (max-width: ${phoneSize}) {
    padding: 15px 60px 15px 15px;
  }
`;

const ChatFormWrapper = styled.div`
  position: relative;
  margin-top: 10px;
  .chat-big-send {
    display: inline-block;
    :focus {
      outline: 5px solid ${mediumBlue};
    }
  }
  .chat-send {
    display: none;
  }
  @media (max-width: ${phoneSize}) {
    .chat-big-send {
      display: none;
    }
    .chat-send {
      background-color: #fff;
      height: 40px;
      width: 40px;
      box-shadow: 0 0 10px 0 rgba(99, 140, 177, 0.2);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 10px;
      right: 10px;
      position: absolute;

      svg {
        margin-top: -4px;
        margin-left: -4px;
      }
    }
  }
`;

class ChatWindow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
    };
  }

  handleFormSubmit = () => {
    if (this.state.message !== '') {
      this.props.handleNewMessage({ message: this.state.message });
      this.setState({
        message: '',
      });
    }
  };

  handleEnterSubmit = (event) => {
    if (this.state.message !== '' && event.key === 'Enter' && !event.shiftKey) {
      this.props.handleNewMessage({
        message: this.state.message,
        sensitive: true,
      });
      this.setState({
        message: '',
      });
      event.preventDefault();
    }
  };

  onChange = (event) => {
    this.setState({ message: event.target.value });
  };

  render() {
    const { allowChatYn, chatMessages } = this.props;
    const placeHolder = allowChatYn
      ? { ...messages.typeAMessage }
      : { ...messages.selectCounsellor };
    return (
      <div>
        <ChatWrapper>
          <ChatContents chatMessages={chatMessages} />
          <ChatFormWrapper>
            <FormattedMessage {...placeHolder}>
              {(placeholder) => (
                <ChatInput
                  name="message"
                  value={this.state.message}
                  onChange={this.onChange}
                  disabled={!allowChatYn}
                  placeholder={placeholder}
                  onKeyDown={this.handleEnterSubmit}
                  style={{ fontFamily: 'Source Sans Pro' }}
                />
              )}
            </FormattedMessage>
            <InkblotTheme>
              <Button
                label={<FormattedMessage {...messages.send} />}
                tertiary
                style={{ top: 20, right: 20, position: 'absolute' }}
                className="chat-big-send"
                onClick={this.handleFormSubmit}
                onKeyPress={(event) => this.handleEnterSubmit(event)}
                singleClick={false}
                disabled={!allowChatYn}
              />
            </InkblotTheme>
            <div
              className="chat-send"
              style={allowChatYn ? {} : { display: 'none' }}
              onClick={this.handleFormSubmit}
              role="presentation"
            >
              <SVG src={PaperPlane} />
            </div>
          </ChatFormWrapper>
        </ChatWrapper>
      </div>
    );
  }
}
ChatWindow.propTypes = {
  allow_messaging_yn: PropTypes.bool,
};

export default ChatWindow;
