import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ContentBoldBlue from 'components/ContentBoldBlue';
import InkblotLogo from '../../images/Matching/logo.png';

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  margin-bottom: 40px;
  width: 100%;
  cursor: pointer;

  img {
    height: 30px;
    margin-right: 10px;
  }
`;

function Logo({ openExitDialog }) {
  return (
    <Container onClick={openExitDialog}>
      <img alt="" src={InkblotLogo} />
      <ContentBoldBlue>Inkblot</ContentBoldBlue>
    </Container>
  );
}

Logo.propTypes = {
  openExitDialog: PropTypes.func,
};

export default Logo;
