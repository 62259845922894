import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import H1 from 'components/H1';
import ContentBold from 'components/ContentBold';
import Button from 'components/Button';
import Content from 'components/Content';
import Spinner from 'components/DataLoadingSpinner/Spinner';
import TermsAndConditions from 'containers/TermsAndConditions';
import PreviousButton from 'components/PreviousButton';

import { connect } from 'react-redux';
import Container from './Container';
import ButtonWrapper from './ButtonWrapper';
import messages from '../messages';

function InformedConsent({
  goToPrevPage,
  handleSubmit,
  loading,
  submitLabel,
  informedConsent,
}) {
  return (
    <Container>
      <H1>
        <FormattedMessage {...messages.confirmConfirmation} />
      </H1>
      <ContentBold style={{ marginBottom: '10px' }}>
        <FormattedMessage {...messages.confirmInformedConsent} />
      </ContentBold>
      <Content style={{ marginBottom: '20px' }}>
        <FormattedMessage {...messages.confirmPleaseReadInformedConsent} />
      </Content>
      <form onSubmit={handleSubmit}>
        <TermsAndConditions consentType="InformedConsent" />
        <ButtonWrapper>
          {loading && <Spinner />}
          <Button
            label={submitLabel}
            primary
            type="submit"
            style={{ margin: '0 5px' }}
            disabled={
              loading ||
              !(
                informedConsent &&
                informedConsent.get('by_signing') &&
                informedConsent.get('understand_all')
              )
            }
          />
        </ButtonWrapper>
        <PreviousButton label={messages.previous} onClick={goToPrevPage} />
      </form>
    </Container>
  );
}

InformedConsent.propTypes = {
  goToPrevPage: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  submitLabel: PropTypes.object,
};
const mapStateToProps = (state) => ({
  informedConsent: state.getIn(['form', 'TermsAndConditionsForm', 'values']),
});
export default connect(mapStateToProps)(InformedConsent);
