import { takeLatest, put, call } from 'redux-saga/effects';

import { API_URL } from 'utils/environment';
import { get } from 'utils/request';

import { fetchDefaultCardSuccess, fetchDefaultCardError } from './actions.js';
import { FETCH_DEFAULT_CARD } from './constants.js';

function* fetchDefaultCard() {
  const requestURL = `${API_URL}/stripe_customers`;

  try {
    const card = yield call(get, requestURL);
    yield put(fetchDefaultCardSuccess(card));
  } catch (error) {
    yield put(fetchDefaultCardError(error));
  }
}

export default function* failedPaymentsModalSaga() {
  yield takeLatest(FETCH_DEFAULT_CARD, fetchDefaultCard);
}
