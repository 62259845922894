import React, { useState, useCallback } from 'react';
import { Modal as AntDModal } from 'antd';
import './modal.css';
import Success from './Success';
import PartialSuccess from './PartialSuccess';
import Processing from './Processing';
import Failure from './Failure';

// Custom Hook
const usePaymentsModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);

  const ModalComponent = ({
    handleTotalAmount,
    paymentState,
    bookingPathInfo,
  }) => {
    const handleContent = () => {
      switch (paymentState) {
        case 'processing':
          return <Processing />;
        case 'success':
          return (
            <Success
              handleTotalAmount={handleTotalAmount}
              closeModal={closeModal}
              bookingPathInfo={bookingPathInfo}
            />
          );
        case 'partialSuccess':
          return <PartialSuccess closeModal={closeModal} />;
        case 'failure':
          return <Failure closeModal={closeModal} />;
        default:
          return <></>;
      }
    };

    return (
      <AntDModal
        visible={isOpen}
        footer={null}
        onCancel={paymentState !== 'success' ? closeModal : () => {}}
        closable={false}
        className="payment-processing-modal"
        width={600}
        maskClosable={false}
        centered
      >
        {handleContent()}
      </AntDModal>
    );
  };

  return [ModalComponent, openModal, closeModal];
};

export default usePaymentsModal;
