/**
 *
 * Failed Payments Page
 *
 */

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { makeSelectUser } from 'containers/UserInformation/selectors';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { fetchDefaultCard } from './actions.js';
import { makeSelectDefaultCard } from './selectors.js';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import saga from './saga.js';
import reducer from './reducer.js';
import Modal from 'components/Modal/index.js';

const ActionButton = styled.button`
  display: flex;
  height: 48px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  background: ${(props) =>
    props.theme === 'green'
      ? 'linear-gradient(270deg, #25A37C -0.23%, #39D7C3 100%)'
      : '#fff'};
  color: ${(props) => (props.theme === 'green' ? '#F5FAFA' : '#2954c3')};
  padding: 8px 48px;
  white-space: normal;
  font-size: 16px;
  font-family: 'Barlow';
  font-weight: 600;
  line-height: 20px;
  box-shadow: ${(props) =>
    props.theme !== 'green' && '0px 1px 9px 0px #dde5ef'};
  width: 274px;
  a {
    color: inherit;
  }
  a:hover {
    text-decoration: none;
  }
`;

const ButtonDiv = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

const Header = styled.div`
  color: var(--text-h2, #193269);
  font-family: 'Barlow';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 144.444% */
  margin-bottom: 24px;
  max-width: 400px;
`;

const Description = styled.div`
  color: var(--text-body, #000);
  text-align: center;
  /* Body 2/regular */
  font-family: 'Source Sans Pro';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
  margin-bottom: 24px;
`;

const Wrapper = styled.div`
  width: 574px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FailedPaymentsModal = ({
  defaultCard,
  fetchDefaultCard,
  open,
  setOpen,
  history,
  bookingPathInfo,
}) => {
  useEffect(() => {
    fetchDefaultCard();
  });
  const lastFourDigits = defaultCard && defaultCard.toJS().last_4_digits;

  const handleNavigate = () => {
    history.push({
      pathname: '/failed_payments',
      state: { bookingPathInfo },
    });
    setOpen(false);
  };

  return (
    <Modal
      isModalOpen={open}
      setIsModalOpen={() => setOpen(false)}
      title={
        <Header>
          <FormattedMessage defaultMessage="Update payment details and pay outstanding balance" />
        </Header>
      }
      description={
        <Description>
          <FormattedMessage
            values={{
              lastFourDigits: lastFourDigits,
            }}
            defaultMessage="We were unable to charge your credit card ending {lastFourDigits} for your counselling appointment. To continue booking your next appointment, update payment details and pay your outstanding balance."
          />
        </Description>
      }
    >
      <Wrapper>
        <ButtonDiv>
          <ActionButton theme="green" onClick={handleNavigate}>
            <FormattedMessage defaultMessage="Update payment details" />
          </ActionButton>
          <ActionButton onClick={() => setOpen(false)}>
            <FormattedMessage defaultMessage="Close" />
          </ActionButton>
        </ButtonDiv>
      </Wrapper>
    </Modal>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    fetchDefaultCard: () => dispatch(fetchDefaultCard()),
  };
}

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  defaultCard: makeSelectDefaultCard(),
});

const withReducer = injectReducer({ key: 'failedPaymentsModal', reducer });
const withSaga = injectSaga({ key: 'failedPaymentsModal', saga });

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(withRouter(FailedPaymentsModal));
