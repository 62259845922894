import { takeLatest, put, call } from 'redux-saga/effects';

import { API_URL } from '../../utils/environment';
import { get } from '../../utils/request';
import {
  fetchCurrentPrivacyConsentsSuccess,
  fetchCurrentPrivacyConsentsError,
} from './actions';
import { FETCH_CURRENT_PRIVACY_CONSENTS } from './constants';

function* fetchCurrentPrivacyConsents({ callback }) {
  const requestURL = `${API_URL}/privacy_consents/current`;

  try {
    const data = yield call(get, requestURL);
    yield put(fetchCurrentPrivacyConsentsSuccess(data));
    if (callback) callback(data);
  } catch (error) {
    yield put(fetchCurrentPrivacyConsentsError(error));
    if (callback) callback(null, error);
  }
}

// Individual exports for testing
export default function* termsAndConditionsSaga() {
  yield takeLatest(FETCH_CURRENT_PRIVACY_CONSENTS, fetchCurrentPrivacyConsents);
}
