import React from 'react';
import styled from 'styled-components';
import InkblotGSLogoEN from 'images/SignUp/Inkblot_GreenShield_Logo_EN-Colour.png';
import InkblotGSLogoFR from 'images/SignUp/Inkblot_GreenShield_Logo_FR-Colour.png';
import PropTypes from 'prop-types';
import {
  minTabletSize,
  phoneSize,
  teenyWeenySize,
  eAZoom400,
} from '../../global-styles';

const Container = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  background-color: white;
  z-index: 999;
  justify-content: space-between;
  width: 100%;
  min-height: 90px;
  border-bottom: solid 1px #c9d4d4;
  align-items: center;
  img {
    width: 150px;
    min-height: 50px;
  }

  @media (min-width: ${teenyWeenySize}) {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    align-items: center;
    img {
      width: 175px;
      min-height: 50px;
    }
  }
  @media (min-width: ${phoneSize}) {
    padding-left: 10%;
    padding-right: 10%;
    width: 100%;
    img {
      width: 200px;
      min-height: 50px;
    }
  }
  @media (min-width: ${minTabletSize}) {
    align-items: left;
    padding-left: 13%;
    padding-right: 13%;
  }

  @media screen and (max-width: ${eAZoom400}) and (min-device-width: ${phoneSize}) {
    padding-left: 5%;
    padding-right: 5%;
    width: 100%;
    align-items: center;
    input {
      max-width: 300px !important;
    }
  }
`;

const TopBar = ({ language }) => (
  <Container>
    {language === 'fr' ? (
      <img src={InkblotGSLogoFR} alt="inkblot_icon" />
    ) : (
      <img src={InkblotGSLogoEN} alt="inkblot_icon" />
    )}
    {language === 'fr' ? (
      <img
        src="https://cdn.inkblottherapy.com/static/images/gsc-wmh/FRE_GreenShield_Communautaire_Logo_Pink_RGB.png"
        alt="GreenShield Communautaire se préoccupe de la santé mentale des femmes"
      />
    ) : (
      <img
        src="https://cdn.inkblottherapy.com/static/images/gsc-wmh/GreenShield_Cares_Logo_Pink_RGB.png"
        alt="GreenShieldCares About Women's Mental Health Logo"
      />
    )}
  </Container>
);

TopBar.propTypes = {
  language: PropTypes.string,
};

TopBar.defaultProps = {
  language: 'en',
};

export default TopBar;
