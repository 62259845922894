import {
  SUBMIT_EMERGENCY_RELIEF_FUND,
  SUBMIT_EMERGENCY_RELIEF_FUND_ERROR,
  SUBMIT_EMERGENCY_RELIEF_FUND_SUCCESS,
} from './constants';

export function submitEmergencyReliefFund(values, callback) {
  return {
    values,
    callback,
    type: SUBMIT_EMERGENCY_RELIEF_FUND,
  };
}

export function submitEmergencyReliefFundSuccess(response) {
  return {
    response,
    type: SUBMIT_EMERGENCY_RELIEF_FUND_SUCCESS,
  };
}

export function submitEmergencyReliefFundError(error) {
  return {
    error,
    type: SUBMIT_EMERGENCY_RELIEF_FUND_ERROR,
  };
}
