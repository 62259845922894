import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import H3 from 'components/H3';
import Button from 'components/Button';
import Body from 'components/Body';
import Divider from 'components/Divider';
import { signOut } from 'utils/auth';

import messages from './messages';

function Declined({ toggleDeclined, intl }) {
  const supportEmail = intl.formatMessage(...messages.supportEmail);
  return (
    <div>
      <H3>
        <FormattedMessage {...messages.mustAccept} />
      </H3>
      <Body className="contact-support" opacity="0.75">
        <FormattedMessage
          {...messages.contactSupport}
          values={{
            emailLink: (chunks) => (
              <a href={`mailto:${supportEmail}`} style={{ color: '#0F2045' }}>
                ${chunks}
              </a>
            ),
          }}
        />
      </Body>
      <Divider />
      <Button
        label={messages.goBack}
        onClick={toggleDeclined}
        secondary
        style={{ marginTop: 0 }}
      />
      <Button
        label={messages.logout}
        onClick={signOut}
        tertiary
        style={{ marginTop: '20px' }}
      />
    </div>
  );
}

Declined.propTypes = {
  toggleDeclined: PropTypes.func.isRequired,
};

export default injectIntl(Declined);
