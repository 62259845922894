/**
 *
 * updatedPrivacyConsents
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Map } from 'immutable';
import styled from 'styled-components';

import Dialog from 'components/Dialog';
import injectSaga from 'utils/injectSaga';
// import injectReducer from 'utils/injectReducer';
import { makeSelectUser } from 'containers/UserInformation/selectors';
import makeSelectPrivacyConsents from 'containers/PrivacyConsents/selectors';

import PrivacyConsentsForm from './PrivacyConsentsForm';
import Declined from './Declined';
// import reducer from './reducer';
import saga from './saga';
import { updatePrivacyConsents } from './actions';

const Container = styled.div`
  margin: 0 auto;
  text-align: center;
  max-width: 512px;
  width: 90%;

  h1 {
    margin-bottom: 20px;
  }

  h3 {
    margin-bottom: 10px;
  }

  .contact-support {
    margin-bottom: 20px;
  }
`;

/* eslint-disable react/prefer-stateless-function */
class updatedPrivacyConsents extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      declined: false,
      submittedForm: false,
    };
  }

  submitForm = () => this.setState({ submittedForm: true });

  toggleDeclined = () =>
    this.setState((prevState) => ({ declined: !prevState.declined }));

  render() {
    const { privacyConsents, user, tokenLoaded } = this.props;
    const { declined, submittedForm } = this.state;
    const acceptedConsent = user.get('accepted_privacy_consent');
    const dialogOpen =
      acceptedConsent === false &&
      !submittedForm &&
      user &&
      (!user.get('company') ||
        user.getIn(['company', 'people_corp_yn']) === false);

    if (acceptedConsent) return <div />;

    return (
      <Dialog closeHidden maxWidth="698px" open={dialogOpen}>
        <Container>
          {!declined ? (
            <PrivacyConsentsForm
              submitForm={this.submitForm}
              loading={privacyConsents.loading}
              privacyConsent={privacyConsents.data}
              toggleDeclined={this.toggleDeclined}
              tokenLoaded={tokenLoaded}
              updatePrivacyConsents={this.props.updatePrivacyConsents}
              company={user.get('company')}
            />
          ) : (
            <Declined toggleDeclined={this.toggleDeclined} />
          )}
        </Container>
      </Dialog>
    );
  }
}

updatedPrivacyConsents.propTypes = {
  privacyConsents: PropTypes.object,
  tokenLoaded: PropTypes.bool.isRequired,
  updatePrivacyConsents: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Map),
};

const mapStateToProps = createStructuredSelector({
  privacyConsents: makeSelectPrivacyConsents(),
  user: makeSelectUser(),
});

function mapDispatchToProps(dispatch) {
  return {
    updatePrivacyConsents: (callback) =>
      dispatch(updatePrivacyConsents(callback)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

// const withReducer = injectReducer({ key: 'updatedPrivacyConsents', reducer });
const withSaga = injectSaga({ key: 'updatedPrivacyConsents', saga });

export default compose(withSaga, withConnect)(updatedPrivacyConsents);
