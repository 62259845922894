import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ProgressBar from 'components/ProgressBar';
import { tabletSize, phoneSize } from '../../global-styles';
import Logo from './Logo';

const Container = styled.div`
  display: flex;
  border-radius: 20px;
  box-shadow: 0 0 20px 0 rgba(99, 150, 177, 0.2);
  max-width: 945px;
  margin: 0 auto;
  min-height: 700px;
  margin-top: 20px;

  @media (max-width: ${tabletSize}) {
    flex-direction: column;
  }
`;

const PanelContainer = styled.div`
  :first-of-type {
    background-color: #ffffff;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    width: 30%;
    padding: 20px;
  }

  :last-of-type {
    background-color: #fafafa;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 70%;
  }

  @media (max-width: ${tabletSize}) {
    :first-of-type {
      width: 100%;
      min-height: 175px;
      border-bottom-left-radius: 0px;
      border-top-right-radius: 20px;
      padding: 40px;
    }

    :last-of-type {
      width: 100%;
      min-height: 525px;
      border-top-right-radius: 0px;
      border-bottom-left-radius: 20px;
    }
  }

  @media (max-width: ${phoneSize}) {
    :first-of-type {
      padding: 20px;
    }
  }
`;

function Panel({ children, openExitDialog, percent }) {
  return (
    <Container>
      <PanelContainer>
        <Logo openExitDialog={openExitDialog} />
        <ProgressBar percent={percent} />
      </PanelContainer>
      <PanelContainer>{children}</PanelContainer>
    </Container>
  );
}

Panel.propTypes = {
  children: PropTypes.node,
  openExitDialog: PropTypes.func,
  percent: PropTypes.number,
};

export default Panel;
