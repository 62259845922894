import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the messaging state domain
 */

const selectMessagingDomain = (state) => state.get('messaging', initialState);

/**
 * Other specific selectors
 */

const makeSelectChats = () =>
  createSelector(selectMessagingDomain, (substate) =>
    substate.get('chats').toJS(),
  );

const makeSelectToken = () =>
  createSelector(selectMessagingDomain, (substate) =>
    substate.get('token').toJS(),
  );

const makeSelectChat = () =>
  createSelector(selectMessagingDomain, (substate) =>
    substate.get('chat').toJS(),
  );

const makeSelectMessages = () =>
  createSelector(selectMessagingDomain, (substate) =>
    substate.get('messages').toJS(),
  );

/**
 * Default selector used by Messaging
 */

const makeSelectMessaging = () =>
  createSelector(selectMessagingDomain, (substate) => substate.toJS());

export default makeSelectMessaging;
export {
  selectMessagingDomain,
  makeSelectChats,
  makeSelectToken,
  makeSelectChat,
  makeSelectMessages,
};
