import { takeLatest, put, call } from 'redux-saga/effects';

import { API_URL } from 'utils/environment';
import { get, post } from 'utils/request';

import {
  fetchDefaultCardSuccess,
  fetchDefaultCardError,
  updateDefaultCardSuccess,
  updateDefaultCardError,
} from './actions.js';
import { FETCH_DEFAULT_CARD, UPDATE_DEFAULT_CARD } from './constants';

function* fetchDefaultCard() {
  const requestURL = `${API_URL}/stripe_customers`;

  try {
    const card = yield call(get, requestURL);
    yield put(fetchDefaultCardSuccess(card));
  } catch (error) {
    yield put(fetchDefaultCardError(error));
  }
}

function* updateDefaultCard({ token }) {
  const requestURL = `${API_URL}/stripe_customers`;

  try {
    const card = yield call(post, requestURL, token);
    yield put(updateDefaultCardSuccess(card));
  } catch (error) {
    yield put(updateDefaultCardError(error));
  }
}

export default function* failedPaymentsBannerSaga() {
  yield takeLatest(FETCH_DEFAULT_CARD, fetchDefaultCard);
  yield takeLatest(UPDATE_DEFAULT_CARD, updateDefaultCard);
}
