/**
 *
 * TextInput for Phone Number with fixed +1 Area code UI
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const PhoneInputWrap = styled.div`
  position: relative;
`;
const StyledLabel = styled.div`
  color: #0f2045;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  -webkit-letter-spacing: 0.1px;
  -moz-letter-spacing: 0.1px;
  -ms-letter-spacing: 0.1px;
  letter-spacing: 0.1px;
  margin-bottom: 10px;
`;
const StyledTextInput = styled.input`
  height: 40px;
  padding-left: 40px;
  padding-right: 10px;
  border-radius: 4px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  color: '#0f2045';
  letter-spacing: normal;
  border: none;
  background-color: rgba(240, 242, 245, 0.79);
  transition: 0.25s;
  outline: 0;
  width: 100%;
`;
const StyledInlineMessage = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: #cf1a1a;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.1px;
  margin-top: 10px;
`;
const Prefix = styled.span`
  padding: 8px 10px;
  background: #f0f0f0;
  border-radius: 4px 0 0 4px;
  position: absolute;
  height: 40px;
  z-index: 1;
`;

const PhoneInput = (props) => {
  const showInlineMessage =
    (props.meta.touched && props.meta.error) || props.inlineMessage;
  return (
    <div
      style={{
        marginBottom: props.marginBottom || '60px',
        width: props.width || '220px',
      }}
    >
      <StyledLabel>
        <FormattedMessage {...props.label} />
      </StyledLabel>
      <PhoneInputWrap>
        <Prefix>+1</Prefix>
        <StyledTextInput {...props.input} />
        {showInlineMessage && (
          <StyledInlineMessage>
            <span>{showInlineMessage}</span>
          </StyledInlineMessage>
        )}
      </PhoneInputWrap>
    </div>
  );
};

PhoneInput.propTypes = {
  label: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,

  inlineMessage: PropTypes.object.string,
  marginBottom: PropTypes.string,
  width: PropTypes.string,
};

export default PhoneInput;
