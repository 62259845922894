/**
 *
 * ProgressBar
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Progress from 'antd/lib/progress';

import ContentBold from 'components/ContentBold';
import { tabletSize } from '../../global-styles';

const Container = styled.div`
  width: 120px;
  height: 120px;
  position: relative;
  margin: 0 auto;

  p {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 60px;
  }

  @media (max-width: ${tabletSize}) {
    height: 100%;
    width: 100%;

    p {
      position: relative;
      text-align: left;
      top: -5px;
      left: 20px;
    }
  }
`;

const Gradient = styled.svg`
  height: 0px;

  @media (max-width: ${tabletSize}) {
    display: none;
  }
`;

const ProgressCircle = styled(Progress)`
  .ant-progress-circle {
    margin-top: -10px;
  }

  .ant-progress-circle-trail {
    stroke: #ecf2f4 !important;
  }

  .ant-progress-circle-path {
    stroke: url(#progress-gradient) !important;
  }

  @media (max-width: ${tabletSize}) {
    display: none;
  }
`;

const ProgressLine = styled(Progress)`
  display: none;

  .ant-progress-inner {
    border-radius: 100px;
    background-color: #ecf2f4;
  }

  .ant-progress-bg {
    background: linear-gradient(to left, #e938cc, #fc587f) !important;
  }

  @media (max-width: ${tabletSize}) {
    display: block;
  }
`;

function ProgressBar({ percent }) {
  return (
    <Container>
      <Gradient>
        <defs>
          <linearGradient id="progress-gradient" gradientTransform="rotate(90)">
            <stop offset="0%" stopColor="#fc587f" />
            <stop offset="100%" stopColor="#e938cc" />
          </linearGradient>
        </defs>
      </Gradient>
      <ContentBold>{percent}%</ContentBold>
      <ProgressCircle
        type="circle"
        strokeLinecap="square"
        percent={percent}
        strokeWidth={6}
        showInfo={false}
        strokeColor="#fc587f"
      />
      <ProgressLine
        type="line"
        strokeLinecap="round"
        percent={percent}
        strokeWidth={6}
        showInfo={false}
        strokeColor="#fc587f"
      />
    </Container>
  );
}

ProgressBar.propTypes = {
  percent: PropTypes.number,
};

export default ProgressBar;
