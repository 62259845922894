/**
 *
 * PanelFullWidth
 *
 */

import styled from 'styled-components';
import { phoneSize } from '../../global-styles';

const PanelFullWidth = styled.div`
  background-color: #ffffff;
  border-radius: 20px;
  width: 100%;

  h1 {
    margin-left: 60px;
    margin-top: 60px;
    @media (max-width: ${phoneSize}) {
      margin-left: 20px;
      margin-top: 40px;
    }
  }
`;

export default PanelFullWidth;
