import React from 'react';
import { Modal as AntDModal } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import H4 from 'components/H4';
import P from 'components/P';
import SVG from 'react-inlinesvg';

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledH4 = styled(H4)`
  font-family: 'TT Commons Pro';
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
`;

const StyledP = styled(P)`
  font-family: 'TT Commons Pro';
  font-style: normal;
  color: #555a6f;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  margin-top: 8px;
`;

const Modal = ({
  isModalOpen,
  setIsModalOpen,
  infoIcon,
  iconColor,
  title,
  description,
  children,
  ...props
}) => {
  const StyledIconContainer = styled.div`
    background-color: ${iconColor};
    display: flex;
    justify-content: center;
    align-items: center;
    fill: 'transparent';
    width: 40px;
    height: 40px;
    border-radius: 25px;
    margin-bottom: 15px;
  `;

  return (
    <AntDModal
      visible={isModalOpen}
      footer={null}
      onCancel={() => setIsModalOpen(false)}
      {...props}
    >
      <StyledContainer>
        {infoIcon && (
          <StyledIconContainer>
            {infoIcon && <SVG src={infoIcon} alt="info icon" />}
          </StyledIconContainer>
        )}
        <StyledH4>{title}</StyledH4>
        <StyledP>{description}</StyledP>
        {children}
      </StyledContainer>
    </AntDModal>
  );
};

Modal.propTypes = {
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
  infoIcon: PropTypes.string,
  iconColor: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  body: PropTypes.any,
};

export default Modal;
