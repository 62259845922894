import styled from 'styled-components';
import { tabletSize } from '../../global-styles';

const Wrapper = styled.div`
  display: flex;
  border-radius: 20px;
  box-shadow: 0 0 20px 0 rgba(99, 150, 177, 0.2);

  @media (max-width: ${tabletSize}) {
    flex-direction: column;
  }
`;

export default Wrapper;
