import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectFailedPaymentsModalDomain = (state) =>
  state.get('failedPaymentsModal', initialState);

const makeSelectDefaultCard = () =>
  createSelector(selectFailedPaymentsModalDomain, (substate) =>
    substate.get('defaultCard'),
  );

export { makeSelectDefaultCard };
