/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import '@babel/polyfill';

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import FontFaceObserver from 'fontfaceobserver';
import TagManager from 'react-gtm-module';
import history from 'utils/history';
import 'sanitize.css/sanitize.css';
import 'antd/dist/antd.css';

// Import css for rsuite ver from 5.6.6
import 'rsuite/dist/rsuite.min.css';

import './fonts/index.css';

import * as Sentry from '@sentry/browser';
import Cohere from 'cohere-js';

// Import root app
import App from 'containers/App';

// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider';

// Load the favicon and the .htaccess file
import '!file-loader?name=[name].[ext]!./images/favicon.ico';
import 'file-loader?name=.htaccess!./.htaccess'; // eslint-disable-line import/extensions

// import environment
import { SENTRY_DSN, ENVIRONMENT, COHERE_PROJECT_KEY } from 'utils/environment';

// Import amplitude
import { initAmplitude } from 'utils/amplitude';

import { Azure } from 'containers/AzureADB2C';
import configureStore from './configureStore';

// Import i18n messages
import { translationMessages } from './i18n';
import { Router } from 'react-router-dom';
import { GSPlus } from 'containers/GSPlus';
import { initGoogleMapScript } from 'containers/Matching/GoogleMap';

// Hook Sentry up
if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENVIRONMENT,
    release: ENV_APP_VERSION, // eslint-disable-line no-undef
  });
}

if (COHERE_PROJECT_KEY) {
  Cohere.init(COHERE_PROJECT_KEY);
} else {
  Sentry.captureMessage('Cohere project key not found');
}

// Observe loading of Open Sans (to remove open sans, remove the <link> tag in
// the index.html file and this observer)
const sourceSansProObserver = new FontFaceObserver('Source Sans Pro', {});

// When Source Sans Pro is loaded, add a font-family Source Sans Pro to the body
sourceSansProObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
});

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app');

// GTM
if (ENVIRONMENT === 'PRODUCTION') {
  TagManager.initialize({
    gtmId: 'GTM-PPKWFTX',
  });
} else {
  TagManager.initialize({
    gtmId: 'GTM-W55T7K2',
  });
}

// Amplitude
initAmplitude();
initGoogleMapScript();

const render = (messages) => {
  ReactDOM.render(
    <Provider store={store}>
      <LanguageProvider messages={messages}>
        <Azure>
          <GSPlus>
            <Router history={history}>
              <App />
            </Router>
          </GSPlus>
        </Azure>
      </LanguageProvider>
    </Provider>,
    MOUNT_NODE,
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

const mswPromise =
  process.env.NODE_ENV === 'development'
    ? require('mocks/worker').default
    : Promise.resolve();

mswPromise.then(() => {
  render(translationMessages);
});

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
// if (process.env.NODE_ENV === 'production') {
//   require('offline-plugin/runtime').install(); // eslint-disable-line global-require
// }
