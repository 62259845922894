/*
 *
 * TermsAndConditions reducer
 *
 */

import { fromJS } from 'immutable';
import {
  FETCH_CURRENT_PRIVACY_CONSENTS,
  FETCH_CURRENT_PRIVACY_CONSENTS_ERROR,
  FETCH_CURRENT_PRIVACY_CONSENTS_SUCCESS,
} from './constants';

export const initialState = fromJS({
  loading: false,
  error: false,
  data: null,
});

function privacyConsentsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CURRENT_PRIVACY_CONSENTS:
      return state.set('loading', true);
    case FETCH_CURRENT_PRIVACY_CONSENTS_ERROR:
      return state.set('loading', false).set('error', action.error);
    case FETCH_CURRENT_PRIVACY_CONSENTS_SUCCESS:
      return state.set('loading', false).set('data', action.PrivacyConsents);
    default:
      return state;
  }
}

export default privacyConsentsReducer;
