/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { reduxForm, Field } from 'redux-form/immutable';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { logEvent, COMPLETED_SIGNUP } from 'utils/amplitude';
import LanguageSelector from 'containers/LanguageSelector';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import history from 'utils/history';
// squid
import { SqTextInputWithLabel } from 'components/SqTextInput/TextInput';
import SqAlert from 'components/SqAlert/Alert';
import SqButton from 'components/SqButton/SqButton';
import SqCheckBox from 'components/SqCheckbox/Checkbox';
import TopBar from 'containers/WMHSignUp/TopBar';
import saga from './saga';
import reducer from './reducer';

import messages from './messages';
import { auditProgress, validate } from './Helper';
import {
  minTabletSize,
  phoneSize,
  teenyWeenySize,
  eAZoom400,
} from '../../global-styles';
import { registerUser } from './actions';
import { makeSelectLoading } from './selectors';
import DropdownButtonAccessibility from 'components/SqDropdownButton/DropdownButtonAccessibility';
import { asyncValidate } from 'containers/SignUp/SignUpForm';
import CaresMigrationDialog from 'containers/SignUp/CaresMigrationDialog';

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  min-height: 100vh;
  z-index: 100;
  max-height: 100vh;
  overflow-y: scroll;

  @media (min-width: ${teenyWeenySize}) {
    padding-left: 5%;
    padding-right: 5%;
    width: 100%;
    align-items: center;
  }
  @media (min-width: ${phoneSize}) {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
  @media (min-width: ${minTabletSize}) {
    align-items: left;
  }

  @media screen and (max-width: ${eAZoom400}) and (min-device-width: ${phoneSize}) {
    padding-left: 5%;
    padding-right: 5%;
    width: 100%;
    align-items: center;

    input {
      max-width: 300px !important;
    }
  }
`;

const SignupFormElementsWrapper = styled.div`
  max-width: 374px;
  width: 100%;

  @media screen and (max-width: ${eAZoom400}) and (min-device-width: ${phoneSize}) {
    div {
      max-width: 80% !important;
    }
  }
`;

const AlreadyHaveAnAccountWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 50px;

  a {
    color: #5d70ba;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: 374px;
  width: 100%;
  margin-top: 180px;
  margin-bottom: 25px;
`;

const StyledForm = styled.form`
  > * {
    margin-bottom: 30px;
    max-width: 374px;
    width: 100%;
  }
`;

const PageTitle = styled.span`
  font-family: 'P22 Mackinac Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 140%;
  color: #282d40;
  margin-top: 45px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
const WarningStyle = styled.span`
  color: rgb(138, 79, 73);
`;

const SignUpForm = (props) => {
  const { locale } = props.intl;
  const intl = useIntl();
  const [signUpError, setSignUpError] = useState(null);
  const [passwordVisibles, setPasswordVisibles] = useState({});
  const [referralSource, setReferralSource] = useState();

  const { loading } = props;
  const urlParams = queryString.parse(window.location.search);
  const [isMigrationDialogVisible, setMigrationDialogVisible] = useState(false);
  const displayMigrationDialog = () => {
    setMigrationDialogVisible(true);
  };
  useEffect(() => {
    if (
      urlParams.company &&
      (urlParams.company.toLowerCase() === 'greenshieldcares2024' ||
        urlParams.company.toLowerCase() === 'greenshieldcares2023')
    ) {
      displayMigrationDialog();
    }
  }, [urlParams.company]);
  const GSCareReferralOptions = [
    {
      key: 0,
      value: '',
      label: intl.formatMessage({
        defaultMessage: 'Please select an option in this list',
      }),
    },
    {
      key: 1,
      value: 'Facebook',
      label: intl.formatMessage({ defaultMessage: 'Facebook' }),
    },
    {
      key: 2,
      value: 'Instagram',
      label: intl.formatMessage({ defaultMessage: 'Instagram' }),
    },
    {
      key: 3,
      value: 'Web search',
      label: intl.formatMessage({ defaultMessage: 'Web search' }),
    },
    {
      key: 4,
      value: 'Friend/Family/Word of Mouth',
      label: intl.formatMessage({
        defaultMessage: 'Friend/Family/Word of Mouth',
      }),
    },
    {
      key: 5,
      value: 'Email',
      label: intl.formatMessage({ defaultMessage: 'Email' }),
    },
    {
      key: 6,
      value: 'News article',
      label: intl.formatMessage({ defaultMessage: 'News article' }),
    },
    {
      key: 7,
      value: 'Community Organization',
      label: intl.formatMessage({ defaultMessage: 'Community Organization' }),
    },
    {
      key: 8,
      value: 'Newcomer Women’s Services Toronto',
      label: 'Newcomer Women’s Services Toronto',
    },
    {
      key: 9,
      value: 'Other',
      label: intl.formatMessage({ defaultMessage: 'Other' }),
    },
  ];

  const GSCare5ReferralOptions = [
    {
      key: 0,
      value: '',
      label: intl.formatMessage({
        defaultMessage: 'Please select an option in this list',
      }),
    },
    {
      key: 1,
      value: "Canadian Women's Foundation",
      label: intl.formatMessage({
        defaultMessage: "Canadian Women's Foundation",
      }),
    },
    {
      key: 2,
      value: 'Up with Women',
      label: intl.formatMessage({ defaultMessage: 'Up with Women' }),
    },
    {
      key: 3,
      value: 'NPower Canada',
      label: intl.formatMessage({ defaultMessage: 'NPower Canada' }),
    },
    {
      key: 4,
      value: 'Newcomer Women’s Services Toronto',
      label: intl.formatMessage({
        defaultMessage: 'Newcomer Women’s Services Toronto',
      }),
    },
    {
      key: 5,
      value: 'Strong Minds Strong Kids/Make the Connection',
      label: 'Strong Minds Strong Kids/Make the Connection',
    },
    {
      key: 6,
      value: 'Canadian Perinatal Mental Health Collaborative',
      label: 'Canadian Perinatal Mental Health Collaborative',
    },
    {
      key: 7,
      value: 'Campfire Circle',
      label: 'Campfire Circle',
    },
    {
      key: 8,
      value: 'Black Mental Health Canada',
      label: 'Black Mental Health Canada',
    },
    {
      key: 9,
      value: 'Other',
      label: intl.formatMessage({ defaultMessage: 'Other' }),
    },
  ];

  const GSCare3ReferralOptions = [
    {
      key: 0,
      value: '',
      label: intl.formatMessage({
        defaultMessage: 'Please select an option in this list',
      }),
    },
    {
      key: 1,
      value: 'Dream Legacy Foundation',
      label: intl.formatMessage({
        defaultMessage: 'Dream Legacy Foundation',
      }),
    },
    {
      key: 2,
      value: 'HOPE',
      label: intl.formatMessage({ defaultMessage: 'HOPE' }),
    },
    {
      key: 3,
      value: 'MaRS',
      label: intl.formatMessage({ defaultMessage: 'MaRS' }),
    },
    {
      key: 4,
      value: 'MPE',
      label: intl.formatMessage({ defaultMessage: 'MPE' }),
    },
    {
      key: 5,
      value: 'ATB',
      label: intl.formatMessage({
        defaultMessage: 'ATB',
      }),
    },
    {
      key: 6,
      value: 'Frida',
      label: intl.formatMessage({ defaultMessage: 'Frida' }),
    },
    {
      key: 7,
      value: 'Not 9 to 5',
      label: 'Not 9 to 5',
    },
    {
      key: 8,
      value: 'Other',
      label: intl.formatMessage({ defaultMessage: 'Other' }),
    },
  ];
  useEffect(() => {
    localStorage.setItem('selectedLanguage', locale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterGSC3NoFilterOptions = () => {
    if (locale === 'fr') {
      return GSCare3ReferralOptions.filter(
        (option) => option.label !== 'Not 9 to 5',
      );
    } else return GSCare3ReferralOptions;
  };

  const serveDifferentGSCListLocale = () => {
    if (urlParams.company.toLowerCase() === 'greenshieldcares3') {
      return filterGSC3NoFilterOptions();
    } else if (
      locale === 'fr' ||
      urlParams.company.toLowerCase() === 'greenshieldcares2024'
    ) {
      return GSCareReferralOptions.filter(
        (option) => option.label !== 'Newcomer Women’s Services Toronto',
      );
    } else return GSCareReferralOptions;
  };

  const completeAndNavigate = (token) => {
    auditProgress('', 'completed_signup', token);
    history.push('/');
  };

  const renderFirstSentence = (company) => {
    switch (company.toLowerCase()) {
      case 'greenshieldcares5':
        break;
      case 'greenshieldcares3':
        break;
      default:
        return <FormattedMessage {...messages.gsCaresAboutWMH} />;
    }
  };

  const renderSecondSentence = (company) => {
    switch (company.toLowerCase()) {
      case 'greenshieldcares5':
        return (
          <FormattedMessage defaultMessage="Sign up to receive your five free hours of talk therapy." />
        );
      case 'greenshieldcares3':
        return (
          <FormattedMessage defaultMessage="Sign up to receive your three free hours of talk therapy." />
        );
      case 'greenshieldcares2024':
        return (
          <FormattedMessage defaultMessage="Sign up to receive your free hour of talk therapy and complimentary access to iCBT." />
        );
      default:
        return (
          <FormattedMessage defaultMessage="Sign up to receive your two free hours of talk therapy and complimentary access to iCBT." />
        );
    }
  };

  const renderDescriptionCopy = () => {
    const { company } = urlParams;
    if (company.toLowerCase() === 'relief') {
      return (
        <>
          <FormattedMessage {...messages.reliefSignup} />
        </>
      );
    } else if (
      [
        'greenshieldcares',
        'greenshieldcares3',
        'greenshieldcares5',
        'greenshieldcares2024',
      ].includes(company.toLowerCase())
    ) {
      return (
        <>
          {renderFirstSentence(company)}
          {renderSecondSentence(company)}
        </>
      );
    }
    return (
      <>
        <FormattedMessage {...messages.mayBeStruggling} />
        <span style={{ display: 'block', marginTop: '1rem' }}>
          <FormattedMessage {...messages.standsByThose} />
        </span>
      </>
    );
  };

  const onSubmit = (values) => {
    const valuesJS = values.toJS();
    const { other_referral_source } = valuesJS;
    delete valuesJS.other_referral_source;

    if (!referralSource) return;
    const mergedValues = {
      ...valuesJS,
      locale,
      source: 'therapy',
      referral_source:
        referralSource === 'Other' ? other_referral_source : referralSource,
      consent_yn: values.get('consent_yn') || false,
      corporate_account: urlParams.company,
      marketing_yn: values.get('marketing_yn') || false,
    };

    // register user
    props.registerUser(
      mergedValues,
      (response) => {
        const token = response.access_token;
        localStorage.setItem('token', token);
        logEvent(COMPLETED_SIGNUP, {
          page: '',
          source: urlParams.company,
        });
        completeAndNavigate(token);
      },
      (error) => {
        setSignUpError(error.data.error);
      },
    );
  };

  return (
    <Panel>
      <TopBar language={locale} />
      <SignupFormElementsWrapper>
        <Header>
          <LanguageSelector
            color="#000"
            locale={(locale || 'EN').toUpperCase()}
          />
        </Header>
        <PageTitle>
          <FormattedMessage {...messages.createAccount} />
        </PageTitle>
        <AlreadyHaveAnAccountWrapper>
          <div style={{ marginBottom: 20 }}>
            <p>{renderDescriptionCopy()}</p>
          </div>
          <p tabIndex="0">
            <FormattedMessage
              {...messages.alreadyHaveAccount}
              values={{
                link: (
                  <Link to="signin">
                    <span>
                      <FormattedMessage {...messages.signIn} />
                    </span>
                  </Link>
                ),
              }}
            />
          </p>
        </AlreadyHaveAnAccountWrapper>
        <hr style={{ width: '100px', marginLeft: '0', marginRight: 'auto' }} />

        <StyledForm onSubmit={props.handleSubmit(onSubmit)}>
          <Field
            component={SqTextInputWithLabel}
            name="first_name"
            label={<FormattedMessage {...messages.firstName} />}
            type="text"
          />
          <Field
            component={SqTextInputWithLabel}
            name="last_name"
            label={<FormattedMessage {...messages.lastName} />}
            type="text"
          />
          <Field
            component={SqTextInputWithLabel}
            name="email"
            label={<FormattedMessage {...messages.email} />}
            type="text"
          />
          <Field
            style={{ width: '374px' }}
            component={SqTextInputWithLabel}
            name="password"
            label={<FormattedMessage {...messages.password} />}
            type={passwordVisibles.password ? 'text' : 'password'}
            rightIconName={passwordVisibles.password ? 'Eye' : 'EyeOff'}
            rightIconOnclick={() =>
              setPasswordVisibles((pre) => ({
                ...passwordVisibles,
                password: !pre.password,
              }))
            }
            rightIconAriaLabel={
              passwordVisibles.password ? 'Hide password' : 'Show password'
            }
          />
          <Field
            style={{ width: '374px' }}
            component={SqTextInputWithLabel}
            name="retype_password"
            label={<FormattedMessage {...messages.retypePassword} />}
            rightIconName={passwordVisibles.retypePassword ? 'Eye' : 'EyeOff'}
            rightIconOnclick={() =>
              setPasswordVisibles((pre) => ({
                ...passwordVisibles,
                retypePassword: !pre.retypePassword,
              }))
            }
            rightIconAriaLabel={
              passwordVisibles.retypePassword
                ? 'Hide password'
                : 'Show password'
            }
            type={passwordVisibles.retypePassword ? 'text' : 'password'}
          />
          <DropdownButtonAccessibility
            name="referral_source"
            label={
              <FormattedMessage defaultMessage="How did you hear about us?" />
            }
            helpText={
              !referralSource && (
                <WarningStyle>
                  <FormattedMessage defaultMessage="This is a required field" />
                </WarningStyle>
              )
            }
            style={{
              marginBottom: '36px',
              zIndex: 0,
            }}
            required
            placeholder={
              <FormattedMessage defaultMessage="Please select an option in this list" />
            }
            onChange={(e) => setReferralSource(e.target.value)}
            selections={
              urlParams.company.toLowerCase() === 'greenshieldcares5'
                ? GSCare5ReferralOptions
                : serveDifferentGSCListLocale()
            }
          />
          {referralSource === 'Other' && (
            <Field
              style={{ width: '374px' }}
              component={SqTextInputWithLabel}
              name="other_referral_source"
              type="text"
            />
          )}
          <Field
            component={SqCheckBox}
            id="consent_yn"
            initValue={null}
            disabled={false}
            type="checkbox"
            name="consent_yn"
            title={<FormattedMessage {...messages.tcppa} />}
            helpText={
              <span>
                <FormattedMessage
                  {...messages.agreements}
                  values={{
                    tc: (
                      <a
                        href={`https://${
                          locale.toLowerCase() === 'fr' ? 'fr.' : 'www.'
                        }inkblottherapy.com/conditions`}
                        target="_blank"
                      >
                        <FormattedMessage {...messages.inkblotTermCondition} />
                      </a>
                    ),
                    pp: (
                      <a
                        href={`https://${
                          locale.toLowerCase() === 'fr' ? 'fr.' : 'www.'
                        }inkblottherapy.com/privacy/`}
                        target="_blank"
                      >
                        <FormattedMessage {...messages.privacyPolicy} />
                      </a>
                    ),
                  }}
                />
              </span>
            }
            helpTextAriaLabel={<FormattedMessage {...messages.byAgreeing} />}
          />
          <SqAlert
            type="info"
            text={<FormattedMessage {...messages.createOwnAccount} />}
          />
          <Field
            component={SqCheckBox}
            id="marketing_yn"
            initValue={null}
            disabled={false}
            type="checkbox"
            name="marketing_yn"
            required={false}
            title={<FormattedMessage {...messages.communicationPermission} />}
            helpText={<FormattedMessage {...messages.communicationConsent} />}
          />
          {signUpError && (
            <p style={{ color: '#8A4F49', margin: '10px' }}>{signUpError}</p>
          )}
          <ButtonContainer>
            <SqButton
              type="submit"
              label={<FormattedMessage {...messages.createAccount} />}
              style={{ width: '166px', height: '56px' }}
              disabled={loading || props.invalid || !referralSource}
            />
          </ButtonContainer>
        </StyledForm>
        <SqAlert
          type="infoCritical"
          text={<FormattedMessage {...messages.crisisSupport} />}
          style={{ marginBottom: '80px' }}
        />
      </SignupFormElementsWrapper>
      <CaresMigrationDialog open={isMigrationDialogVisible} />
    </Panel>
  );
};

SignUpForm.propTypes = {
  intl: PropTypes.object,
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
});

function mapDispatchToProps(dispatch) {
  return {
    registerUser: (values, callback, errCallback) =>
      dispatch(registerUser(values, callback, errCallback)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'wmhSignup', reducer });
const withSaga = injectSaga({ key: 'wmhSignup', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
  reduxForm({
    form: 'WMHSignUpForm',
    validate,
    asyncValidate,
    asyncChangeFields: ['email'],
  }),
)(injectIntl(SignUpForm));
