import styled from 'styled-components';
import { darkSlateBlue } from '../../global-styles';

const ContentBoldBlue = styled.p`
  font-family: 'Barlow', sans-serif;
  color: ${({ color }) => color || darkSlateBlue};
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.2px;
`;

export default ContentBoldBlue;
