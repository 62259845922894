/*
 * Matching Messages
 *
 * This contains all the text for the Matching container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers';

export default defineMessages({
  completeRegistration: {
    defaultMessage: 'Complete Registration',
  },
  yourDetails: {
    defaultMessage: 'Your Details',
  },
  loading: {
    defaultMessage: 'Loading...',
  },
  waitingHostConfirm: {
    defaultMessage:
      'The primary account holder must confirm the match before you can complete registration',
  },
});
