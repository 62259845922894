import axios from 'axios';
import { SERVER_URL } from 'utils/environment';

export const auditProgress = (
  page,
  choice,
  token,
  url = SERVER_URL,
  callback = () => {},
) => {
  axios
    .post(
      `${url}/api/v2/audit_progresses`,
      { page, choice: [choice] },
      { headers: { Authorization: token } },
    )
    .then(() => callback())
    .catch(() => callback());
};
