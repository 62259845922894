import styled from 'styled-components';

import { phoneSize, tabletSize, teenyWeenySize } from '../../../global-styles';

const Container = styled.div`
  text-align: center;
  margin: 0 auto;
  width: 500px;
  padding-bottom: 60px;
  h1 {
    margin-top: 60px;
    margin-bottom: 20px;
  }
  @media (max-width: ${tabletSize}) {
    width: 420px;
    padding-bottom: 60px;
  }
  @media (max-width: ${phoneSize}) {
    width: 300px;
    h1 {
      margin-top: 40px;
    }
  }
  @media (max-width: ${teenyWeenySize}) {
    width: 250px;
  }
`;

export default Container;
