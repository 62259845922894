import { PublicClientApplication } from '@azure/msal-browser';
import {
  DEFAULT_DHE_AZURE_POLICY_ID,
  DHE_AZURE_CLIENT_ID,
  DHE_AZURE_TENANT_NAME,
} from 'utils/environment';

export const constructAuthorityFromPolicy = (
  policyId = DEFAULT_DHE_AZURE_POLICY_ID,
) =>
  `https://${DHE_AZURE_TENANT_NAME}.b2clogin.com/${DHE_AZURE_TENANT_NAME}.onmicrosoft.com/${policyId}`;

export const createClient = (policyId) =>
  new PublicClientApplication({
    auth: {
      clientId: DHE_AZURE_CLIENT_ID,
      authority: constructAuthorityFromPolicy(policyId),
      knownAuthorities: [`${DHE_AZURE_TENANT_NAME}.b2clogin.com`],
    },
  });
