/**
 *
 * PreviousButtonContent
 *
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Content from 'components/Content';
import { mediumBlue } from '../../global-styles';

const Button = styled.button`
  margin-top: 10px;
  cursor: pointer;
  :focus {
    outline: 5px solid ${mediumBlue};
  }
  p.link:hover,
  &:focus p.link {
    text-decoration: underline;
  }
`;

function PreviousButtonContent({ label, onClick, style, isLink }) {
  return (
    <Button type="button" onClick={onClick} style={style}>
      <Content bold color={mediumBlue} className={isLink ? 'link' : ''}>
        <FormattedMessage {...label} />
      </Content>
    </Button>
  );
}

PreviousButtonContent.propTypes = {
  label: PropTypes.object,
  onClick: PropTypes.func,
  style: PropTypes.object,
  isLink: PropTypes.bool,
};

export default PreviousButtonContent;
