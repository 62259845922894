import { useAccount, useMsal } from '@azure/msal-react';
import { makeSelectAzureProfileId } from 'containers/UserInformation/selectors';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { PMOS_SCOPE, ROOT_URL } from 'utils/environment';
import { initSessionMonitoring } from './initSessionMonitoring';
import { clearAuthTokens } from 'utils/auth';
import { constructAuthorityFromPolicy } from 'containers/AzureADB2C/client';

const GSPlusSessionContext = React.createContext();
const useGSPlusSessionContext = () => React.useContext(GSPlusSessionContext);
// eslint-disable-next-line react/display-name
export const withGSPlusSessionContext = (Cmp) => (props) =>
  <Cmp {...props} gsPlusSession={{ ...useGSPlusSessionContext() }} />;

const GSPlusSessionProvider = ({ azureUserId, children }) => {
  const account = useAccount({ localAccountId: azureUserId });
  const [terminate, setTerminate] = useState();
  const { instance } = useMsal();

  const logout = useCallback(() => {
    clearAuthTokens();
    instance.logoutRedirect({
      account,
      authority: constructAuthorityFromPolicy(account.idTokenClaims['tfp']),
      postLogoutRedirectUri: `${ROOT_URL}/signin`,
    });
  }, [account, instance]);

  const terminateAndLogout = useCallback(async () => {
    try {
      if (terminate) await terminate();
    } catch {
      // pass
    } finally {
      logout();
    }
  }, [terminate, logout]);

  useEffect(() => {
    let cleanup;
    if (!account) return;

    const getToken = async () => {
      const { accessToken } = await instance.acquireTokenSilent({
        account,
        authority: constructAuthorityFromPolicy(account.idTokenClaims['tfp']),
        scopes: [PMOS_SCOPE],
      });
      return accessToken;
    };

    const init = async () => {
      try {
        const result = await initSessionMonitoring(getToken, logout);

        setTerminate(() => result.terminate);
        cleanup = result.cleanup;
      } catch (err) {
        // failed to init session
      }
    };

    init();

    return () => {
      if (cleanup) cleanup();
    };
  }, [account, instance, logout]);

  return (
    <GSPlusSessionContext.Provider value={{ terminateAndLogout }}>
      {children}
    </GSPlusSessionContext.Provider>
  );
};

const mapStateToProps = createStructuredSelector({
  azureUserId: makeSelectAzureProfileId(),
});

const withConnect = connect(mapStateToProps);

export const GSPlus = compose(withConnect)(GSPlusSessionProvider);
