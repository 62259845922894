/*
 *
 * TermsAndConditions actions
 *
 */

import {
  FETCH_CURRENT_TERMS_AND_CONDITIONS_SUCCESS,
  FETCH_CURRENT_TERMS_AND_CONDITIONS_ERROR,
  FETCH_CURRENT_TERMS_AND_CONDITIONS,
} from './constants';

export function fetchCurrentTermsAndConditions(callback) {
  return {
    type: FETCH_CURRENT_TERMS_AND_CONDITIONS,
    callback,
  };
}

export function fetchCurrentTermsAndConditionsSuccess(termsAndConditions) {
  return {
    type: FETCH_CURRENT_TERMS_AND_CONDITIONS_SUCCESS,
    termsAndConditions,
  };
}

export function fetchCurrentTermsAndConditionsError(error) {
  return {
    type: FETCH_CURRENT_TERMS_AND_CONDITIONS_ERROR,
    error,
  };
}
