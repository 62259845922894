/**
 *
 * StripeButtonWrapper
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import StripeCheckout from 'react-stripe-checkout';
import { STRIPE_KEY } from '../../utils/environment';

function setTitleForStripeIframe() {
  const waitPopup = setTimeout(() => {
    const iframes = document.getElementsByName('stripe_checkout_app');
    if (iframes && iframes[0]) {
      iframes[0].title = 'Inkblot Technologies Modals';
      clearTimeout(waitPopup);
    }
  }, 100);
}
/* eslint-disable react/prefer-stateless-function */
class StripeButtonWrapper extends React.PureComponent {
  componentDidMount() {
    setTitleForStripeIframe();
  }

  render() {
    const { onToken, email, description, panelLabel, children, locale } =
      this.props;

    return (
      <div>
        <StripeCheckout
          stripeKey={STRIPE_KEY}
          name="Inkblot Technologies Inc."
          allowRememberMe={false}
          token={onToken}
          email={email}
          description={description}
          panelLabel={panelLabel}
          ComponentClass="div"
          locale={locale}
        >
          {children}
        </StripeCheckout>
      </div>
    );
  }
}

StripeButtonWrapper.propTypes = {
  onToken: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  panelLabel: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  locale: PropTypes.string.isRequired,
};

export default StripeButtonWrapper;
