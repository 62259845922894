export const FETCH_DEFAULT_CARD = 'app/FailedPaymentsPage/FETCH_DEFAULT_CARD';
export const FETCH_DEFAULT_CARD_SUCCESS =
  'app/FailedPaymentsPage/FETCH_DEFAULT_CARD_SUCCESS';
export const FETCH_DEFAULT_CARD_ERROR =
  'app/FailedPaymentsPage/FETCH_DEFAULT_CARD_ERROR';

export const UPDATE_DEFAULT_CARD = 'app/FailedPaymentsPage/UPDATE_DEFAULT_CARD';
export const UPDATE_DEFAULT_CARD_SUCCESS =
  'app/FailedPaymentsPage/UPDATE_DEFAULT_CARD_SUCCESS';
export const UPDATE_DEFAULT_CARD_ERROR =
  'app/FailedPaymentsPage/UPDATE_DEFAULT_CARD_ERROR';

export const FETCH_FAILED_APPOINTMENTS =
  'app/FailedPaymentsPage/FETCH_FAILED_APPOINTMENTS';
export const FETCH_FAILED_APPOINTMENTS_SUCCESS =
  'app/FailedPaymentsPage/FETCH_FAILED_APPOINTMENTS_SUCCESS';
export const FETCH_FAILED_APPOINTMENTS_ERROR =
  'app/FailedPaymentsPage/FETCH_FAILED_APPOINTMENTS_ERROR';

export const RETRY_FAILED_PAYMENTS =
  'app/FailedPaymentsPage/RETRY_FAILED_PAYMENTS';
export const RETRY_FAILED_PAYMENTS_SUCCESS =
  'app/FailedPaymentsPage/RETRY_FAILED_PAYMENTS_SUCCESS';
export const RETRY_FAILED_PAYMENTS_ERROR =
  'app/FailedPaymentsPage/RETRY_FAILED_PAYMENTS_ERROR';
