import React from 'react';
import Icon from 'components/SqIcon/Icon';
import { SQTOKEN } from 'squid-styles';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CommonLabelStyles } from 'components/CommonStyles';
import { tabletSize } from 'global-styles';

const StyledAlert = styled.div`
  display: flex;
  justify-content: center;
  background: ${({ type }) => SQTOKEN[`alias-color-bg-${type}-weak`]};
  border-radius: 8px;
  padding: 8px;
  @media (min-width: 374px) {
    width: 100%;
  }
  @media (min-width: ${tabletSize}) {
    width: 374px;
  }
`;
const StyledIcon = styled(Icon)`
  margin-right: 4px;
  width: 16px;
  height: 16px;
  color: ${({ type }) => SQTOKEN[`alias-color-icon-${type}`]};
`;
const StyledText = styled.span`
  color: ${({ type }) => SQTOKEN[`alias-color-text-on-${type}-weak`]};
  ${CommonLabelStyles};
`;
const TypeToIconName = {
  info: 'InformationCircle',
  attention: 'ExclamationTriangle',
  neutral: 'LightBulb',
  critical: 'XCircle',
  success: 'CheckCircle',
  infoCritical: 'InformationCircle',
};
const SqAlert = (props) => {
  let { type = 'neutral' } = props;
  const { text, style } = props;
  type = type === 'infoCritical' ? 'critical' : type;
  const IconKey = props.type === 'infoCritical' ? 'info' : type;
  return (
    <StyledAlert type={type} style={style}>
      <StyledIcon
        className={type}
        color={SQTOKEN[`alias-color-icon-${type}`]}
        name={TypeToIconName[IconKey]}
      />
      <StyledText tabIndex="0" type={type}>
        {text}
      </StyledText>
    </StyledAlert>
  );
};
SqAlert.propTypes = {
  type: PropTypes.oneOf([
    'info',
    'attention',
    'neutral',
    'critical',
    'success',
    'infoCritical',
  ]),
  text: PropTypes.object.isRequired,
  style: PropTypes.object,
};

export default SqAlert;
