import {
  UPDATE_PRIVACY_CONSENTS,
  UPDATE_PRIVACY_CONSENTS_ERROR,
  UPDATE_PRIVACY_CONSENTS_SUCCESS,
} from './constants';

export function updatePrivacyConsents(callback) {
  return {
    type: UPDATE_PRIVACY_CONSENTS,
    callback,
  };
}

export function updatePrivacyConsentsSuccess() {
  return {
    type: UPDATE_PRIVACY_CONSENTS_SUCCESS,
  };
}

export function updatePrivacyConsentsError(error) {
  return {
    type: UPDATE_PRIVACY_CONSENTS_ERROR,
    error,
  };
}
