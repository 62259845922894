import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import ActionCable from 'actioncable';

import { fetchUpcomingSessions } from 'containers/Dashboard/actions';
import { fetchUpcomingAppointments } from 'containers/Sessions/actions';
import { checkNewChatMessage } from 'containers/TopNavigationBar/actions';
import { fetchUserSettings } from 'containers/UpdatePopover/actions';

import { getToken } from '../../../utils/auth';
import { SERVER_URL } from '../../../utils/environment';

class NotificationManager extends Component {
  constructor() {
    super();
    this.cable = null;
    this.channel = null;
  }

  componentDidMount() {
    if (this.props.tokenLoaded) {
      this.subscribe();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.tokenLoaded !== this.props.tokenLoaded &&
      this.props.tokenLoaded
    ) {
      this.subscribe();
    }
  }

  subscribe() {
    const token = getToken();

    if (token) {
      this.cable = ActionCable.createConsumer(
        `${SERVER_URL}/cable?token=${token}`,
      );
      this.channel = this.cable.subscriptions.create(
        { channel: 'WebNotificationChannel' },
        {
          received: this.handleReceived,
          initialized: () => {},
          connected: () => {},
          rejected: () => {
            if (this.cable) this.cable.disconnect();
          },
          disconnected: () => {},
        },
      );
    }
  }

  unsubscribe() {
    if (this.channel) {
      this.channel.unsubscribe();
      this.channel = null;
    }

    if (this.cable) {
      this.cable.disconnect();
      this.cable = null;
    }
  }

  handleReceived = (notification) => {
    const currentPath = this.props.location.pathname;

    if (
      notification.type === 'new_chat_message' &&
      !/^\/messaging/.test(currentPath)
    ) {
      this.props.checkNewChatMessage();
    } else if (notification.type === 'reschedule_appointment') {
      if (/^\/dashboard/.test(currentPath) || currentPath === '/') {
        this.props.fetchUpcomingSessions();
      }

      if (/^\/sessions/.test(currentPath)) {
        this.props.fetchUpcomingAppointments();
      }
    } else if (notification.type === 'new_update_therapy') {
      this.props.fetchUserSettings();
    }
  };

  render() {
    return <noscript />;
  }
}

NotificationManager.propTypes = {
  location: PropTypes.object.isRequired,
  fetchUpcomingSessions: PropTypes.func.isRequired,
  fetchUpcomingAppointments: PropTypes.func.isRequired,
  checkNewChatMessage: PropTypes.func.isRequired,
  fetchUserSettings: PropTypes.func.isRequired,
  tokenLoaded: PropTypes.bool.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    fetchUpcomingSessions: () => dispatch(fetchUpcomingSessions()),
    fetchUpcomingAppointments: () => dispatch(fetchUpcomingAppointments()),
    checkNewChatMessage: () => dispatch(checkNewChatMessage()),
    fetchUserSettings: () => dispatch(fetchUserSettings()),
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default withRouter(compose(withConnect)(NotificationManager));
