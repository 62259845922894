import { Link } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components';

import SVG from 'react-inlinesvg';
import { withNavigationLinks } from './withNavigationLinks';
import { black, mediumBlue, tabletSize } from '../../global-styles';

const NavigationLink = styled(Link)`
  font-family: 'Barlow', sans-serif;
  color: ${(props) => (props.active ? mediumBlue : black)};
  font-size: 18px;
  font-weight: ${(props) => (props.active ? 'bold' : '600')};
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-decoration: none;
  :visited {
    text-decoration: none;
  }
  :focus {
    text-decoration: none;
    background-color: transparent;
  }
  svg g {
    stroke: ${(props) => (props.active ? mediumBlue : black)};
    path {
      stroke: ${(props) => (props.active ? mediumBlue : black)};
    }
  }
  .isvg {
    @media (max-width: ${tabletSize}) {
      display: none;
    }
  }
  &:hover,
  &:focus {
    color: ${mediumBlue};
    font-weight: bold;
    letter-spacing: -0.001527em;
    svg g {
      stroke: ${mediumBlue};
      path {
        stroke: ${mediumBlue};
      }
    }
  }
`;

const LinkWrapper = styled.div`
  display: inline-block;
  :last-child {
    margin-right: 0px;
  }
`;

const NavigationIcon = styled(SVG)`
  height: 22px;
  width: 22px;
  margin-right: 10px;
`;

const Tablist = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
`;

const NavigationLinks = ({ navigationLinks }) => (
  <Tablist role="tablist" aria-label="Tab list">
    {navigationLinks.map((navigationItem, i) => {
      let active;
      const to = `${navigationItem.to}`;
      const pathname = `${window.location.pathname}`;

      if (
        (to.length === 1 && pathname === to) ||
        (to.length !== 1 && pathname.startsWith(to))
      ) {
        active = 'true';
      }

      return (
        <LinkWrapper key={i}>
          <NavigationLink
            role="tab"
            active={active}
            to={navigationItem.to}
            area-current={active}
          >
            <NavigationIcon
              alt={navigationItem.name}
              src={navigationItem.icon}
            />
            {navigationItem.formattedMessage}
          </NavigationLink>
        </LinkWrapper>
      );
    })}
  </Tablist>
);

export default withNavigationLinks(NavigationLinks);
