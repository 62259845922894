/*
 * StaticPage Messages
 *
 * This contains all the text for the StaticPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.staticPage';

export default defineMessages({
  goToDashboard: {
    defaultMessage: 'Go to Dashboard',
  },
  weApologize: {
    defaultMessage: 'We apologize for this experience',
  },
  weHaveRecieved: {
    defaultMessage:
      'We have received your feedback and let our client support team know. They will reach out to you to gather more information.',
  },
});
