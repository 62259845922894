/*
 * ErrorDialog Messages
 *
 * This contains all the text for the ErrorDialog component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.ErrorDialog';

export default defineMessages({
  close: {
    defaultMessage: 'Close',
  },
  generalErrorMessage: {
    defaultMessage: 'There was an error with your request',
  },
});
