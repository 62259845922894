/*
 *
 * LanguageProvider constants
 *
 */

export const CHANGE_LOCALE = 'app/LanguageToggle/CHANGE_LOCALE';

export const GET_LANGUAGES = 'app/Languages/GET_LANGUAGES';
export const GET_LANGUAGES_SUCCESS = 'app/Languages/GET_LANGUAGES_SUCCESS';
export const GET_LANGUAGES_ERROR = 'app/Languages/GET_LANGUAGES_ERROR';
