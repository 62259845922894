/*
 *
 * Messaging actions
 *
 */

import {
  DEFAULT_ACTION,
  FETCH_CHATS,
  FETCH_CHATS_SUCCESS,
  FETCH_CHATS_ERROR,
  FETCH_TWILIO_TOKEN,
  FETCH_TWILIO_TOKEN_SUCCESS,
  FETCH_TWILIO_TOKEN_ERROR,
  FETCH_CHAT,
  FETCH_CHAT_SUCCESS,
  FETCH_CHAT_ERROR,
  FETCH_MESSAGES,
  FETCH_MESSAGES_SUCCESS,
  FETCH_MESSAGES_ERROR,
  CREATE_MESSAGE,
  CREATE_MESSAGE_SUCCESS,
  CREATE_MESSAGE_ERROR,
  MARK_AS_READ,
  MARK_AS_READ_SUCCESS,
  MARK_AS_READ_ERROR,
} from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function fetchChats(callback) {
  return {
    type: FETCH_CHATS,
    callback,
  };
}

export function fetchChatsSuccess(chats) {
  return {
    type: FETCH_CHATS_SUCCESS,
    chats,
  };
}

export function fetchChatsError(error) {
  return {
    type: FETCH_CHATS_ERROR,
    error,
  };
}

export function fetchTwilioToken(chatId, callback) {
  return {
    type: FETCH_TWILIO_TOKEN,
    chatId,
    callback,
  };
}

export function fetchTwilioTokenSuccess(token) {
  return {
    type: FETCH_TWILIO_TOKEN_SUCCESS,
    token,
  };
}
export function fetchTwilioTokenError(error) {
  return {
    type: FETCH_TWILIO_TOKEN_ERROR,
    error,
  };
}

export function fetchChat(id, callback) {
  return {
    type: FETCH_CHAT,
    id,
    callback,
  };
}

export function fetchChatSuccess(chats) {
  return {
    type: FETCH_CHAT_SUCCESS,
    chats,
  };
}

export function fetchChatError(error) {
  return {
    type: FETCH_CHAT_ERROR,
    error,
  };
}

export function fetchMessages(id, callback = () => {}) {
  return {
    type: FETCH_MESSAGES,
    id,
    callback,
  };
}

export function fetchMessagesSuccess(messages) {
  return {
    type: FETCH_MESSAGES_SUCCESS,
    messages,
  };
}

export function fetchMessagesError(error) {
  return {
    type: FETCH_MESSAGES_ERROR,
    error,
  };
}

export function createMessage(values, callback) {
  return {
    type: CREATE_MESSAGE,
    values,
    callback,
  };
}

export function createMessageSuccess(message) {
  return {
    type: CREATE_MESSAGE_SUCCESS,
    message,
  };
}

export function createMessageError(error) {
  return {
    type: CREATE_MESSAGE_ERROR,
    error,
  };
}

export function markAsRead(chatId, callback = () => {}) {
  return {
    type: MARK_AS_READ,
    chatId,
    callback,
  };
}

export function markAsReadSuccess() {
  return {
    type: MARK_AS_READ_SUCCESS,
  };
}

export function markAsReadError(error) {
  return {
    type: MARK_AS_READ_ERROR,
    error,
  };
}
