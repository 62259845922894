import {
  FETCH_DEFAULT_CARD,
  FETCH_DEFAULT_CARD_ERROR,
  FETCH_DEFAULT_CARD_SUCCESS,
  UPDATE_DEFAULT_CARD,
  UPDATE_DEFAULT_CARD_SUCCESS,
  UPDATE_DEFAULT_CARD_ERROR,
  FETCH_FAILED_APPOINTMENTS,
  FETCH_FAILED_APPOINTMENTS_SUCCESS,
  FETCH_FAILED_APPOINTMENTS_ERROR,
  RETRY_FAILED_PAYMENTS,
  RETRY_FAILED_PAYMENTS_ERROR,
  RETRY_FAILED_PAYMENTS_SUCCESS,
} from './constants';

export function fetchDefaultCard() {
  return {
    type: FETCH_DEFAULT_CARD,
  };
}

export function fetchDefaultCardSuccess(card) {
  return {
    type: FETCH_DEFAULT_CARD_SUCCESS,
    card,
  };
}

export function fetchDefaultCardError(error) {
  return {
    type: FETCH_DEFAULT_CARD_ERROR,
    error,
  };
}

export function fetchFailedAppointments() {
  return {
    type: FETCH_FAILED_APPOINTMENTS,
  };
}

export function fetchFailedAppointmentsSuccess(appointments) {
  return {
    type: FETCH_FAILED_APPOINTMENTS_SUCCESS,
    appointments,
  };
}

export function fetchFailedAppointmentsError(error) {
  return {
    type: FETCH_FAILED_APPOINTMENTS_ERROR,
    error,
  };
}

export function updateDefaultCard(token, callback) {
  return {
    type: UPDATE_DEFAULT_CARD,
    token,
    callback,
  };
}

export function updateDefaultCardSuccess(card) {
  return {
    type: UPDATE_DEFAULT_CARD_SUCCESS,
    card,
  };
}

export function updateDefaultCardError(error) {
  return {
    type: UPDATE_DEFAULT_CARD_ERROR,
    error,
  };
}

export function retryFailedPayments(ids, callback) {
  return {
    type: RETRY_FAILED_PAYMENTS,
    ids,
    callback,
  };
}

export function retryFailedPaymentsSuccess(ids) {
  return {
    type: RETRY_FAILED_PAYMENTS_SUCCESS,
    ids,
  };
}

export function retryFailedPaymentsError(error) {
  return {
    type: RETRY_FAILED_PAYMENTS_ERROR,
    error,
  };
}
