/*
 *
 * Messaging constants
 *
 */

export const DEFAULT_ACTION = 'app/Messaging/DEFAULT_ACTION';

export const FETCH_CHATS = 'app/Messaging/FETCH_CHATS';
export const FETCH_CHATS_SUCCESS = 'app/Messaging/FETCH_CHATS_SUCCESS';
export const FETCH_CHATS_ERROR = 'app/Messaging/FETCH_CHATS_ERROR';
export const FETCH_TWILIO_TOKEN = 'app/Messaging/FETCH_TWILIO_TOKEN ';
export const FETCH_TWILIO_TOKEN_SUCCESS =
  'app/Messaging/FETCH_TWILIO_TOKEN_SUCCESS';
export const FETCH_TWILIO_TOKEN_ERROR =
  'app/Messaging/FETCH_TWILIO_TOKEN_ERROR';
export const FETCH_CHAT = 'app/Messaging/FETCH_CHAT';
export const FETCH_CHAT_SUCCESS = 'app/Messaging/FETCH_CHAT_SUCCESS';
export const FETCH_CHAT_ERROR = 'app/Messaging/FETCH_CHAT_ERROR';
export const FETCH_MESSAGES = 'app/Messaging/FETCH_MESSAGES';
export const FETCH_MESSAGES_SUCCESS = 'app/Messaging/FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_ERROR = 'app/Messaging/FETCH_MESSAGES_ERROR';
export const CREATE_MESSAGE = 'app/Messaging/CREATE_MESSAGES';
export const CREATE_MESSAGE_SUCCESS = 'app/Messaging/CREATE_MESSAGES_SUCCESS';
export const CREATE_MESSAGE_ERROR = 'app/Messaging/CREATE_MESSAGES_ERROR';
export const MARK_AS_READ = 'app/Messaging/MARK_AS_READ';
export const MARK_AS_READ_SUCCESS = 'app/Messaging/MARK_AS_READ_SUCCESS';
export const MARK_AS_READ_ERROR = 'app/Messaging/MARK_AS_READ_ERROR';
