import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form/immutable';

import H1 from 'components/H1';
import ContentBoldBlue from 'components/ContentBoldBlue';
import H3 from 'components/H3';
import Content from 'components/Content';
import Button from 'components/Button';
import PreviousButtonContent from 'components/PreviousButtonContent';
import { logEvent } from 'utils/amplitude';

import { updateUser } from 'containers/UserInformation/actions';
import { compose } from 'redux';
import injectSaga from 'utils/injectSaga';
import TextInput from './TextInput';
import Logo from '../Matching/Logo';
import { phoneSize, errorRed, mediumBlue } from '../../global-styles';
import messages from './messages';
import { verifyPhoneNumber, createPhoneNumber } from './actions';
import {
  normalizeCountryCode,
  normalizePin,
  matchingProperties,
} from '../Matching/helpers';
import {
  validatePhoneNumber,
  normalizePhoneNumber,
} from 'utils/reduxFormHelpers';
import saga from './saga';

const Panel = styled.div`
  min-height: 700px;
  width: 100%;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 0 50px 0 rgba(40, 71, 140, 0.2);
  max-width: 945px;
  margin: 0 auto;
  padding: 40px;
  @media (max-width: ${phoneSize}) {
    padding: 20px 20px 100px 20px;
  }
  button {
    :focus {
      outline: 5px solid ${mediumBlue};
    }
  }
`;

const Container = styled.div`
  max-width: 560px;
  margin: 0 auto;
  text-align: center;
`;

const PositiveText = styled.p`
  font-family: 'Source Sans Pro', sans-serif;
  color: green;
  display: ${(props) => (props.visible ? 'inline-block' : 'none')};
  margin-top: 10px;
`;

const ErrorText = styled.p`
  font-family: 'Source Sans Pro', sans-serif;
  color: ${errorRed};
  display: ${(props) => (props.visible ? 'inline-block' : 'none')};
  margin-top: 10px;
`;

const InputWrapper = styled.div`
  display: inline-block;
`;

const RowWrapper = styled.div`
  position: relative;
  text-align: left;
  display: inline-flex;
  align-items: flex-end;
  margin: 0 auto;
  @media (max-width: ${phoneSize}) {
    display: block;
    input: last-of-type {
      margin-bottom: 20px;
    }
  }
  p {
    display: none;
  }
`;

const Form = styled.form`
  display: contents;
`;

const PAGE = 'phone';

class PhoneNumberVerification extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      verificationSent: false,
      phoneNumberError: false,
      landLine: false,
      verified: false,
      verifiedErorr: false,
      number: '',
    };
  }

  onSubmit = (values) => {
    const valuesJS = values.toJS();
    valuesJS.call_to = this.state.landLine ? 'landline' : 'mobile';
    this.props.createPhoneNumber(
      valuesJS,
      () =>
        this.setState({
          verified: false,
          phoneNumberError: false,
          verificationSent: true,
          number: valuesJS.number,
        }),
      () =>
        this.setState({
          verified: false,
          verificationSent: false,
          phoneNumberError: true,
        }),
    );
  };

  advance = () => {
    const { auditProgress, currentMatch, goToNextPage } = this.props;
    if (auditProgress) {
      logEvent(
        'advance in matching',
        matchingProperties(PAGE, currentMatch.toJS()),
      );
      auditProgress(PAGE, [], currentMatch.get('id'));
    }
    goToNextPage();
  };

  checkCode = (code) => {
    // eslint-disable-next-line no-unused-expressions
    if (code.length === 7 && !this.state.verified) {
      this.props.verifyPhoneNumber(code, this.updateVerified, this.updateError);
    }
  };

  updateVerified = () => {
    this.setState({ verified: true, verifiedErorr: false });
    this.props.dispatch(
      updateUser({ primary_phone_number: { number: this.state.number } }),
    );
  };

  updateError = () => {
    this.setState({ verifiedErorr: true, verified: false });
  };

  changeToLandline = () => {
    this.setState({ landLine: true, verificationSent: false });
  };

  render() {
    const { openExitDialog, goToPrevPage, handleSubmit, isReminder } =
      this.props;

    const {
      verified,
      verifiedErorr,
      landLine,
      verificationSent,
      phoneNumberError,
    } = this.state;
    return (
      <Panel style={{ position: 'relative' }}>
        <Logo openExitDialog={openExitDialog} />
        <H1 style={{ marginBottom: '40px', textAlign: 'center' }}>
          <FormattedMessage {...messages.phoneVerification} />
        </H1>
        {
          <Container>
            <ContentBoldBlue
              style={{ fontSize: 18, marginBottom: '40px', lineHeight: 1.2 }}
            >
              <FormattedMessage
                {...messages[isReminder ? 'reminder' : 'phoneVerificationAuth']}
              />
            </ContentBoldBlue>
            <RowWrapper>
              <Form onSubmit={handleSubmit(this.onSubmit)}>
                <InputWrapper style={{ marginRight: '20px' }}>
                  <Field
                    inputStyle={{ width: '70px' }}
                    component={TextInput}
                    label={messages.phoneCountryCode}
                    name="country"
                    normalize={normalizeCountryCode}
                  />
                </InputWrapper>
                <InputWrapper>
                  <Field
                    component={TextInput}
                    inputStyle={{ width: '100%' }}
                    label={messages.phoneNumber}
                    name="number"
                    validate={validatePhoneNumber}
                    normalize={normalizePhoneNumber}
                  />
                </InputWrapper>
                <Button
                  tertiary
                  label={messages.phoneGetPin}
                  type="submit"
                  style={{
                    marginTop: '0px',
                    minWidth: '154px',
                    marginLeft: '20px',
                  }}
                />
              </Form>
            </RowWrapper>
            <PositiveText visible={verificationSent}>
              {this.state.landLine ? (
                <FormattedMessage {...messages.calledVerification} />
              ) : (
                <FormattedMessage {...messages.textedVerification} />
              )}
            </PositiveText>
            <ErrorText visible={phoneNumberError}>
              <FormattedMessage {...messages.somethingWentWrong} />
            </ErrorText>
            <H3 style={{ marginTop: '40px', marginBottom: '20px' }}>
              {landLine ? (
                <FormattedMessage {...messages.landlineSevenDigitPin} />
              ) : (
                <FormattedMessage {...messages.phoneSevenDigitPin} />
              )}
            </H3>
            <Field
              component={TextInput}
              inputStyle={{ width: '150px' }}
              label=""
              name="code"
              normalize={normalizePin}
              onChange={(event) => this.checkCode(event.target.value)}
            />
            <PositiveText visible={verified}>
              <FormattedMessage {...messages.phoneNumberVerified} />
            </PositiveText>
            <ErrorText visible={verifiedErorr}>
              <FormattedMessage {...messages.incorrectCode} />
            </ErrorText>
            <Content bold color={mediumBlue}>
              {!landLine && (
                <button
                  type="button"
                  onClick={this.changeToLandline}
                  style={{ marginTop: '20px' }}
                >
                  <FormattedMessage {...messages.phoneDontHaveMobile} />
                </button>
              )}
            </Content>
            <Button
              label={isReminder ? messages.verify : messages.next}
              secondary={verified}
              onClick={this.advance}
              style={{ marginTop: '40px' }}
              disabled={verified ? null : 'disabled'}
            />
            {!isReminder && (
              <PreviousButtonContent
                label={messages.previous}
                style={{ marginBottom: '40px' }}
                onClick={() => goToPrevPage && goToPrevPage()}
              />
            )}
            <Content bold color={mediumBlue}>
              <button
                type="button"
                onClick={this.advance}
                style={{ marginTop: '20px' }}
              >
                <FormattedMessage {...messages.verifyPhoneLater} />
              </button>
            </Content>
          </Container>
        }
      </Panel>
    );
  }
}

PhoneNumberVerification.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  openExitDialog: PropTypes.func.isRequired,
  goToNextPage: PropTypes.func.isRequired,
  goToPrevPage: PropTypes.func,
  isReminder: PropTypes.bool,
};

function mapStateToProps(state) {
  const UserStore = state.get('user');
  const phoneNumberInfo = UserStore
    ? UserStore.toJS().primary_phone_number
    : null;
  const { number, country_code: country } = phoneNumberInfo || {};
  return {
    initialValues: { number, country },
  };
}
function mapDispatchToProps(dispatch) {
  return {
    verifyPhoneNumber: (value, callback, errorCallback) =>
      dispatch(verifyPhoneNumber(value, callback, errorCallback)),
    createPhoneNumber: (value, callback, errorCallback) =>
      dispatch(createPhoneNumber(value, callback, errorCallback)),
  };
}
const withSaga = injectSaga({ key: 'PhoneNumber', saga });
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(
  withSaga,
  withConnect,
)(
  injectIntl(
    reduxForm({
      form: 'phoneNumberVerificationForm',
    })(PhoneNumberVerification),
  ),
);
