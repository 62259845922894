/**
 *
 * Divider
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Line = styled.div`
  border-bottom: solid 1px #dfe7f7;
  height: 2px;
  margin: 40px 0;
`;

function Divider({ style }) {
  return <Line style={style} />;
}

Divider.propTypes = {
  style: PropTypes.object,
};

export default Divider;
