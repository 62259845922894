import React from 'react';
import axios from 'axios';
import { SERVER_URL } from 'utils/environment';
import { validEmail } from 'utils/validation';
import { FormattedMessage } from 'react-intl';

import messages from '../SignUp/messages';

export const auditProgress = (
  page,
  choice,
  token,
  url = SERVER_URL,
  callback = () => {},
) => {
  axios
    .post(
      `${url}/api/v2/audit_progresses`,
      { page, choice: [choice] },
      { headers: { Authorization: token } },
    )
    .then(() => callback())
    .catch(() => callback());
};

export function validate(values) {
  const errors = {};
  // for required fields
  if (!values.get('first_name')) {
    errors.first_name = <FormattedMessage {...messages.required} />;
  }

  if (!values.get('last_name')) {
    errors.last_name = <FormattedMessage {...messages.required} />;
  }

  if (!values.get('email')) {
    errors.email = <FormattedMessage {...messages.required} />;
  }

  if (!values.get('password')) {
    errors.password = <FormattedMessage {...messages.required} />;
  }

  if (!values.get('retype_password')) {
    errors.retype_password = <FormattedMessage {...messages.required} />;
  }

  if (!values.get('other_referral_source')) {
    errors.other_referral_source = <FormattedMessage {...messages.required} />;
  }

  if (!values.get('consent_yn')) {
    errors.consent_yn = <FormattedMessage {...messages.required} />;
  }

  // input validation
  const email = values.get('email');
  const password = values.get('password');
  // eslint-disable-next-line camelcase
  const retype_password = values.get('retype_password');

  if (email) {
    if (!validEmail.test(email)) {
      errors.email = <FormattedMessage {...messages.enterAnEmailWithExample} />;
    }
  }

  if (password) {
    if (password.length < 8) {
      errors.password = (
        <FormattedMessage {...messages.passwordLengthRequirement} />
      );
      // eslint-disable-next-line camelcase
    } else if (password !== retype_password) {
      errors.retype_password = (
        <FormattedMessage {...messages.passwordConfirmationMustMatch} />
      );
    }
  }

  return errors;
}
