import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the preferences state domain
 */

const selectWMHDomain = (state) => state.get('wmhSignup', initialState);
/**
 * Default selector used by Preferences
 */

const makeSelectLoading = () =>
  createSelector(selectWMHDomain, (substate) => substate.get('loading'));

const makeSelectWMHSignup = () =>
  createSelector(selectWMHDomain, (substate) => substate.toJS());

export default makeSelectWMHSignup;
export { selectWMHDomain, makeSelectLoading };
