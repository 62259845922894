/**
 *
 * LoadingPanel
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Lottie from 'react-lottie';
import { FormattedMessage } from 'react-intl';

import H2 from 'components/H2';

import animationData from './main-loading.json';
import messages from './messages';

const StyledContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  z-index: 10;
  .panel-message {
    margin: 100px 10% 0 10%;
    text-align: center;
  }
`;

class LoadingPanel extends React.PureComponent {
  componentDidUpdate() {
    if (this.props.open) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { open, message, style } = this.props;
    const lottieOptions = {
      loop: true,
      autoplay: true,
      animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    if (!open) {
      return <noscript />;
    }

    return (
      <StyledContainer style={style}>
        <div className="panel-message">
          <H2>{message}</H2>
        </div>
        <div>
          <Lottie
            options={lottieOptions}
            width={200}
            height={200}
            isClickToPauseDisabled
          />
        </div>
      </StyledContainer>
    );
  }
}

LoadingPanel.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  style: PropTypes.object,
};

LoadingPanel.defaultProps = {
  message: <FormattedMessage {...messages.loading} />,
};

export default LoadingPanel;
