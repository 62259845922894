import {
  FETCH_DEFAULT_CARD,
  FETCH_DEFAULT_CARD_ERROR,
  FETCH_DEFAULT_CARD_SUCCESS,
  UPDATE_DEFAULT_CARD,
  UPDATE_DEFAULT_CARD_SUCCESS,
  UPDATE_DEFAULT_CARD_ERROR,
} from './constants';

export function fetchDefaultCard() {
  return {
    type: FETCH_DEFAULT_CARD,
  };
}

export function fetchDefaultCardSuccess(card) {
  return {
    type: FETCH_DEFAULT_CARD_SUCCESS,
    card,
  };
}

export function fetchDefaultCardError(error) {
  return {
    type: FETCH_DEFAULT_CARD_ERROR,
    error,
  };
}

export function updateDefaultCard(token) {
  return {
    type: UPDATE_DEFAULT_CARD,
    token,
  };
}

export function updateDefaultCardSuccess(card) {
  return {
    type: UPDATE_DEFAULT_CARD_SUCCESS,
    card,
  };
}

export function updateDefaultCardError(error) {
  return {
    type: UPDATE_DEFAULT_CARD_ERROR,
    error,
  };
}
