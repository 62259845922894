import { getBrowserFingerprint } from './fingerprint';
import { debounce } from 'lodash';
import { DHE_SESSION_PULSE } from 'utils/environment';
import * as userHelperClient from 'utils/userHelperClient';
import retry from 'retry';

const DOCUMENT_PULSE_EVENTS = ['click', 'keypress', 'scroll'];
const RETRY_CONFIG =
  process.env.NODE_ENV === 'test' ? { retries: 3, minTimeout: 10 } : undefined;

export const createInterval = (fingerprint, sessionId, getToken, logout) => {
  const operation = retry.operation(RETRY_CONFIG);
  let interval = setTimeout(async () => {
    operation.attempt(async () => {
      const restart = () => {
        interval = createInterval(fingerprint, sessionId, getToken, logout)[1];
      };

      try {
        const response = await userHelperClient.checkSession(
          sessionId,
          fingerprint,
          await getToken(),
        );

        if (response.status === 404) {
          logout();
          return;
        }

        if (response.status === 200) {
          restart();
          return;
        }

        throw new Error(
          `Bad status from checking GSPlus session - ${response.status}`,
        );
      } catch (err) {
        // failed to check session, retry
        if (!operation.retry(err)) {
          restart();
        }
      }
    });
  }, DHE_SESSION_PULSE);

  return [
    () => {
      operation.stop();
      clearTimeout(interval);
    },
    interval,
  ];
};

const addEvents = (fingerprint, sessionId, getToken, logout) => {
  const handler = debounce(() => {
    const operation = retry.operation(RETRY_CONFIG);
    operation.attempt(async () => {
      try {
        const response = await userHelperClient.updateSession(
          sessionId,
          fingerprint,
          await getToken(),
        );

        if (response.status === 404) logout();

        if (response.status !== 201)
          throw new Error(
            `Failed to update GSPlus session - ${response.status}`,
          );
      } catch (err) {
        operation.retry(err);
      }
    });
  }, 1000);

  DOCUMENT_PULSE_EVENTS.forEach((ev) => document.addEventListener(ev, handler));
  return () =>
    DOCUMENT_PULSE_EVENTS.forEach((ev) =>
      document.removeEventListener(ev, handler),
    );
};

export const createSession = async (getToken, fingerprint) => {
  const operation = retry.operation(RETRY_CONFIG);

  return new Promise((resolve, reject) => {
    operation.attempt(async () => {
      try {
        const token = await getToken();
        const { sessionId } = await userHelperClient.createSession(
          fingerprint,
          token,
        );
        resolve(sessionId);
      } catch (err) {
        if (!operation.retry(err)) reject(err);
      }
    });
  });
};

export const initSessionMonitoring = async (getToken, logout) => {
  const fingerprint = getBrowserFingerprint({ enableScreen: false });

  const sessionId = await createSession(getToken, fingerprint);
  const removeEvents = addEvents(fingerprint, sessionId, getToken, logout);
  const [clear] = createInterval(fingerprint, sessionId, getToken, logout);
  return {
    cleanup: () => {
      removeEvents();
      clear();
    },
    terminate: async () =>
      userHelperClient.terminateSession(
        sessionId,
        fingerprint,
        await getToken(),
      ),
  };
};
