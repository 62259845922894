/*
 *
 * LanguageProvider actions
 *
 */

import {
  CHANGE_LOCALE,
  GET_LANGUAGES,
  GET_LANGUAGES_ERROR,
  GET_LANGUAGES_SUCCESS,
} from './constants';

export function changeLocale(languageLocale) {
  return {
    type: CHANGE_LOCALE,
    locale: languageLocale,
  };
}

export function getLanguages() {
  return {
    type: GET_LANGUAGES,
  };
}
export function getLanguagesSuccess(response) {
  return {
    response,
    type: GET_LANGUAGES_SUCCESS,
  };
}

export function getLanguagesError(error) {
  return {
    error,
    type: GET_LANGUAGES_ERROR,
  };
}
