/*
 * UpdatedTermsConditions Messages
 *
 * This contains all the text for the UpdatedTermsConditions container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.UpdatedTermsConditions';
export default defineMessages({
  updatedTC: {
    defaultMessage: 'Updated Terms & Conditions',
  },
  lastRevised: {
    defaultMessage: 'Last Revised:',
  },
  accept: {
    defaultMessage: 'Accept',
  },
  decline: {
    defaultMessage: 'Decline',
  },
  pleaseRead: {
    defaultMessage:
      'Please read the following terms of agreement. We have adjusted our {policy} as of April 1, 2022.',
  },
  pleaseReadBenevity: {
    defaultMessage:
      'Please read the following terms of agreement. We have adjusted our {policy} for Benevity users as of April 1, 2022.',
  },
  contactSupport: {
    defaultMessage:
      'If you have any questions, please contact <emailLink>support@inkblottherapy.com</emailLink> or select the Support icon to chat with a live agent.',
  },
  mustAccept: {
    defaultMessage:
      'You must accept the revised Terms & Conditions in order to continue using Inkblot.',
  },
  goBack: {
    defaultMessage: 'Go back',
  },
  logout: {
    defaultMessage: 'Log out',
  },
  tncCheckBoxErrorMessages: {
    defaultMessage: 'Please indicate your agreement by checking the box. ',
  },
  supportEmail: {
    defaultMessage: 'support@inkblottherapy.com',
    description: 'User support email address',
  },
});
