import { takeLatest, put as reduxPut, call } from 'redux-saga/effects';
import { API_URL } from '../../utils/environment';
import { fetchCompanySuccess, fetchCompanyError } from './actions';
import { FETCH_COMPANY } from './constants';
import { get } from '../../utils/request';

function* fetchCompany() {
  const requestURL = `${API_URL}/company`;

  try {
    const company = yield call(get, requestURL);
    yield reduxPut(fetchCompanySuccess(company));
  } catch (error) {
    yield reduxPut(fetchCompanyError(error));
  }
}

// Individual exports for testing
export default function* companySaga() {
  yield takeLatest(FETCH_COMPANY, fetchCompany);
}
