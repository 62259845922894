import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import injectSaga from 'utils/injectSaga';

import saga from './saga';
import { fetchCompany } from './actions';

const Company = ({ fetchCompany, children }) => {
  useEffect(() => {
    fetchCompany();
  }, [fetchCompany]);

  return <>{children}</>;
};

function mapDispatchToProps(dispatch) {
  return {
    fetchCompany: () => dispatch(fetchCompany()),
  };
}

const withConnect = connect(() => ({}), mapDispatchToProps);

const withSaga = injectSaga({ key: 'company', saga });

export default compose(withSaga, withConnect)(Company);
