import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the userInformation state domain
 */

const selectUserInformationDomain = (state) => state.get('user', initialState);

/**
 * Other specific selectors
 */

const makeSelectFromAtb = () =>
  createSelector(
    selectUserInformationDomain,
    (substate) => substate.getIn(['company', 'name']) === 'ATB',
  );

/**
 * Default selector used by UserInformation
 */

const makeSelectUserInformation = () =>
  createSelector(selectUserInformationDomain, (substate) => substate.toJS());

const makeSelectUser = () =>
  createSelector(selectUserInformationDomain, (substate) => substate);

const makeSelectAzureProfileId = () =>
  createSelector(makeSelectUser(), (user) =>
    user ? user.toJS().azure_profile_id : user,
  );

export const makeSelectUserAdvisoryServices = () =>
  createSelector(
    makeSelectUserInformation(),
    (user) => user.additional_services,
  );

export const makeSelectUserHasAdvisoryServices = () =>
  createSelector(makeSelectUserAdvisoryServices(), (services = {}) =>
    // Filtering out crisisSupport as it's not an advisory service
    Object.values(
      Object.keys(services)
        .filter((key) => key !== 'crisisSupport')
        .reduce(
          (obj, key) => ({
            ...obj,
            [key]: services[key],
          }),
          {},
        ),
    ).some((v) => v),
  );

export const makeSelectUserHasAdvisoryService = (key) =>
  createSelector(
    makeSelectUserAdvisoryServices(),
    (services = {}) => services[key],
  );

export default makeSelectUserInformation;

export {
  selectUserInformationDomain,
  makeSelectUser,
  makeSelectFromAtb,
  makeSelectAzureProfileId,
};
