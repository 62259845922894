import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import { Map } from 'immutable';

import MailSvg from './svgs/mail.svg';

const StyledContainer = styled.div`
  width: 25px;
  height: 20px;
  display: inline-block;
  cursor: pointer;
  position: relative;

  svg {
    margin-top: -4px;
  }
`;

const UnreadIcon = styled.div`
  width: 13px;
  height: 13px;
  background-image: linear-gradient(to left, #e938cc, #fc587f);
  border-radius: 50%;

  position: absolute;
  right: -4px;
  top: -3px;
`;

// eslint-disable-next-line arrow-body-style
const MailIcon = ({ newMessageInfo }) => {
  return (
    <StyledContainer aria-label="View inbox message">
      <SVG src={MailSvg} role="img" />
      {newMessageInfo.getIn(['data', 'unread_yn']) && <UnreadIcon />}
    </StyledContainer>
  );
};

MailIcon.propTypes = {
  newMessageInfo: PropTypes.instanceOf(Map).isRequired,
};

export default MailIcon;
