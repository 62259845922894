import { takeLatest, call, put } from 'redux-saga/effects';
import {
  DESTROY_MATCH,
  FETCH_PRACTITIONER,
  UPLOAD_FILE,
  FETCH_COUPLES_INFO,
  FETCH_CARE_TEAM,
  FETCH_COMPANY,
  ENTER_PARTNER_CODE,
} from './constants';
import { API_URL } from '../../utils/environment';
import {
  destroyMatchSuccess,
  destroyMatchError,
  fetchPractitionerSuccess,
  fetchPractitionerError,
  uploadFileSuccess,
  uploadFileError,
  fetchCouplesInfoSuccess,
  fetchCouplesInfoError,
  fetchCareTeamSuccess,
  fetchCareTeamError,
  fetchCompanySuccess,
  fetchCompanyError,
  enterPartnerCodeSuccess,
  enterPartnerCodeError,
} from './actions';
import { get, destroy, post } from '../../utils/request';

function* destroyMatch(values) {
  const requestUrl = `${API_URL}/matches/${values.id}?feedback=${values.feedback}`;
  try {
    const match = yield call(destroy, requestUrl);
    yield put(destroyMatchSuccess(match));
    yield put({ type: FETCH_CARE_TEAM });
  } catch (error) {
    yield put(destroyMatchError(error));
  }
}

function* fetchPractitioner() {
  const requestURL = `${API_URL}/practitioners`;

  try {
    const practitioner = yield call(get, requestURL);
    yield put(fetchPractitionerSuccess(practitioner));
  } catch (error) {
    yield put(fetchPractitionerError(error));
  }
}

function* uploadFile({ providerId, file }) {
  const requestURL = `${API_URL}/practitioners/upload?provider_id=${providerId}`;

  try {
    const response = yield call(post, requestURL, file);
    yield put(uploadFileSuccess(response));
  } catch (error) {
    yield put(uploadFileError(error));
  }
}

function* fetchCouplesInfo() {
  const requestURL = `${API_URL}/couples/info`;

  try {
    const couplesInfo = yield call(get, requestURL);
    yield put(fetchCouplesInfoSuccess(couplesInfo));
  } catch (error) {
    yield put(fetchCouplesInfoError(error));
  }
}

function* fetchCareTeam() {
  const requestURL = `${API_URL}/practitioners/care_team`;

  try {
    const careTeam = yield call(get, requestURL);
    yield put(fetchCareTeamSuccess(careTeam));
  } catch (error) {
    yield put(fetchCareTeamError(error));
  }
}

function* fetchCompany() {
  const requestURL = `${API_URL}/company`;

  try {
    const company = yield call(get, requestURL);
    yield put(fetchCompanySuccess(company));
  } catch (error) {
    yield put(fetchCompanyError(error));
  }
}

function* enterPartnerCode({ match_code, callback, errCallback }) {
  const requestUrl = `${API_URL}/couples/code`;
  const body = { match_code };
  try {
    const partner = yield call(post, requestUrl, body);
    yield put(enterPartnerCodeSuccess(partner));
    if (callback) callback(partner);
  } catch (error) {
    yield put(enterPartnerCodeError(error));
    if (errCallback) errCallback(error);
  }
}

// Individual exports for testing
export default function* myCareProviderSaga() {
  yield takeLatest(DESTROY_MATCH, destroyMatch);
  yield takeLatest(FETCH_PRACTITIONER, fetchPractitioner);
  yield takeLatest(UPLOAD_FILE, uploadFile);
  yield takeLatest(FETCH_COUPLES_INFO, fetchCouplesInfo);
  yield takeLatest(FETCH_CARE_TEAM, fetchCareTeam);
  yield takeLatest(FETCH_COMPANY, fetchCompany);
  yield takeLatest(ENTER_PARTNER_CODE, enterPartnerCode);
}
