import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the dashboard state domain
 */

const selectDashboardDomain = (state) => state.get('dashboard', initialState);

/**
 * Other specific selectors
 */

const makeSelectBenefitsCoverage = () =>
  createSelector(selectDashboardDomain, (substate) =>
    substate.get('benefitsCoverage'),
  );

const makeSelectCompany = () =>
  createSelector(selectDashboardDomain, (substate) => substate.get('company'));

const makeSelectPractitioner = () =>
  createSelector(selectDashboardDomain, (substate) =>
    substate.get('practitioner'),
  );

const makeSelectUpcomingSessions = () =>
  createSelector(selectDashboardDomain, (substate) => substate.get('upcoming'));

const makeSelectCouplesInfo = () =>
  createSelector(selectDashboardDomain, (substate) =>
    substate.get('couplesInfo'),
  );

const makeSelectCareTeam = () =>
  createSelector(selectDashboardDomain, (substate) => substate.get('careTeam'));

/**
 * Default selector used by Dashboard
 */

const makeSelectDashboard = () =>
  createSelector(selectDashboardDomain, (substate) => substate.toJS());

export default makeSelectDashboard;
export {
  selectDashboardDomain,
  makeSelectCompany,
  makeSelectPractitioner,
  makeSelectUpcomingSessions,
  makeSelectCouplesInfo,
  makeSelectCareTeam,
  makeSelectBenefitsCoverage,
};
