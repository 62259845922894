import PanelWrapper from 'components/PanelWrapper';
import React from 'react';
import InkblotLogo from '../PreSessionAssessment/inkblot-logo.svg';
import styled from 'styled-components';
import Button from 'components/Button';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import messages from 'containers/StaticPage/messages';
import { phoneSize } from 'global-styles';
import { Link } from 'react-router-dom';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px;
  width: 100%;
  height: 700px;
  background: #fff;
  border-radius: 20px;
  position: relative;
  text-align: center;

  img {
    @media (max-width: ${phoneSize}) {
      width: 150px;
    }
    width: 200px;
    position: absolute;
    left: 40px;
    top: 40px;
  }

  h1 {
    max-width: 564px;
    color: #2e60ca;
    font-size: 34px;
    margin-bottom: 20px;
  }

  p {
    max-width: 564px;
    font-family: 'Source Sans Pro';
    font-size: 16px;
  }
`;

const StaticPage = () => (
  <PanelWrapper>
    <Content>
      <img src={InkblotLogo} alt="Inkblot logo" />
      <h1>
        <FormattedMessage {...messages.weApologize} />
      </h1>
      <p>
        <FormattedMessage {...messages.weHaveRecieved} />
      </p>
      <Link to="/">
        <Button label={messages.goToDashboard} primary />
      </Link>
    </Content>
  </PanelWrapper>
);

StaticPage.PropTypes = {
  intl: PropTypes.object,
};

export default injectIntl(StaticPage);
