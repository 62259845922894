import { takeLatest, put, call } from 'redux-saga/effects';

import { API_URL } from '../../utils/environment';
import { post } from '../../utils/request';
import {
  updatePrivacyConsentsSuccess,
  updatePrivacyConsentsError,
} from './actions';
import { UPDATE_PRIVACY_CONSENTS } from './constants';

function* updatePrivacyConsents({ callback }) {
  const requestURL = `${API_URL}/accepted_privacy_consents`;

  try {
    yield call(post, requestURL, { privacy_policy_consent_yn: true });
    yield put(updatePrivacyConsentsSuccess());
    if (callback) callback();
  } catch (error) {
    yield put(updatePrivacyConsentsError(error));
    if (callback) callback(error);
  }
}

export default function* updatedPrivacyConsentsSaga() {
  yield takeLatest(UPDATE_PRIVACY_CONSENTS, updatePrivacyConsents);
}
