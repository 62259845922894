import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import Button from 'components/Button';
import Body from 'components/Body';
import Dialog from 'components/Dialog';

import messages from '../messages';

const Container = styled.div`
  text-align: center;

  h2,
  h4 {
    width: 90%;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  h2 {
    margin-bottom: 30px;
  }
`;

const ErrorDialog = ({ toggleOpen, open }) => (
  <Dialog onRequestClose={toggleOpen} open={open}>
    <Container>
      <Body bold>
        <FormattedMessage {...messages.upcomingError} />
      </Body>
      <Button label={messages.close} onClick={toggleOpen} tertiary />
    </Container>
  </Dialog>
);

ErrorDialog.propTypes = {
  toggleOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ErrorDialog;
