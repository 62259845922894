/*
 * Messaging Messages
 *
 * This contains all the text for the Messaging container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Messaging';

export default defineMessages({
  header: {
    defaultMessage: 'Messaging',
  },
  inbox: {
    defaultMessage: 'Inbox',
  },
  conversations: {
    defaultMessage: 'Conversations',
  },
  send: {
    defaultMessage: 'Send',
  },
  typeAMessage: {
    defaultMessage: 'Type a message...',
  },
  selectCounsellor: {
    defaultMessage: 'Your care provider does not have messaging enabled.',
  },
});
