/**
 *
 * Breadcrumbs
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';

import RightSVG from './right.svg';

const StyledNameSetRow = styled.div`
  margin-bottom: 40px;
  display: flex;
  align-items: baseline;
  height: 18px;
`;

const StyledNameSet = styled.div`
  display: flex;
  align-items: baseline;
  height: 100%;
`;

const StyledRightDiv = styled.div`
  margin: 0 20px;
  height: 100%;
`;

const StyledNameDiv = styled.div`
  height: 100%;
`;

const StyledName = styled.p`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: rgba(15, 32, 69, 0.75);
  margin: 0px;
`;

/* eslint-disable react/prefer-stateless-function */
class Breadcrumbs extends React.PureComponent {
  render() {
    const { names } = this.props;
    if (names.length === 1) {
      return <StyledNameSetRow />;
    }
    return (
      <StyledNameSetRow>
        {names.map((name, index) => (
          <StyledNameSet key={`key-${index + 1}`}>
            {index !== 0 && (
              <StyledRightDiv>
                <SVG src={RightSVG} alt="right icon" />
              </StyledRightDiv>
            )}
            <StyledNameDiv>
              <StyledName>{name}</StyledName>
            </StyledNameDiv>
          </StyledNameSet>
        ))}
      </StyledNameSetRow>
    );
  }
}

Breadcrumbs.propTypes = {
  names: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  ).isRequired,
};

export default Breadcrumbs;
