/*
 * MyCareProvider Messages
 *
 * This contains all the text for the MyCareProvider container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.MyCareProvider';

export default defineMessages({
  myCareProvider: {
    defaultMessage: 'My Care Provider',
  },
  ymBio: {
    defaultMessage: 'Practitioner Bio',
  },
  ymCertification: {
    defaultMessage: 'Certifications',
  },
  ymDegree: {
    defaultMessage: 'Degrees',
  },
  ymAvailability: {
    defaultMessage: 'General Availability',
  },
  ymNextAvailability: {
    defaultMessage: 'Next Availability',
  },
  ymLocation: {
    defaultMessage: 'Location',
  },
  ymMessageAvailability: {
    defaultMessage: 'Message practitioner to request availability',
  },
  ymDescription: {
    defaultMessage:
      'Below are the practitioners that are the best fit based on your preferences. If they have not set availability, you will be matched, then we will message the provider to request a session.',
  },
  ymResponseTime: {
    defaultMessage: 'Typical practitioner response time is within 48 hours.',
  },
  ymMatchingCode: {
    defaultMessage: 'Direct Matching Code',
  },
  ymRankedByFit: {
    defaultMessage: 'Matches ranked by fit',
  },
  ymSelectPractitioner: {
    defaultMessage: 'Select Practitioner',
  },
  ymLanguage: {
    defaultMessage: 'Languages',
  },
  ymInsurance: {
    defaultMessage: 'Insurance network',
  },
  ymHeHim: {
    defaultMessage: 'He/Him',
  },
  ymSheHer: {
    defaultMessage: 'She/Her',
  },
  ymTheyThem: {
    defaultMessage: 'They/Them',
  },
  ymMan: {
    defaultMessage: 'Man',
  },
  ymWoman: {
    defaultMessage: 'Woman',
  },
  ymGender: {
    defaultMessage: 'Gender Non-Binary or Expansive',
  },
  ymTrans: {
    defaultMessage: 'Transgender',
  },
  ymIdentifyAs: {
    defaultMessage: 'Identifies As',
  },
  ymAsianSouth: {
    defaultMessage: 'Asian - South (e.g. Indian, Pakistani, Sri Lankan)',
  },
  ymAsianEast: {
    defaultMessage: 'Asian - East (e.g. Chinese, Japanese, Korean)',
  },
  ymAsianSouthEast: {
    defaultMessage: 'Asian - South East (e.g. Malaysian, Filipino, Vietnamese)',
  },
  ymAsianNorth: {
    defaultMessage: 'Asian - North American (e.g. Canadian, American)',
  },
  ymAsianCarib: {
    defaultMessage: 'Asian - Caribbean',
  },
  ymBlackAfrican: {
    defaultMessage: 'Black - African (e.g. Ghanaian, Kenyan, Somali)',
  },
  ymBlackCarbi: {
    defaultMessage: 'Black - Caribbean (e.g. Barbadian, Jamaican)',
  },
  ymBlackEuro: {
    defaultMessage: 'Black - European (e.g. English, Dutch, Portuguese)',
  },
  ymBlackLatin: {
    defaultMessage: 'Black - Latin American',
  },
  ymBlackNorth: {
    defaultMessage: 'Black - North American (e.g. Canadian, American)',
  },
  ymFirstNation: {
    defaultMessage: 'First Nations',
  },
  ymIndianCarib: {
    defaultMessage: 'Indian - Caribbean (e.g. Guyanese with origins in India)',
  },
  ymIndige: {
    defaultMessage: 'Indigenous',
  },
  ymInuit: {
    defaultMessage: 'Inuit',
  },
  ymLatinAmeri: {
    defaultMessage: 'Latin American (e.g. Argentinean, Chilean, Salvadoran)',
  },
  ymMetis: {
    defaultMessage: 'Métis',
  },
  ymMiddleEast: {
    defaultMessage:
      'Middle Eastern/North African (e.g. Egyptian, Iranian, Lebanese)',
  },
  ymPacific: {
    defaultMessage: 'Pacific Islander (e.g. Hawaii, Guam, Samoa)',
  },
  ymWhiteEuro: {
    defaultMessage:
      'White - European (e.g. English, Italian, Portuguese, Russian)',
  },
  ymWhiteNorth: {
    defaultMessage: 'White - North American (e.g. Canadian, American)',
  },
  ymWhiteCarib: {
    defaultMessage: 'White - Caribbean',
  },
  ymOther: {
    defaultMessage: 'Add Other - type in',
  },
  ymLgbtq: {
    defaultMessage: 'LGBTQ2S+',
  },
  ymNeurodivergent: {
    defaultMessage: 'Neurodivergent Person',
  },
  ymWithDisability: {
    defaultMessage: 'Person Living with a Disability',
  },
  ymApproach: {
    defaultMessage: 'Approach to Care',
  },
  ymSeeMore: {
    defaultMessage: 'See more',
  },
  ymSeeLess: {
    defaultMessage: 'See less',
  },
  ymDesignation: {
    defaultMessage: 'Designations',
  },
  ymQualification: {
    defaultMessage: 'Qualifications',
  },
  resources: {
    defaultMessage: 'Resources',
  },
  download: {
    defaultMessage: 'Download',
  },
  sendFiles: {
    defaultMessage: 'Send Files',
  },
  useThisTool: {
    defaultMessage: 'Use this tool to send files to your care practitioner.',
  },
  selectFile: {
    defaultMessage: 'Select File',
  },
  send: {
    defaultMessage: 'Send',
  },
  distance: {
    defaultMessage: 'Distance',
  },
  viewOnMap: {
    defaultMessage: 'View on Map',
  },
  locationNotAvailable: {
    defaultMessage: 'Location information not available.',
  },
  monFri: {
    defaultMessage: 'MON-FRI',
  },
  satSun: {
    defaultMessage: 'SAT-SUN',
  },
  inPerson: {
    defaultMessage: 'In Person',
  },
  video: {
    defaultMessage: 'Video',
  },
  voice: {
    defaultMessage: 'Voice',
  },
  careProviderPreferences: {
    defaultMessage: 'Care Provider Preferences',
  },
  doYouUnmatch: {
    defaultMessage: 'Do you wish to unmatch with your Care Provider?',
  },
  unmatch: {
    defaultMessage: 'Unmatch',
  },
  areYouSureUnmatch: {
    defaultMessage: 'Are you sure you want to unmatch with your Care Provider?',
  },
  ifYouDecide: {
    defaultMessage:
      'If you decide to unmatch, your Care Provider will be notified and you will be able to redo the matching process.',
  },
  reasonUnmatch: {
    defaultMessage: 'What is your reason for unmatching?',
  },
  yesUnmatch: {
    defaultMessage: 'Yes, unmatch',
  },
  noKeepCare: {
    defaultMessage: 'No, keep my Care Provider',
  },
  unmatchSchedule: {
    defaultMessage: "My counsellor's schedule doesn't work for me",
  },
  unmatchInsurance: {
    defaultMessage: 'My counsellor is not covered by my insurance',
  },
  unmatchApproach: {
    defaultMessage:
      "My counsellor's approach to care/specialties don't match my needs",
  },
  unmatchSession: {
    defaultMessage: 'I want to try a free consultation with another counsellor',
  },
  unmatchComplaint: {
    defaultMessage: 'I have a complaint about my counsellor',
  },
  noPractitioner: {
    defaultMessage: 'No Care Provider',
  },
  haventMatched: {
    defaultMessage: "Looks like you haven't matched with a care provider yet!",
  },
  findMyCareProvider: {
    defaultMessage: 'Find My Care Provider',
  },
  messageMyProvider: {
    defaultMessage: 'Message my care provider',
  },
  upcomingError: {
    defaultMessage:
      'Please cancel any upcoming appointments with your practitioner',
  },
  close: {
    defaultMessage: 'Close',
  },
  bookASession: {
    defaultMessage: 'Book a Session',
  },
  bookAConsultation: {
    defaultMessage: 'Book a Consultation',
  },
  viewDetails: {
    defaultMessage: 'View Details & Resources',
  },
  hideDetails: {
    defaultMessage: 'Hide Details & Resources',
  },
  outOfOffice: {
    defaultMessage: 'Out-of-office until',
  },
  individualProvider: {
    defaultMessage: 'Individual mental health counsellor',
  },
  couplesProvider: {
    defaultMessage: 'Couples mental health counsellor',
  },
  genericProvider: {
    defaultMessage: 'Specialized Practitioners',
  },
  availabilityUponRequest: {
    defaultMessage: 'Availability provided upon request',
  },
  fileSent: {
    defaultMessage: 'File Sent!',
  },
  pleaseSelectFile: {
    defaultMessage: 'Please select a file.',
  },
  invitationCode: {
    defaultMessage: 'Enter Invitation Code',
  },
});
