import React from 'react';
import styled from 'styled-components';
import shape from 'images/SignUp/shape.png';
import userImage from 'images/SignUp/user_holding_cup.png';

const BackgroundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 55%;
  background-color: #f0f2fa;
  overflow-y: hidden;

  @media (max-width: 1200px) {
    display: none;
  }
`;

const ShapeContainer = styled.div`
  position: relative;
`;

const UserImageContainer = styled.div`
  margin-top: -300px;
  height: 90%;
  display: flex;
  justify-content: center;
`;

const Background = () => (
  <BackgroundWrapper>
    <ShapeContainer>
      <img alt="blueShape" style={{ height: '75%' }} src={shape} />
    </ShapeContainer>
    <UserImageContainer>
      <img alt="meditation" src={userImage} />
    </UserImageContainer>
  </BackgroundWrapper>
);

export default Background;
