import React from 'react';
import styled from 'styled-components';
import Content from 'components/Content';
import H3 from 'components/H3';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';

const StyledContent = styled(Content)`
  line-height: 24px;
  white-space: pre-line;
  text-align: justify;
  display: flex;
`;

const ItemInfo = ({ content, messageTitle, ...props }) => {
  if (!content && !props.children) return <></>;
  return (
    <div {...props}>
      <H3 style={{ marginBottom: '20px' }}>
        <FormattedMessage {...messages[messageTitle]} />
      </H3>
      {props.children ? (
        props.children
      ) : (
        <StyledContent>{content}</StyledContent>
      )}
    </div>
  );
};

export default ItemInfo;
