/*
 *
 * Company actions
 *
 */

import {
  FETCH_COMPANY,
  FETCH_COMPANY_SUCCESS,
  FETCH_COMPANY_ERROR,
} from './constants';

export function fetchCompany() {
  return {
    type: FETCH_COMPANY,
  };
}

export function fetchCompanySuccess(company) {
  return {
    type: FETCH_COMPANY_SUCCESS,
    company,
  };
}

export function fetchCompanyError(error) {
  return {
    type: FETCH_COMPANY_ERROR,
    error,
  };
}
