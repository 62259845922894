import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SVG from 'react-inlinesvg';
import { Map } from 'immutable';
import 'wicg-inert';
import ReactDOM from 'react-dom';

import { black, mediumBlue, phoneSize } from '../../global-styles';
import MailIcon from './MailIcon';
import X from './X';
import TimezoneSelect from './TimezoneSelect';
import EnglishSvg from './svgs/en.svg';
import FrenchSvg from './svgs/fr.svg';
import { withNavigationLinks } from './withNavigationLinks';

const NavSideLink = styled(Link)`
  font-family: 'Barlow', sans-serif;
  color: ${(props) => (props.active ? mediumBlue : black)};
  font-size: 18px;
  font-weight: ${(props) => (props.active ? 'bold' : '600')};
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-decoration: none;

  display: flex;

  :visited {
    text-decoration: none;
  }
  :focus {
    text-decoration: none;
    background-color: transparent;
  }
  svg g {
    stroke: ${(props) => (props.active ? mediumBlue : black)};
    path {
      stroke: ${(props) => (props.active ? mediumBlue : black)};
    }
  }
  &:hover,
  &:focus {
    color: ${mediumBlue};
    font-weight: bold;
    letter-spacing: -0.001527em;
    svg g {
      stroke: ${mediumBlue};
      path {
        stroke: ${mediumBlue};
      }
    }
    outline: none;
  }
`;

const StyledLink = styled(Link)`
  &:hover,
  &:focus {
    color: ${mediumBlue};
    font-weight: bold;
    letter-spacing: -0.001527em;
    svg {
      stroke: ${mediumBlue};
      path {
        stroke: ${mediumBlue};
      }
    }
  }
`;

const LinkWrapper = styled.div`
  padding-bottom: 40px;
`;

const NavigationIcon = styled.div`
  height: 22px;
  width: 22px;
  margin-right: 20px;
  text-align: center;
`;

const Wrapper = styled.div`
  padding-top: 72px;
  padding-left: 40px;
  padding-bottom: 0rem !important;

  @media screen and (max-device-width: ${phoneSize}) {
    padding-bottom: 5rem !important;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 200;
  }
`;

const StyledTextButton = styled.button`
  color: ${mediumBlue};
  font-size: 16px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  letter-spacing: 0.2px;
  display: block;
  margin-left: -6px;
`;

const LinksWrapper = styled.div`
  padding-bottom: 20px;
`;

const ItemWrapper = styled.div`
  height: 58px;
  cursor: pointer;
  margin-bottom: 7rem;

  display: flex;
  align-items: center;

  .tno-icon {
    width: 32px;
    margin-top: -4px;
  }

  .tno-label {
    display: inline-block;

    p {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.2px;
      color: ${mediumBlue};
    }
  }
`;

const NavSideLinks = ({
  toggleNavSide,
  open,
  newMessageInfo,
  handleSignOut,
  navigationLinks,
  timezoneObject,
  localeObject,
}) => {
  useEffect(() => {
    if (open) {
      document.getElementById('app').inert = true;
    }
  }, [open]);

  const languages = {
    en: { flag: EnglishSvg, label: 'English' },
    fr: { flag: FrenchSvg, label: 'Français' },
  };

  const handleLocaleChange = () => {
    const changeLocale = localeObject.get('changeLocale');

    if (localeObject.get('currentLocale') === 'en') {
      changeLocale('fr');
    } else {
      changeLocale('en');
    }
  };

  const renderLanguage = () => {
    let code = 'en';

    if (localeObject.get('currentLocale') === 'en') {
      code = 'fr';
    } else {
      code = 'en';
    }

    return (
      <React.Fragment>
        <div className="tno-label">
          <p>{languages[code].label}</p>
        </div>
      </React.Fragment>
    );
  };
  const focusOnFirstTab = () => {
    if (open) {
      const el = document.getElementById('tab-0');
      if (el) el.focus();
    }
  };
  return ReactDOM.createPortal(
    <Wrapper role="dialog" aria-modal="true">
      <X open={open} toggleNavSide={toggleNavSide} />
      <LinksWrapper>
        {focusOnFirstTab(open)}
        {navigationLinks.map((navigationItem, i) => {
          const active =
            navigationItem.to === window.location.pathname ? 'true' : undefined;
          return (
            <LinkWrapper onClick={toggleNavSide} key={i}>
              <NavSideLink
                active={active}
                to={navigationItem.to}
                id={`tab-${i}`}
              >
                <NavigationIcon>
                  <SVG alt={navigationItem.name} src={navigationItem.icon} />
                </NavigationIcon>
                {navigationItem.formattedMessage}
              </NavSideLink>
            </LinkWrapper>
          );
        })}
      </LinksWrapper>
      <TimezoneSelect timezoneObject={timezoneObject} />
      <StyledLink to="/messaging">
        <MailIcon newMessageInfo={newMessageInfo} />
      </StyledLink>
      <StyledTextButton style={{ marginTop: 60 }} onClick={handleSignOut}>
        Log Out
      </StyledTextButton>
      <ItemWrapper>
        <StyledTextButton onClick={handleLocaleChange}>
          {renderLanguage()}
        </StyledTextButton>
      </ItemWrapper>
    </Wrapper>,
    document.body,
  );
};

NavSideLinks.propTypes = {
  toggleNavSide: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  newMessageInfo: PropTypes.instanceOf(Map),
  handleSignOut: PropTypes.func.isRequired,
  timezoneObject: PropTypes.instanceOf(Map).isRequired,
  localeObject: PropTypes.instanceOf(Map).isRequired,
};

export default withNavigationLinks(NavSideLinks);
