/**
 *
 * PrivacyConsents
 *
 */

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import { Field } from 'redux-form/immutable';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import get from 'lodash/get';

import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import SingleCheckBoxContent from 'components/SingleCheckBoxContent';
import Divider from 'components/Divider';
import DataLoadingSpinner from 'components/DataLoadingSpinner';

import DownArrow from './down.svg';
import makeSelectPrivacyConsents from './selectors';
import reducer from './reducer';
import saga from './saga';
import messages from './messages';
import { fetchCurrentPrivacyConsents } from './actions';

const TermsContainer = styled.div`
  height: 180px;
  border-radius: 6px;
  box-shadow: 0 0 10px 0 rgba(99, 140, 177, 0.1);
  border: solid 1px rgba(46, 95, 202, 0.1);
  background-color: #ffffff;
  overflow-y: hidden;
  padding: 15px 20px;
  font-size: 12px;
  color: rgba(15, 32, 69, 0.6);
  position: relative;
  margin-bottom: 20px;

  svg {
    cursor: pointer;
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
`;

const Content = styled.div`
  overflow-y: scroll;
  height: 100%;
  text-align: left;
  scroll-behavior: smooth;
  white-space: pre-wrap;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`;

/* eslint-disable react/prefer-stateless-function */
class PrivacyConsents extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { fullyScrolled: false };
  }

  componentDidMount() {
    if (this.props.tokenLoaded || this.props.tokenLoaded === undefined) {
      this.props.fetchCurrentPrivacyConsents();
    }
  }

  // Check that the user's token is loaded before fetching P&C because this
  // container gets mounted quickly by UpdatedPrivacyConsents
  componentDidUpdate(prevProps) {
    if (
      this.props.tokenLoaded !== prevProps.tokenLoaded &&
      this.props.tokenLoaded
    ) {
      this.props.fetchCurrentPrivacyConsents();
    }
  }

  isFullyScrolled = () => {
    const element = document.getElementById('privacy-consent');
    const { fullyScrolled } = this.state;

    if (element && !fullyScrolled) {
      this.setState({
        fullyScrolled:
          element.scrollHeight - element.scrollTop - 10 <= element.clientHeight,
      });
    }
  };

  scrollDown = () => {
    const element = document.getElementById('privacy-consent');

    if (element) {
      const height = Number.parseInt(element.clientHeight, 10);
      const scrollAmount = height - 50;
      element.scrollBy(0, scrollAmount);
    }
  };

  render() {
    const { style, privacyConsents, intl } = this.props;
    const { fullyScrolled } = this.state;
    const dataText = get(
      privacyConsents,
      intl.locale === 'fr' ? 'data.fr_text' : 'data.text',
    );

    return (
      <div>
        {privacyConsents.loading ? (
          <DataLoadingSpinner />
        ) : (
          <TermsContainer style={style}>
            <Content id="privacy-consent" onScroll={this.isFullyScrolled}>
              {dataText}
            </Content>
            {!fullyScrolled && (
              <div onClick={this.scrollDown} role="presentation">
                <SVG src={DownArrow} />
              </div>
            )}
          </TermsContainer>
        )}
        <div style={{ textAlign: 'left' }}>
          <Field
            component={SingleCheckBoxContent}
            name="read_tos"
            label={<FormattedMessage {...messages.confirmReadTos} />}
            smallText
          />
          <Divider />
        </div>
      </div>
    );
  }
}

PrivacyConsents.propTypes = {
  fetchCurrentPrivacyConsents: PropTypes.func.isRequired,
  style: PropTypes.object,
  privacyConsents: PropTypes.object,
  tokenLoaded: PropTypes.bool,
  intl: intlShape,
};

const mapStateToProps = createStructuredSelector({
  privacyConsents: makeSelectPrivacyConsents(),
});

function mapDispatchToProps(dispatch) {
  return {
    fetchCurrentPrivacyConsents: (callback) =>
      dispatch(fetchCurrentPrivacyConsents(callback)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'privacyConsents', reducer });
const withSaga = injectSaga({ key: 'privacyConsents', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(injectIntl(PrivacyConsents));
