export const FETCH_DEFAULT_CARD = 'app/FailedPaymentsBanner/FETCH_DEFAULT_CARD';
export const FETCH_DEFAULT_CARD_SUCCESS =
  'app/FailedPaymentsBanner/FETCH_DEFAULT_CARD_SUCCESS';
export const FETCH_DEFAULT_CARD_ERROR =
  'app/FailedPaymentsBanner/FETCH_DEFAULT_CARD_ERROR';

export const UPDATE_DEFAULT_CARD =
  'app/FailedPaymentsBanner/UPDATE_DEFAULT_CARD';
export const UPDATE_DEFAULT_CARD_SUCCESS =
  'app/FailedPaymentsBanner/UPDATE_DEFAULT_CARD_SUCCESS';
export const UPDATE_DEFAULT_CARD_ERROR =
  'app/FailedPaymentsBanner/UPDATE_DEFAULT_CARD_ERROR';
