/*
 *
 * Booking actions
 *
 */
import {
  FETCH_COUPLES_INFO,
  FETCH_COUPLES_INFO_SUCCESS,
  FETCH_COUPLES_INFO_ERROR,
  CONFIRM_CONSENT_INFO,
  CONFIRM_CONSENT_INFO_SUCCESS,
  CONFIRM_CONSENT_INFO_ERROR,
} from './constants';

export function fetchCouplesInfo(callback) {
  return {
    type: FETCH_COUPLES_INFO,
    callback,
  };
}

export function fetchCouplesInfoSuccess(data) {
  return {
    type: FETCH_COUPLES_INFO_SUCCESS,
    data,
  };
}

export function fetchCouplesInfoError(error) {
  return {
    type: FETCH_COUPLES_INFO_ERROR,
    error,
  };
}

//----------------

export function confirmConsentInfo(params, callback) {
  return {
    type: CONFIRM_CONSENT_INFO,
    params,
    callback,
  };
}

export function confirmConsentInfoSuccess(data) {
  return {
    type: CONFIRM_CONSENT_INFO_SUCCESS,
    data,
  };
}

export function confirmConsentInfoError(error) {
  return {
    type: CONFIRM_CONSENT_INFO_ERROR,
    error,
  };
}
