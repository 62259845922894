import { takeLatest, put, call } from 'redux-saga/effects';
import { API_URL_NO_VERSION } from 'utils/environment';
import { post } from 'utils/request';
import { REGISTER_USER } from './constants';
import { registerUserError, registerUserSuccess } from './actions';

function* registerUser({ values, callback, errCallback }) {
  const requestURL = `${API_URL_NO_VERSION}/signup`;
  try {
    const response = yield call(post, requestURL, values);
    yield put(registerUserSuccess(response));
    if (callback) yield callback(response);
  } catch (error) {
    yield put(registerUserError(error));
    if (errCallback) errCallback(error);
  }
}

export default function* wmhSignupSaga() {
  yield takeLatest(REGISTER_USER, registerUser);
}
