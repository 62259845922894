/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { reduxForm, Field } from 'redux-form/immutable';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  COUNTRY,
  OTHER_THERAPY_URL,
  SERVER_URL,
  PRACTICE_URL,
} from 'utils/environment';
import axios from 'axios';
import { logEvent, COMPLETED_SIGNUP } from 'utils/amplitude';
import LanguageSelector from 'containers/LanguageSelector';
import InkblotLogo from 'images/SignUp/inkblot-logo.svg';
import { redirectToTherapy } from 'utils/auth';
import { validEmail } from 'utils/validation';
// squid
import { SqTextInputWithLabel } from 'components/SqTextInput/TextInput';
import DropdownButtonAccessibility from 'components/SqDropdownButton/DropdownButtonAccessibility';
import SqAlert from 'components/SqAlert/Alert';
import SqButton from 'components/SqButton/SqButton';
import SqCheckBox from 'components/SqCheckbox/Checkbox';
import RadioCard from 'components/SqRadioCard/RadioCard';
import messages from './messages';
import {
  validateNotOnlyWhitespace,
  validateRequired,
} from 'utils/reduxFormHelpers';

import { auditProgress } from './Helper';
import {
  minTabletSize,
  phoneSize,
  teenyWeenySize,
  eAZoom400,
} from '../../global-styles';

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 70px;
  padding-left: 11%;
  padding-bottom: 30px;
  width: 45%;
  min-height: 100vh;
  z-index: 100;
  max-height: 100vh;
  overflow-y: scroll;

  @media (min-width: ${teenyWeenySize}) {
    padding-left: 5%;
    padding-right: 5%;
    width: 100%;
    align-items: center;
  }
  @media (min-width: ${phoneSize}) {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
  @media (min-width: ${minTabletSize}) {
    padding-left: 11%;
    padding-right: 10px;
    width: 45%;
    align-items: left;
  }

  @media screen and (max-width: ${eAZoom400}) and (min-device-width: ${phoneSize}) {
    padding-left: 5%;
    padding-right: 5%;
    width: 100%;
    align-items: center;

    input {
      max-width: 300px !important;
    }
  }
`;

const SignupFormElementsWrapper = styled.div`
  max-width: 374px;
  width: 100%;

  @media screen and (max-width: ${eAZoom400}) and (min-device-width: ${phoneSize}) {
    div {
      max-width: 80% !important;
    }
  }
`;

const AlreadyHaveAnAccountWrapper = styled.div`
  margin-top: 10px;

  a {
    color: #5d70ba;
  }
`;

const StyledLabel = styled.span`
  font-family: 'TT Commons Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  letter-spacing: 0.02em;
  color: #696e82;
  margin-bottom: 8px;
`;

const CountrySelectorButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: fit-content;
  margin-bottom: 30px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 374px;
  width: 100%;
  margin-bottom: 45px;
`;

const StyledRadioCard = styled(RadioCard)`
  width: 143px;
  height: 44px;
`;

const StyledForm = styled.form`
  > * {
    margin-bottom: 30px;
    max-width: 374px;
    width: 100%;
  }
`;

const PageTitle = styled.span`
  font-family: 'P22 Mackinac Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 140%;
  color: #282d40;
  margin-top: 45px;
`;

const Asterisk = styled.span`
  color: #8a4f49;
`;

const referralSourceOptionsFR = [
  {
    key: 0,
    value: '',
    label: 'Veuillez sélectionner une option.',
  },
  {
    key: 1,
    value: 'Healthcare provider',
    label: 'Fournisseur de soins de santé',
  },
  { key: 2, value: 'Family/Friend', label: 'Famille/Ami(e)' },
  { key: 3, value: 'Social Media', label: 'Médias sociaux' },
  { key: 4, value: 'Internet Search', label: 'Recherche Internet' },
  { key: 5, value: 'Advertisement', label: 'Publicité' },
  { key: 6, value: 'Television/Radio', label: 'Télévision/Radio' },
  { key: 7, value: 'YouTube/Podcast', label: 'YouTube/Baladodiffusion' },
  { key: 8, value: 'Article', label: 'Article' },
  { key: 9, value: 'Other', label: 'Autre' },
];

const referralSourceOptionsEN = [
  {
    key: 0,
    value: '',
    label: 'Please select an option in this list',
  },
  { key: 1, value: 'Healthcare provider', label: 'Healthcare provider' },
  { key: 2, value: 'Family/Friend', label: 'Family/Friend' },
  { key: 3, value: 'Social Media', label: 'Social Media' },
  { key: 4, value: 'Internet Search', label: 'Internet Search' },
  { key: 5, value: 'Advertisement', label: 'Advertisement' },
  { key: 6, value: 'Television/Radio', label: 'Television/Radio' },
  { key: 7, value: 'YouTube/Podcast', label: 'YouTube/Podcast' },
  { key: 8, value: 'Article', label: 'Article' },
  { key: 9, value: 'Other', label: 'Other' },
];

const SignUpForm = ({
  invalid,
  // redux form props
  handleSubmit,
  // react-intl props
  intl,
}) => {
  const { locale } = intl;
  const [selectedCountry, setSelectedCountry] = useState(COUNTRY);
  const [loading, setLoading] = useState(false);
  const [signUpError, setSignUpError] = useState(null);
  const [referralSource, setReferralSource] = useState(null);
  const [passwordVisibles, setPasswordVisibles] = useState({});

  const urlParams = queryString.parse(window.location.search);
  const partnerInviteToken = urlParams.partner_token || null;

  const isFr = locale === 'fr';

  const renderCrisisCopy =
    // eslint-disable-next-line no-nested-ternary
    COUNTRY === 'US'
      ? 'If you or someone you know is in crisis and requires immediate support, call the 988 Suicide & Crisis Lifeline or go to your nearest emergency room. Please know you are not alone. Support is available'
      : isFr
      ? "Si vous ou une personne que vous connaissez êtes en situation de crise et avez besoin d'une aide immédiate, composez le « 911 » ou rendez-vous à la salle des urgences la plus près. Vous pouvez aussi appeler le Service canadien de prévention du suicide au 1-833-456-4566 (en tout temps). Si vous êtes résident du Québec, composez le 1 866 APPELLE (1-866-277-3553). N'oubliez pas que vous n'êtes pas seul. Un soutien est offert."
      : 'If you or someone you know is in crisis and requires immediate support, call 911 or go to your nearest emergency room. Alternately, please contact the Canada Suicide Prevention Service at 1-833-456-4566 (24/7). For residents of Québec, call 1 866 APPELLE (1-866-277-3553). Please know you are not alone. Support is available.';

  useEffect(() => {
    if (selectedCountry !== COUNTRY) {
      window.location.href = `${OTHER_THERAPY_URL}/signup`;
    }
  }, [selectedCountry]);

  useEffect(() => {
    localStorage.setItem('selectedLanguage', locale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * On form submit, pre-process form values and pass to registration endpoint on backend
   * @param {Map<string, any>} values Values from Redux Form in Immutable.js Map
   */
  const performSignUp = (values) => {
    let countryValue = selectedCountry;
    if (countryValue === 'init') {
      countryValue = COUNTRY;
    }
    const mergedValues = {
      country: countryValue,
      first_name: values.get('first_name').trim(),
      last_name: values.get('last_name').trim(),
      email: values.get('email'),
      password: values.get('password'),
      referral_source: referralSource || '',
      locale,
      source: 'therapy',
      consent_yn: values.get('consent_yn') || false,
      marketing_yn: values.get('marketing_yn') || false,
    };

    if (partnerInviteToken) {
      mergedValues.partner_invitation_token = partnerInviteToken;
      mergedValues.referral_source = 'Partner Invitation';
    }

    setLoading(true);

    axios
      .post(`${SERVER_URL}/api/signup`, mergedValues)
      .then((response) => {
        const token = response.data.access_token;
        // previously in marketing sign up, there is 'referral code'. It's defined as '' temporarily.
        logEvent(COMPLETED_SIGNUP, {
          page: '',
          source: partnerInviteToken ? 'partner invitation' : '',
        });

        auditProgress('', 'completed_signup', token);
        if (partnerInviteToken) {
          redirectToTherapy(token, { partner_registration: true });
        } else {
          redirectToTherapy(token);
        }
      })
      .catch((e) => {
        setLoading(false);
        setSignUpError(e.response.data.error);
      });
  };

  return (
    <Panel>
      <SignupFormElementsWrapper>
        <Header>
          <SVG src={InkblotLogo} alt="inkblot_icon" />
          <LanguageSelector
            color="#000"
            locale={(locale || 'EN').toUpperCase()}
          />
        </Header>
        <PageTitle>{isFr ? 'Créer un compte' : 'Create an Account'}</PageTitle>
        <AlreadyHaveAnAccountWrapper>
          <>
            {isFr ? (
              <span tabIndex="0">
                <p>
                  Vous avez déjà un compte?{' '}
                  <Link to="signin">
                    <span>Se connecter</span>
                  </Link>
                </p>
              </span>
            ) : (
              <span tabIndex="0">
                <p>
                  Already have an account?{' '}
                  <Link to="signin">
                    <span>Sign In</span>
                  </Link>
                </p>
              </span>
            )}
            {isFr ? (
              <span tabIndex="0">
                <p>
                  Si vous êtes un prestataire de soins,{' '}
                  <a
                    href="https://practitioner.greenshieldplus.ca/signup"
                    target="_blank"
                  >
                    <span>inscrivez-vous ici.</span>
                  </a>
                </p>
              </span>
            ) : (
              <span tabIndex="0">
                <p>
                  If you are a care provider,{' '}
                  <a href={`${PRACTICE_URL}/signup`} target="_blank">
                    <span>Sign up here.</span>
                  </a>
                </p>
              </span>
            )}
            <span tabIndex="0">
              <p>
                {isFr
                  ? "Si vous êtes un employé, un membre ou une personne à charge d'une organisation utilisant Inkblot, veuillez vous inscrire en suivant les instructions qui vous ont été fournies."
                  : 'If you are an employee, member or dependent of an organization using Inkblot, please register using the instructions provided to you.'}
              </p>
            </span>
          </>
        </AlreadyHaveAnAccountWrapper>
        <hr style={{ width: '100px', marginLeft: '0', marginRight: 'auto' }} />
        {!partnerInviteToken && (
          <>
            <StyledLabel>
              {isFr ? 'Pays' : 'Country'}
              <Asterisk> *</Asterisk>
            </StyledLabel>
            <CountrySelectorButtonWrapper>
              <StyledRadioCard
                onClick={() => setSelectedCountry('CA')}
                initValue={COUNTRY === 'CA'}
                label="Canada"
                style={{ marginRight: '14px' }}
              />
              <StyledRadioCard
                onClick={() => setSelectedCountry('US')}
                label={isFr ? 'États-Unis' : 'United States'}
                initValue={COUNTRY === 'US'}
              />
            </CountrySelectorButtonWrapper>
          </>
        )}

        <StyledForm onSubmit={handleSubmit(performSignUp)}>
          <Field
            component={SqTextInputWithLabel}
            name="first_name"
            label={isFr ? 'Prénom' : 'First Name'}
            type="text"
            validate={[validateRequired, validateNotOnlyWhitespace]}
          />
          <Field
            component={SqTextInputWithLabel}
            name="last_name"
            label={isFr ? 'Nom de Famille' : 'Last Name'}
            type="text"
            validate={[validateRequired, validateNotOnlyWhitespace]}
          />
          <Field
            component={SqTextInputWithLabel}
            name="email"
            label={isFr ? 'Courriel' : 'Email Address'}
            type="text"
            validate={[validateRequired]}
          />
          <Field
            style={{ width: '374px' }}
            component={SqTextInputWithLabel}
            name="password"
            label={isFr ? 'Mot de Passe' : 'Password'}
            type={passwordVisibles.password ? 'text' : 'password'}
            rightIconName={passwordVisibles.password ? 'Eye' : 'EyeOff'}
            rightIconOnclick={() =>
              setPasswordVisibles((pre) => ({
                ...passwordVisibles,
                password: !pre.password,
              }))
            }
            rightIconAriaLabel={
              passwordVisibles.password ? 'Hide password' : 'Show password'
            }
            validate={[validateRequired]}
          />
          <Field
            style={{ width: '374px' }}
            component={SqTextInputWithLabel}
            name="retype_password"
            label={isFr ? 'Retaper le Mot de Passe' : 'Retype Password'}
            rightIconName={passwordVisibles.retypePassword ? 'Eye' : 'EyeOff'}
            rightIconOnclick={() =>
              setPasswordVisibles((pre) => ({
                ...passwordVisibles,
                retypePassword: !pre.retypePassword,
              }))
            }
            rightIconAriaLabel={
              passwordVisibles.retypePassword
                ? 'Hide password'
                : 'Show password'
            }
            type={passwordVisibles.retypePassword ? 'text' : 'password'}
            validate={[validateRequired]}
          />
          {!partnerInviteToken && (
            <DropdownButtonAccessibility
              name="referral_source"
              label={
                isFr
                  ? 'Comment avez-vous entendu parler de nous?'
                  : 'How did you hear about us?'
              }
              helpText={isFr ? 'Optionnel' : 'Optional'}
              style={{
                marginBottom: '36px',
              }}
              placeholder={
                isFr
                  ? 'Sélectionnez une option'
                  : 'Please select an option in this list.'
              }
              onChange={(e) => setReferralSource(e.target.value)}
              selections={
                isFr ? referralSourceOptionsFR : referralSourceOptionsEN
              }
            />
          )}
          <Field
            component={SqCheckBox}
            id="consent_yn"
            initValue={null}
            disabled={false}
            type="checkbox"
            name="consent_yn"
            title={
              isFr
                ? 'Accord sur les conditions générales et la politique de confidentialité'
                : 'Terms and Conditions & Privacy Policy agreement'
            }
            helpText={
              isFr ? (
                <span>
                  En créant un compte, j'accepte{' '}
                  <a
                    href="https://inkblottherapy.com/conditions/"
                    target="_blank"
                  >
                    les conditions générales{' '}
                  </a>
                  et{' '}
                  <a href="https://inkblottherapy.com/privacy/" target="_blank">
                    la politique de confidentialité{' '}
                  </a>
                  d'Inkblot dans leur intégralité.
                </span>
              ) : (
                <span>
                  By creating an account, I agree to{' '}
                  <a
                    href="https://inkblottherapy.com/conditions/"
                    target="_blank"
                  >
                    Inkblot's Terms and Conditions{' '}
                  </a>
                  and
                  <a href="https://inkblottherapy.com/privacy/" target="_blank">
                    Privacy Policy{' '}
                  </a>
                  in their entirety.
                </span>
              )
            }
            helpTextAriaLabel={
              isFr
                ? "En créant un compte, j'accepte les conditions générales et la politique de confidentialité d'Inkblot dans leur intégralité."
                : "By creating an account, I agree to Inkblot's Terms and Conditions and Privacy Policy in their entirety."
            }
            validate={[validateRequired]}
          />
          <SqAlert
            type="info"
            text={
              isFr
                ? "Vous devez créer votre propre compte pour accéder à thérapie Inkblot et garder vos informations de connexion et de mot de passe privées. Personne d'autre ne peut accéder à votre compte."
                : 'You should create your own account for accessing Inkblot Therapy and keep your login and password information private. No one else should be able to access your account.'
            }
          />
          <Field
            component={SqCheckBox}
            id="marketing_yn"
            initValue={null}
            disabled={false}
            type="checkbox"
            name="marketing_yn"
            required={false}
            title={
              isFr
                ? 'Autorisation de communication'
                : 'Communication permission'
            }
            helpText={
              isFr
                ? "J'accepte de recevoir des articles, des informations sur les produits ou des demandes de commentaires à l'avenir. Je comprends que je peux me désinscrire à tout moment par la suite."
                : 'I consent to receiving articles, product information, or requests for feedback in the future. I understand that I can opt out at any time later.'
            }
            extraText={isFr ? 'Optionnel' : 'Optional'}
          />
          {signUpError && <p style={{ margin: '10px' }}>{signUpError}</p>}
          <SqButton
            type="submit"
            label={isFr ? 'Créer un compte' : 'Create Account'}
            style={{ width: '166px', heigiht: '56px' }}
            disabled={loading || invalid}
          />
        </StyledForm>
        <SqAlert
          type="infoCritical"
          text={renderCrisisCopy}
          style={{ marginBottom: '80px' }}
        />
      </SignupFormElementsWrapper>
    </Panel>
  );
};

function validate(values) {
  const errors = {};

  // input validation
  const email = values.get('email');
  const password = values.get('password');
  // eslint-disable-next-line camelcase
  const retype_password = values.get('retype_password');

  if (email) {
    if (!validEmail.test(email)) {
      errors.email = <FormattedMessage {...messages.enterAnEmailWithExample} />;
    }
  }

  if (password) {
    if (password.length < 8) {
      errors.password = (
        <FormattedMessage {...messages.passwordLengthRequirement} />
      );
      // eslint-disable-next-line camelcase
    } else if (password !== retype_password) {
      errors.retype_password = (
        <FormattedMessage {...messages.passwordConfirmationMustMatch} />
      );
    }
  }

  return errors;
}

export const asyncValidate = (values) => {
  const locale = localStorage.getItem('selectedLanguage');
  const email = values.get('email');
  const errorMessageEmailExists =
    locale === 'fr'
      ? "Il semble que vous ayez déjà un compte Inkblot. Veuillez vous connecter et contacter notre équipe d'assistance à la clientèle pour que vos heures gratuites soient ajoutées à votre compte existant."
      : 'It appears you already have an Inkblot account. Please sign in and contact support@inkblottherapy.com to have your free hours added to your existing account.';

  return axios
    .get(
      `${SERVER_URL}/api/signup/check_email?email=${encodeURIComponent(email)}`,
    )
    .then(() => {})
    .catch(() => {
      // eslint-disable-next-line no-throw-literal
      throw { email: errorMessageEmailExists };
    });
};

SignUpForm.propTypes = {
  intl: PropTypes.object,
  handleSubmit: PropTypes.func,
  invalid: PropTypes.object,
};

export default injectIntl(
  reduxForm({
    form: 'SignUpForm',
    validate,
    asyncValidate,
    asyncChangeFields: ['email'],
  })(SignUpForm),
);
