/**
 *
 * Misc
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import makeSelectMisc from './selectors';
import reducer from './reducer';
import saga from './saga';
import { fetchStates } from './actions';

class Misc extends React.PureComponent {
  componentDidMount() {
    // if (this.props.tokenLoaded) {
    this.props.fetchStates();
    // }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.tokenLoaded !== this.props.tokenLoaded &&
      this.props.tokenLoaded
    ) {
      this.props.fetchStates();
    }
  }

  render() {
    return <div />;
  }
}

Misc.propTypes = {
  fetchStates: PropTypes.func.isRequired,
  tokenLoaded: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  misc: makeSelectMisc(),
});

function mapDispatchToProps(dispatch) {
  return {
    fetchStates: () => dispatch(fetchStates()),
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'misc', reducer });
const withSaga = injectSaga({ key: 'misc', saga });

export default compose(withReducer, withSaga, withConnect)(Misc);
