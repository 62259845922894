/*
 *
 * TermsAndConditions constants
 *
 */

export const FETCH_CURRENT_TERMS_AND_CONDITIONS =
  'app/TermsAndConditions/FETCH_CURRENT_TERMS_AND_CONDITIONS';
export const FETCH_CURRENT_TERMS_AND_CONDITIONS_SUCCESS =
  'app/TermsAndConditions/FETCH_CURRENT_TERMS_AND_CONDITIONS_SUCCESS';
export const FETCH_CURRENT_TERMS_AND_CONDITIONS_ERROR =
  'app/TermsAndConditions/FETCH_CURRENT_TERMS_AND_CONDITIONS_ERROR';
