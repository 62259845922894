import {
  VERIFY_PHONE_NUMBER,
  VERIFY_PHONE_NUMBER_SUCCESS,
  VERIFY_PHONE_NUMBER_ERROR,
  CREATE_PHONE_NUMBER,
  CREATE_PHONE_NUMBER_SUCCESS,
  CREATE_PHONE_NUMBER_ERROR,
} from './constants';

export function verifyPhoneNumber(code, callback, errorCallback) {
  return {
    type: VERIFY_PHONE_NUMBER,
    code,
    callback,
    errorCallback,
  };
}

export function verifyPhoneNumberSuccess(message) {
  return {
    type: VERIFY_PHONE_NUMBER_SUCCESS,
    message,
  };
}

export function verifyPhoneNumberError(error) {
  return {
    type: VERIFY_PHONE_NUMBER_ERROR,
    error,
  };
}

export function createPhoneNumber(values, callback, errorCallback) {
  return {
    type: CREATE_PHONE_NUMBER,
    values,
    callback,
    errorCallback,
  };
}

export function createPhoneNumberSuccess(message) {
  return {
    type: CREATE_PHONE_NUMBER_SUCCESS,
    message,
  };
}

export function createPhoneNumberError(error) {
  return {
    type: CREATE_PHONE_NUMBER_ERROR,
    error,
  };
}
