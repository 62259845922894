import { takeLatest, put, call } from 'redux-saga/effects';

import { API_URL } from 'utils/environment';
import { post } from 'utils/request';

import { SUBMIT_EMERGENCY_RELIEF_FUND } from './constants';
import {
  submitEmergencyReliefFundSuccess,
  submitEmergencyReliefFundError,
} from './actions';

function* submitEmergencyReliefFund({ values, callback }) {
  const requestURL = `${API_URL}/additional_services`;

  try {
    const response = yield call(post, requestURL, values);
    yield put(submitEmergencyReliefFundSuccess(response));
    if (callback) yield call(callback);
  } catch (error) {
    yield put(submitEmergencyReliefFundError(error));
  }
}

export default function* emergencyReliefFundSaga() {
  yield takeLatest(SUBMIT_EMERGENCY_RELIEF_FUND, submitEmergencyReliefFund);
}
