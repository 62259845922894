import React from 'react';
import styled from 'styled-components';
import Background from './Background';
import SignUpForm from './SignUpForm';

const SignUpIndexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: white;
`;
const index = () => (
  <SignUpIndexWrapper>
    <SignUpForm />
    <Background />
  </SignUpIndexWrapper>
);

export default index;
