import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form/immutable';
import styled from 'styled-components';
import { connect } from 'react-redux';
import H2 from 'components/H2';
import Button from 'components/Button';
import Body from 'components/Body';
import { FormattedMessage } from 'react-intl';
import Dialog from 'components/Dialog';
import { createStructuredSelector } from 'reselect';
import Select from './SelectInput';
import { darkSlateBlue } from '../../../global-styles';
import messages from '../messages';
import makeSelectMyCareProvider from '../selectors';
import { destroyMatch } from '../actions';

const Options = [
  {
    label: <FormattedMessage {...messages.unmatchSchedule} />,
    value: 'schedule',
  },
  {
    label: <FormattedMessage {...messages.unmatchInsurance} />,
    value: 'insurance',
  },
  {
    label: <FormattedMessage {...messages.unmatchApproach} />,
    value: 'approach',
  },
  {
    label: <FormattedMessage {...messages.unmatchSession} />,
    value: 'session',
  },
  {
    label: <FormattedMessage {...messages.unmatchComplaint} />,
    value: 'complaint',
  },
];

const Container = styled.div`
  text-align: center;
  h2,
  h4 {
    width: 90%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  h2 {
    margin-bottom: 30px;
  }
`;

class UnmatchDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  submitUnmatch = (values) => {
    const { provider } = this.props;
    const matchId = provider.match_id;
    const parsedValues = values.toJS();
    this.props.destroyingMatch(matchId, parsedValues.feedback);
    this.props.onRequestClose();
  };

  render() {
    const { onRequestClose, open, handleSubmit } = this.props;
    return (
      <Dialog maxWidth="600px" onRequestClose={onRequestClose} open={open}>
        <Container>
          <H2 color={darkSlateBlue}>
            <FormattedMessage {...messages.areYouSureUnmatch} />
          </H2>
          <Body>
            <FormattedMessage {...messages.ifYouDecide} />
          </Body>
          <form
            onSubmit={handleSubmit(this.submitUnmatch)}
            style={{ marginTop: '40px' }}
          >
            <Field
              component={Select}
              label={{ ...messages.reasonUnmatch }}
              name="feedback"
              options={Options}
            />
            <Button
              label={messages.yesUnmatch}
              secondary
              singleClick={false}
              type="submit"
              style={{ marginTop: '20px' }}
            />
            <Button
              label={messages.noKeepCare}
              onClick={onRequestClose}
              singleClick={false}
              tertiary
            />
          </form>
        </Container>
      </Dialog>
    );
  }
}

UnmatchDialog.propTypes = {
  closeUnmatchDialog: PropTypes.func,
  handleSubmit: PropTypes.func,
  exitMatching: PropTypes.func,
  open: PropTypes.bool,
  provider: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  myCareProvider: makeSelectMyCareProvider(),
});

function mapDispatchToProps(dispatch) {
  return {
    destroyingMatch: (id, feedback) => dispatch(destroyMatch(id, feedback)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'personalDetailsForm',
    enableReinitialize: true,
  })(UnmatchDialog),
);
