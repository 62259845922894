import styled from 'styled-components';
import { tabletSize, phoneSize } from '../../../global-styles';

const MarginWrapper = styled.div`
  @media (max-width: ${tabletSize}) {
    margin-bottom: -40px;
  }
  @media (max-width: ${phoneSize}) {
    margin-bottom: 0px;
  }
`;

export default MarginWrapper;
