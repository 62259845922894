import { takeLatest, put, call } from 'redux-saga/effects';
import { API_URL } from '../../utils/environment';
import { get } from '../../utils/request';
import { CHECK_NEW_CHAT_MESSAGE } from './constants';
import {
  checkNewChatMessageSuccess,
  checkNewChatMessageError,
} from './actions';

function* checkNewChatMessage() {
  const requestURL = `${API_URL}/chats/new_message_info`;

  try {
    const newMessageInfo = yield call(get, requestURL);
    yield put(checkNewChatMessageSuccess(newMessageInfo));
  } catch (error) {
    yield put(checkNewChatMessageError(error));
  }
}

// Individual exports for testing
export default function* topNavigationBarSaga() {
  yield takeLatest(CHECK_NEW_CHAT_MESSAGE, checkNewChatMessage);
}
