/*
 *
 * FailedPaymentsModal reducer
 *
 */

import { fromJS } from 'immutable';
import {
  FETCH_DEFAULT_CARD,
  FETCH_DEFAULT_CARD_ERROR,
  FETCH_DEFAULT_CARD_SUCCESS,
} from './constants';

export const initialState = fromJS({
  loading: false,
  defaultCard: {},
});

function failedPaymentsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DEFAULT_CARD:
      return state.set('loading', true);
    case FETCH_DEFAULT_CARD_ERROR:
      return state.set('loading', false).set('error', action.error);
    case FETCH_DEFAULT_CARD_SUCCESS:
      return state
        .set('loading', false)
        .mergeDeep({ defaultCard: action.card });
    default:
      return state;
  }
}

export default failedPaymentsReducer;
