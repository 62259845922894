import React, { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import DetailForm from 'containers/Matching/Detail/DetailForm';
import LoadingPanel from 'components/LoadingPanel';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import injectReducer from 'utils/injectReducer';
import makeSelectUserInformation from 'containers/UserInformation/selectors';
import injectSaga from 'utils/injectSaga';
import { compose } from 'redux';
import { getToken } from 'utils/auth';
import { makeSelectCouplesInfo } from 'containers/Dashboard/selectors';
import makeSelectMisc from 'containers/Misc/selectors';
import InformedConsent from 'containers/Matching/Confirmation/InformedConsent';
import ErrorDialog from 'components/ErrorDialog';
import PhoneNumberVerification from 'containers/PhoneNumberVerification';
import { all, fork } from 'redux-saga/effects';
import {
  confirmConsentInfo as confirmConsentInfoAction,
  fetchCouplesInfo as fetchCouplesInfoAction,
} from './actions';
import partnerSaga from './saga';
import matchingSaga from '../Matching/saga';
import reducer from './reducer';
import messages from './messages';
import { Container, StyledH1 } from '../Matching/Detail/StyledClasses';
import PractitionerPanel from '../Matching/Confirmation/Panel';
import Panel from '../Matching/Panel';

const PartnerRegistration = (props) => {
  const { user, misc, history, fetchCouplesInfo, confirmConsentInfo, intl } =
    props;
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(1);
  const [coupleInfo, setCoupleInfo] = useState({});
  const [errorMsg, setErrorMsg] = useState(null);
  useEffect(() => {
    if (!getToken()) {
      history.push('/signin?partner_registration=true');
      return;
    }
    if (user && user.loaded && misc && !misc.loading) {
      fetchCouplesInfo((data) => {
        const { partner_role, partner_confirmed_consent } = data;
        if (!data) return;
        setCoupleInfo(data);
        if (partner_role === 'guest' && !partner_confirmed_consent) {
          setLoading(false);
        } else {
          history.push('/');
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, misc.loading, fetchCouplesInfo]);

  function handleExitRegistration() {
    history.push('/');
  }

  function onInformedConsentSubmit(e) {
    // prevent form reloading the page
    e.preventDefault();
    fetchCouplesInfo((data) => {
      const hostId = data.host_partner && data.host_partner.id;
      const practitionerId = data.provider && data.provider.id;
      if (!practitionerId) {
        setErrorMsg(intl.formatMessage(messages.waitingHostConfirm));
        return;
      }
      if (!hostId) {
        return;
      }
      confirmConsentInfo({ hostId, practitionerId }, () => {
        history.push('/');
      });
    });
  }

  return (
    <div>
      {loading && (
        <LoadingPanel
          open
          message={<FormattedMessage {...messages.loading} />}
          style={{ position: 'absolute', top: 0, borderRadius: '20px' }}
        />
      )}
      {!loading && (
        <>
          {step === 1 && (
            <Panel openExitDialog={handleExitRegistration} percent={60}>
              <Container>
                <StyledH1>
                  <FormattedMessage {...messages.yourDetails} />
                </StyledH1>
                <DetailForm onUpdated={() => setStep(2)} hidePreviousButton />
              </Container>
            </Panel>
          )}
          {step === 2 && (
            <PhoneNumberVerification
              openExitDialog={handleExitRegistration}
              goToNextPage={() => setStep((pre) => pre + 1)}
              goToPrevPage={() => setStep((pre) => pre - 1)}
            />
          )}
          {step === 3 && (
            <PractitionerPanel
              openExitDialog={handleExitRegistration}
              firstName={user && user.first_name}
              avatarUrl={
                coupleInfo &&
                coupleInfo.provider &&
                coupleInfo.provider.avatar &&
                coupleInfo.provider.avatar.url
              }
            >
              <InformedConsent
                goToPrevPage={() => setStep(1)}
                handleSubmit={onInformedConsentSubmit}
                loading={false}
                submitLabel={messages.completeRegistration}
              />
              <ErrorDialog
                message={errorMsg}
                open={!!errorMsg}
                onClose={() => setErrorMsg(false)}
              />
            </PractitionerPanel>
          )}
        </>
      )}
    </div>
  );
};

PartnerRegistration.propTypes = {};
const mapStateToProps = createStructuredSelector({
  user: makeSelectUserInformation(),
  misc: makeSelectMisc(),
  couplesInfo: makeSelectCouplesInfo(),
});

function mapDispatchToProps(dispatch) {
  return {
    fetchCouplesInfo: (callback) => dispatch(fetchCouplesInfoAction(callback)),
    confirmConsentInfo: (data, callback) =>
      dispatch(confirmConsentInfoAction(data, callback)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
function* combineSaga() {
  // use mix with matchingSaga for utilizing some common components
  yield all([fork(partnerSaga), fork(matchingSaga)]);
}
const withReducer = injectReducer({ key: 'partner', reducer });
const withSaga = injectSaga({ key: 'partner', saga: combineSaga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(injectIntl(PartnerRegistration));
