/**
 *
 * TextArea
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { tabletSize } from '../../global-styles';

const StyledTextArea = styled.textarea`
  max-width: 512px;
  width: -webkit-fill-available;
  height: 201px;
  max-height: 201px;
  border-radius: 6px;
  box-shadow: 0 0 10px 0 rgba(99, 140, 177, 0.1);
  border: solid 1px rgba(46, 95, 202, 0.1);
  background-color: #ffffff;
  margin-top: 60px;
  margin-bottom: 60px;
  resize: none;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  padding: 20px;
  outline: none;
  @media (max-width: ${tabletSize}) {
    height: 450px;
    max-height: 450px;
    margin-top: 60px;
    max-width: unset;
  }
`;

/* eslint-disable react/prefer-stateless-function */
export default function TextArea(props) {
  return (
    <StyledTextArea
      {...props.input}
      placeholder={props.placeholder}
      value={props.defaultValue || props.input.value}
      style={{ ...props.style }}
    />
  );
}
TextArea.propTypes = {
  placeholder: PropTypes.string.isRequired,
};
