/*
 * App Messages
 *
 * This contains all the text for the App container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.App';

export default defineMessages({
  authenticating: {
    defaultMessage: 'Authenticating...',
  },
  authError: {
    defaultMessage: 'There was a problem authenticating your account.',
  },
});
