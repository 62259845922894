/*
 *
 * Company reducer
 *
 */

import { fromJS } from 'immutable';
import {
  FETCH_COMPANY,
  FETCH_COMPANY_SUCCESS,
  FETCH_COMPANY_ERROR,
} from './constants';

export const initialState = fromJS({
  loading: false,
  error: false,
  company: {},
});

function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_COMPANY:
      return state.set('loading', true);
    case FETCH_COMPANY_ERROR:
      return state.set('loading', false).set('error', action.error);
    case FETCH_COMPANY_SUCCESS:
      return state.set('loading', false).mergeDeep({ company: action.company });
    default:
      return state;
  }
}

export default dashboardReducer;
