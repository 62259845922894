import React, { useEffect, useState } from 'react';
import { GOOGLE_MAPS_API_KEY } from 'utils/environment';
import './index.css';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import ApproxImg from '../../../images/Matching/approxLocation.svg';

const GoogleMapsWrapper = styled.div`
  font-family: 'Source Sans Pro', sans-serif;
  color: #0f2045;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  gap: 18px;
  margin-top: 10px;
`;

const MapLoadStatusKey = 'google-map-loaded';

// should be called only once, otherwise G will throw the multiple render map error
export const initGoogleMapScript = () => {
  // Load the Google Maps JavaScript API script dynamically
  localStorage.removeItem(MapLoadStatusKey);
  const script = document.createElement('script');
  script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`;
  script.async = true;
  script.defer = true;
  script.onload = () => localStorage.setItem(MapLoadStatusKey, 'yes');
  document.body.appendChild(script);
};

const GoogleMap = ({ id, location, pageType = '' }) => {
  const [error, setError] = useState('');
  const MapKey = `map-${id}`;

  useEffect(() => {
    if (localStorage.getItem(MapLoadStatusKey) !== 'yes') return;

    new window.google.maps.Geocoder().geocode(
      { address: location },
      (results, status) => {
        if (status === 'OK' && results[0].geometry) {
          const location = results[0].geometry.location;

          const mapOptions = {
            center: location,
            zoom: 14,
            fullscreenControl: false, // Disable fullscreen control
            streetViewControl: false, // Disable street view control
            zoomControl: false, // Disable zoom control
            mapTypeControl: false, // Disable map/satellite toggle
          };

          const map = new window.google.maps.Map(
            document.getElementById(MapKey),
            mapOptions,
          );
          const customMarker = {
            url: ApproxImg, // Replace with the path to your SVG file
            scaledSize: new window.google.maps.Size(256, 256), // Initial size of the marker
            anchor: new window.google.maps.Point(128, 128), // Anchor point at the center of the marker
          };

          const marker = new window.google.maps.Marker({
            position: location,
            map,
            icon: customMarker,
            title: 'Custom Marker',
          });

          // Listen for zoom changes
          map.addListener('zoom_changed', () => {
            const currentZoom = map.getZoom();

            // Calculate the new size based on the zoom level (adjust the factor as needed)
            const newSize = 256 * 2 ** (currentZoom - 14);

            // Calculate the new anchor point to keep the center of the circle over the center of the location
            const newAnchor = new window.google.maps.Point(
              newSize / 2,
              newSize / 2,
            );

            // Update the marker's scaledSize
            marker.setIcon({
              url: customMarker.url,
              scaledSize: new window.google.maps.Size(newSize, newSize),
              anchor: newAnchor,
              labelOrigin: new window.google.maps.Point(
                newSize / 2,
                newSize / 2,
              ), // Adjust label origin
              labelClass: 'custom-marker-transition', // Apply a CSS class for transitions
            });
          });
        } else {
          setError(
            `Geocode was not successful for the following reason: ${status}`,
          );
        }
      },
    );
  }, [location]);

  return (
    <GoogleMapsWrapper>
      {!error && (
        <div
          id={MapKey}
          style={{
            width: '100%',
            height: '400px',
            borderRadius: '10px',
            border: '1px solid #DCDEE8',
          }}
        />
      )}
      {error ? (
        <FormattedMessage
          defaultMessage={
            "We're not able to display this address on the map, please use the city and postal code information in the Location section for more information about where this provider is located."
          }
        />
      ) : (
        pageType == 'matching' && (
          <FormattedMessage
            defaultMessage={
              'You’ll receive the exact location in an email when you book an appointment. After matching with your counsellor, you can find the location details on My Care Team screen.'
            }
          />
        )
      )}
    </GoogleMapsWrapper>
  );
};

export default GoogleMap;
