import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import 'wicg-inert';
import ReactDOM from 'react-dom';
import { phoneSize } from 'global-styles';

import gsLogo from 'images/SignUp/gs_logo.svg';
import { GSPLUS_SIGNUP_EN_URL, GSPLUS_SIGNUP_FR_URL } from 'utils/environment';

const LEARN_MORE_URL =
  'https://inkblot.zendesk.com/hc/en-us/articles/25450855312660';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9000;
`;

const Overlay = styled.div`
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(15, 32, 69, 0.6);
`;

const BodyContainer = styled.div`
  display: block;
  margin-left: 25px;
  margin-right: 25px;
  max-width: 648px;
  width: 100%;
  z-index: 9001;
  box-shadow: 0 0 50px 0 rgba(40, 71, 140, 0.2);
`;

const Top = styled.div`
  position: relative;
  background-color: #ffffff;
  height: 80px;
  padding-top: 24px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  display: flex;
  justify-content: center;

  .close {
    position: absolute;
    cursor: pointer;
    right: 22px;
    top: 22px;
  }
`;

const Body = styled.div`
  background-color: #ffffff;
  max-height: 90vh;
  overflow-y: auto;
  &.bodyOnly {
    border-radius: 12px;
    padding: 0;
    margin: 0;
    @media (max-width: ${phoneSize}) {
      max-height: 80vh;
    }
  }
`;

const Bottom = styled.div`
  background-color: #ffffff;
  height: 20px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`;

const Center = styled.div`
  text-align: center;
  margin: 0 auto;
  width: 90%;

  h2 {
    margin-bottom: 20px;
  }
`;

const Attention = styled.div`
  color: #09709c;
  text-align: center;
  font-family: Gilroy;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`;

const Title = styled.div`
  color: #303333;
  text-align: center;
  font-family: Gilroy;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
`;

const Text = styled.div`
  color: #545959;
  text-align: center;
  font-family: Gilroy;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.25px;
`;

const Link = styled.div`
  color: #09709c;
  font-family: Gilroy;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-decoration-line: underline;
  cursor: pointer;
`;

const StyledButton = styled.button`
  width: 296px;
  height: 48px;
  border-radius: 999px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ outline }) => (outline ? '#d0dbdb' : 'none')};
  background-color: ${({ outline }) => (outline ? 'none' : '#00544E')};
  color: ${({ outline }) => (outline ? '#545959' : '#D9F0FA')};
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  &:hover {
    background-color: ${({ outline }) => (outline ? '#24262608' : '#004645')};
  }
`;

const StyledButtonLabel = styled.span`
  font-family: Gilroy;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  flex: none;
  order: 1;
  flex-grow: 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap-reverse;
  gap: 8px;
`;

const Button = (props) => {
  // eslint-disable-next-line react/prop-types
  const { onClick, disable, label, style, type, input } = props;

  return (
    <>
      <StyledButton
        type={type || 'button'}
        onClick={onClick}
        style={style}
        disable={disable}
        {...input}
        {...props}
      >
        <StyledButtonLabel>{label}</StyledButtonLabel>
      </StyledButton>
    </>
  );
};

class CaresMigrationDialog extends React.PureComponent {
  componentDidMount() {
    if (this.props.open) {
      this.hideNavBar();
    }
  }

  componentDidUpdate(prevProps) {
    const { open } = this.props;

    if (prevProps.open !== open) {
      this.hideNavBar();
      if (open) {
        document.getElementById('app').inert = true;
      } else {
        document.getElementById('app').inert = false;
      }
    }
  }

  hideNavBar = () => {
    const { open } = this.props;
    const navBar = document.getElementById('navBar');

    if (navBar) {
      navBar.classList.toggle('move-down', open);
    }
    document.body.classList.toggle('no-scroll', open);
  };

  render() {
    const {
      open,
      intl: { locale },
    } = this.props;

    if (!open) return <></>;

    return ReactDOM.createPortal(
      <Container>
        <Overlay />
        <BodyContainer role="dialog" aria-modal="true" maxWidth="600px">
          <Top>
            <SVG src={gsLogo} />
          </Top>
          <Body>
            <Center>
              <Attention style={{ marginTop: 20 }}>
                <FormattedMessage defaultMessage="Attention" />
              </Attention>
              <Title>
                <FormattedMessage defaultMessage="Transition to GreenShield+" />
              </Title>
              <Text style={{ marginTop: 8 }}>
                <FormattedMessage
                  defaultMessage="You're being redirected because we have upgraded to GreenShield+, providing a sleeker, more user-friendly experience for all mental health services previously accessed on Inkblot. 
Sign up on the new site now to begin exploring GreenShield+! We're excited for you to get started!"
                />
              </Text>
              <Link
                style={{ marginTop: 20 }}
                tabIndex="0"
                onClick={() => {
                  window.location.assign(LEARN_MORE_URL);
                }}
                onKeyUp={(event) => {
                  if (event.key === 'Enter') {
                    window.location.assign(LEARN_MORE_URL);
                  }
                }}
              >
                <FormattedMessage defaultMessage="Learn more about this change" />
              </Link>
            </Center>
            <ButtonContainer style={{ marginTop: 44 }}>
              <Button
                label={<FormattedMessage defaultMessage="Go to GreenShield+" />}
                onClick={() =>
                  window.location.assign(
                    locale === 'fr'
                      ? GSPLUS_SIGNUP_FR_URL
                      : GSPLUS_SIGNUP_EN_URL,
                  )
                }
              />
            </ButtonContainer>
          </Body>
          <Bottom />
        </BodyContainer>
      </Container>,
      document.body,
    );
  }
}

CaresMigrationDialog.propTypes = {
  open: PropTypes.bool,
  intl: PropTypes.object,
};

export default injectIntl(CaresMigrationDialog);
