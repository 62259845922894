import React from 'react';
import styled from 'styled-components';
import { CommonLabelStyles } from 'components/CommonStyles';

const StyledButton = styled.button`
  width: 109px;
  height: 56px;
  border-radius: 999px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  background-color: #343a54;
  color: #ffffff;
  --ring-offset-width: 2px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  &:hover {
    background-color: #2d3248;
  }

  &:disabled {
    background-color: #ebecf2;
    cursor: not-allowed;
    border-color: transparent;
  }
  &:active {
    background-color: #252a3b;
    color: #acb0bf;
  }
`;

const StyledButtonLabel = styled.span`
  font-family: 'TT Commons Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  flex: none;
  order: 1;
  flex-grow: 0;
`;

const StyledHelpText = styled.span`
  ${CommonLabelStyles} color: #696e82;
  margin-top: 8px;
`;

const Button = (props) => {
  // eslint-disable-next-line react/prop-types
  const { onClick, disable, label, style, type, input, meta } = props;

  return (
    <>
      <StyledButton
        type={type || 'button'}
        onClick={onClick}
        style={style}
        disable={disable}
        {...input}
        {...props}
      >
        <StyledButtonLabel>{label}</StyledButtonLabel>
      </StyledButton>
      {meta && meta.error && <StyledHelpText>{meta.error}</StyledHelpText>}
    </>
  );
};

export default Button;
