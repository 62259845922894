/**
 *
 * TwoFactorAuthentication
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { setToken } from 'utils/auth';
import LoadingPanel from 'components/LoadingPanel';
import { API_URL, MARKETING_URL } from 'utils/environment';
import PanelWrapper from 'components/PanelWrapper';
import Panel from 'components/Panel';
import ErrorDialog from 'components/ErrorDialog';

import { FormattedMessage } from 'react-intl';
import messages from '../messages';

const StyledPanel = styled(Panel)`
  min-height: 500px;
  position: relative;
  width: 100% !important;
`;

/* eslint-disable react/prefer-stateless-function */
export class TwoFactorAuthentication extends React.PureComponent {
  constructor() {
    super();
    this.state = { error: false, loading: true };
  }

  componentDidMount() {
    const { match, history } = this.props;

    if (match.params.code) {
      const colonPosition = match.params.code.indexOf(':');
      const id = match.params.code.substring(0, colonPosition);
      const code = match.params.code.substring(colonPosition + 1);

      axios
        .put(`${API_URL}/two_factor_logs/${id}`, { code })
        .then((response) => {
          const token = response.data.access_token;

          setToken(token);
          this.setState({ loading: false });
          history.push('/');
          window.location.reload();
        })
        .catch(() => this.setState({ error: true }));
    } else {
      this.setState({ error: true });
    }
  }

  redirectToSignIn = () => {
    window.location.href = `${MARKETING_URL}?signin=true`;
  };

  render() {
    const { error, loading } = this.state;

    return (
      <div>
        <PanelWrapper>
          <StyledPanel>
            <LoadingPanel
              open={loading}
              message={<FormattedMessage {...messages.authenticating} />}
              style={{ borderRadius: '20px' }}
            />
          </StyledPanel>
        </PanelWrapper>
        <ErrorDialog
          message={<FormattedMessage {...messages.authError} />}
          open={error}
          onClose={this.redirectToSignIn}
        />
      </div>
    );
  }
}

TwoFactorAuthentication.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(TwoFactorAuthentication);
