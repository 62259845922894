import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Img from 'components/Img';
import Content from 'components/Content';

const NameAvatarWrapper = styled.div`
  margin: auto;
  display: flex;
`;

const Avatar = styled(Img)`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
`;

const NoAvatar = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  color: #fefefe;
  background-color: #4e7bcd;
  text-align: center;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
`;

const TopUserAvatarBanner = (props) => {
  const renderUserImage = (user) => {
    if (user.avatar && user.avatar.url) {
      return (
        <Avatar
          key={`avatar-${user.id}`}
          src={`${user.avatar.url}`}
          alt="Headshot of provider"
        />
      );
    }
    return (
      <NoAvatar key={`no-avatar-${user.id}`}>
        {_.get(user, 'first_name', '').charAt(0).toUpperCase()}
        {_.get(user, 'last_name', '').charAt(0).toUpperCase()}
      </NoAvatar>
    );
  };

  return (
    <div className="top-user-avatar-banner" style={{ display: 'flex' }}>
      <NameAvatarWrapper>
        {props.activeChat.recipient && (
          <>
            {renderUserImage(props.activeChat.recipient)}
            <Content style={{ fontWeight: '600', alignSelf: 'center' }}>
              {`${props.activeChat.recipient.first_name} ${props.activeChat.recipient.last_name}`}
            </Content>
          </>
        )}
      </NameAvatarWrapper>
    </div>
  );
};

TopUserAvatarBanner.propTypes = {
  activeChat: PropTypes.object.isRequired,
};

export default TopUserAvatarBanner;
