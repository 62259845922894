/*
 *
 * WMHSignUp actions
 *
 */

import {
  REGISTER_USER,
  REGISTER_USER_ERROR,
  REGISTER_USER_SUCCESS,
} from './constants';

export function registerUser(values, callback, errCallback) {
  return {
    type: REGISTER_USER,
    values,
    callback,
    errCallback,
  };
}

export function registerUserSuccess(sessionInfo) {
  return {
    type: REGISTER_USER_SUCCESS,
    sessionInfo,
  };
}

export function registerUserError(error) {
  return {
    type: REGISTER_USER_ERROR,
    error,
  };
}
